import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { closeSnackbar, SnackbarKey } from 'notistack';
import React, { FC, ReactElement } from 'react';

interface OwnProps {
  text?: string;
  handleClick?: () => void;
  closeKey?: SnackbarKey;
}

export const SnackbarAction: FC<OwnProps> = ({ text, handleClick, closeKey }): ReactElement<any, any> => {
  return (
    <React.Fragment>
      {text && handleClick && (
        <Button color="inherit" size="small" onClick={handleClick}>
          {text}
        </Button>
      )}
      {closeKey && (
        <IconButton onClick={() => closeSnackbar(closeKey)}>
          <CloseIcon />
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default SnackbarAction;

export const SnackbarActionWithUrl: FC<{ url: string; closeKey?: SnackbarKey }> = ({
  url,
  closeKey,
}): ReactElement<any, any> => {
  return <SnackbarAction text={'open'} handleClick={() => window.open(url, '_blank')} closeKey={closeKey} />;
};
