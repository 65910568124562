import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import BlockchainParam from './blockchain-param';

interface OwnProps {
  name: string;
  params: { [p: string]: string };
}

const BlockchainParamsList: FC<OwnProps> = ({ name, params }) => {
  return (
    <Grid container item xs={12} mt={2}>
      <Box p={1}>
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Grid item container xs={12}>
        {Object.keys(params).map((key) => (
          <BlockchainParam key={key} name={key} value={params[key]} />
        ))}
      </Grid>
    </Grid>
  );
};

export default BlockchainParamsList;
