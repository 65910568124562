import React from 'react';
import { useParams } from 'react-router-dom';
import BlockchainModule from '../components/blockchain/blockchain-module';

function BlockchainPage() {
  let { blockchainId } = useParams();

  return <BlockchainModule blockchainId={blockchainId} />;
}

export default BlockchainPage;
