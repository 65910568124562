import TelegramIcon from '@mui/icons-material/Telegram';
import { Alert, AlertTitle, Box, Grid, Link, Typography } from '@mui/material';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactGA from 'react-ga4';
import SnackbarAction from '../components/common/snackbar-action';

const needNodes = [
  '8ball',
  'acrechain',
  'agoric',
  'aioz',
  'akash',
  'arkh',
  'assetmantle',
  'aura',
  'axelar',
  'bandchain',
  'beezee',
  'bitcanna',
  'bitsong',
  'bluzelle',
  'bostrom',
  'canto',
  'carbon',
  'chain4energy',
  'cheqd',
  'chihuahua',
  'chimba',
  'comdex',
  'commercionetwork',
  'coreum',
  'cosmoshub',
  'crescent',
  'cronos',
  'cryptoorgchain',
  'cudos',
  'decentr',
  'desmos',
  'dyson',
  'echelon',
  'ethos',
  'evmos',
  'fetchhub',
  'firmachain',
  'genesisl1',
  'gitopia',
  'gravitybridge',
  'idep',
  'impacthub',
  'imversed',
  'injective',
  'irisnet',
  'jackal',
  'juno',
  'kava',
  'kichain',
  'konstellation',
  'kujira',
  'kyve',
  'lambda',
  'likecoin',
  'logos',
  'loyal',
  'lumnetwork',
  'mars',
  'mayachain',
  'medasdigital',
  'meme',
  'microtick',
  'migaloo',
  'mises',
  'mythos',
  'nois',
  'nomic',
  'nyx',
  'odin',
  'omniflixhub',
  'onomy',
  'oraichain',
  'osmosis',
  'panacea',
  'passage',
  'persistence',
  'planq',
  'point',
  'provenance',
  'quasar',
  'quicksilver',
  'realio',
  'rebus',
  'regen',
  'rizon',
  'secretnetwork',
  'sentinel',
  'shareledger',
  'shentu',
  'sifchain',
  'sommelier',
  'stafihub',
  'stargaze',
  'starname',
  'stride',
  'teritori',
  'terpnetwork',
  'terra',
  'terra2',
  // 'akashtestnet',
  // 'archwaytestnet',
  // 'axelartestnet',
  // 'babylontestnet',
  // 'bitcannadevnet',
  // 'bitcannadevnet2',
  // 'cascadiatestnet',
  // 'celestiatestnet',
  // 'celestiatestnet2',
  // 'celestiatestnet3',
  // 'cheqdtestnet',
  // 'chimbatestnet',
  // 'composabletestnet',
  // 'coolcattestnet',
  // 'coreumtestnet',
  // 'cosmoshubtestnet',
  // 'cosmwasmtestnet',
  // 'cudostestnet',
  // 'elystestnet',
  // 'empowertestnet',
  // 'evmostestnet',
  // 'fetchhubtestnet',
  // 'gitopiatestnet',
  // 'humanstestnet',
  // 'hypersigntestnet',
  // 'impacthubdevnet',
  // 'impacthubtestnet',
  // 'imversedtestnet',
  // 'injectivetestnet',
  // 'jackaltestnet',
  // 'junotestnet',
  // 'kichaintestnet',
  // 'kujiratestnet',
  // 'kyvedevnet',
  // 'kyvetestnet',
  // 'lumenxtestnet',
  // 'marstestnet',
  // 'migalootestnet',
  // 'neutrontestnet',
  // 'nobltestnet',
  // 'noistestnet',
  // 'nolustestnet',
  // 'osmosistestnet',
  // 'osmosistestnet5',
  // 'persistencetestnet',
  // 'quasartestnet',
  // 'quicksilvertestnet',
  // 'qwoyntestnet',
  // 'saagetestnet',
  // 'secretnetworktestnet',
  // 'seidevnet3',
  // 'seitestnet',
  // 'seitestnet2',
  // 'sixtestnet',
  // 'sourcetestnet',
  // 'stargazetestnet',
  // 'statesettestnet',
  // 'stridetestnet',
  // 'terpnettestnet',
  // 'terra2testnet',
  // 'ulastestnet',
  'thorchain',
  'umee',
  'unification',
  'uptick',
  'vidulum',
  'xpla',
];

const otherProblems = [
  'dig (proposals)',
  'emoney (proposals)',
  'galaxy (api)',
  'idep (api)',
  'lumenx (api)',
  'mises (api)',
  'noble (proposals)',
  'octa (api)',
  'okexchain (api)',
  'tgrade (proposals)',
];

export default function AboutPage() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'About' });
  }, []);
  return (
    <Box style={{ padding: '0 0 1rem 0' }}>
      <Box mt={-1}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Looking for a sponsor!</AlertTitle>
          Feel free to dm in Discord Sargiuz#0815 or email:{' '}
          <Link href="mailto:ssargiuz@gmail.com">ssargiuz@gmail.com</Link> or Telegram{' '}
          <Link href="https://t.me/ssargiuz" target="_blank" rel="nofollow">
            @ssargiuz
          </Link>
          <br />
          You can also donate to our ATOM address to support project{' '}
          <CopyToClipboard
            text="cosmos15fkp0kx8dnux5p9s88s923dphkwjwdau6mxjsq"
            onCopy={() =>
              enqueueSnackbar('Address copied to clipboard!', {
                variant: 'success',
                action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
              })
            }
          >
            <Link>cosmos15fkp0kx8dnux5p9s88s923dphkwjwdau6mxjsq</Link>
          </CopyToClipboard>
        </Alert>
      </Box>
      <Typography variant="h4" component="h1" mt={3}>
        About
      </Typography>
      <Box mt={3}>
        <Typography variant="body1" mt={2}>
          Our goal is to create a convenient and simple platform for users to comfortably participate in the life of the
          government of all blockchains (for that moment only cosmos ecosystem is available). We will make every effort
          so that you can comfortably stake in the validator you are interested in, restake or unstake your funds, vote
          on any active proposal, add a deposit to proposals that are in the fundraising stage, find all existing
          validators and their running nodes. And of course, create your own proposal. We are now at the very beginning
          of our journey towards achieving this goal, but much is already available. Let us help you to realize you
          VOTING POWER! Welcome to visit us and thank you for your trust!
        </Typography>
      </Box>
      <Box mt={3}>
        <Alert severity="warning" variant="outlined">
          <AlertTitle>For validators and developers</AlertTitle>
          <strong>Looking for full nodes for a short time to parse old votes for next blockchains:</strong>
          <br />
          {needNodes.join(', ')}
          <br />
          <br />
          <strong>Looking for a help to get a required data for next blockchains</strong>
          <br />
          {otherProblems.join(', ')}
        </Alert>
      </Box>
      <Grid container spacing={5} mt={2}>
        {/*<Grid item xs={12}>*/}
        {/*  <Typography variant="h5" component="h2" textAlign="center">*/}
        {/*    Team*/}
        {/*  </Typography>*/}
        {/*  <Box p={2} display="flex" alignItems="center" flexDirection="column">*/}
        {/*    <Card sx={{ maxWidth: 300 }}>*/}
        {/*      <CardMedia component="img" height="140" image="/img/ssargiuz.png" alt="ssargiuz" />*/}
        {/*      <CardContent>*/}
        {/*        <Typography gutterBottom variant="h5" component="div">*/}
        {/*          Strybul Sergii*/}
        {/*        </Typography>*/}
        {/*        <Typography variant="body2" color="text.secondary">*/}
        {/*          All in one*/}
        {/*        </Typography>*/}
        {/*        /!*<Typography variant="body2" color="text.secondary">*!/*/}
        {/*        /!*  Programmer with more than 15 years of production development, in love with crypto. <br /> Last 4 years*!/*/}
        {/*        /!*  working only for crypto projects. Trading and arbitrage bots, crypto wallets etc. Fill free to use the*!/*/}
        {/*        /!*  best mobile wallet to be safety{' '}*!/*/}
        {/*        /!*  <Link href="https://amlsafe.io" target="_blank" rel="nofollow">*!/*/}
        {/*        /!*    AML Safe*!/*/}
        {/*        /!*  </Link>*!/*/}
        {/*        /!*  , and very useful{' '}*!/*/}
        {/*        /!*  <Link href="http://amlbot.com/" target="_blank" rel="nofollow">*!/*/}
        {/*        /!*    amlbot*!/*/}
        {/*        /!*  </Link>{' '}*!/*/}
        {/*        /!*  for check your transactions.*!/*/}
        {/*        /!*</Typography>*!/*/}
        {/*      </CardContent>*/}
        {/*      <CardActions>*/}
        {/*        <Button size="small" rel="nofollow" target="_blank" href="https://www.linkedin.com/in/stribulsergey">*/}
        {/*          linkedin*/}
        {/*        </Button>*/}
        {/*        <Button size="small" rel="nofollow" target="_blank" href="https://github.com/stribulsergey">*/}
        {/*          github*/}
        {/*        </Button>*/}
        {/*        <Button size="small" rel="nofollow" target="_blank" href="https://t.me/ssargiuz">*/}
        {/*          Telegram*/}
        {/*        </Button>*/}
        {/*      </CardActions>*/}
        {/*    </Card>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
      </Grid>
      <Typography variant="h5" component="h2">
        Contacts
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TelegramIcon />
        <Typography variant="body1">
          En:{' '}
          <Link href="https://t.me/vpower_en_group" target="_blank" rel="nofollow">
            @vpower_en_group
          </Link>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TelegramIcon />
        <Typography variant="body1">
          Ru:{' '}
          <Link href="https://t.me/vpower_ru_group" target="_blank" rel="nofollow">
            @vpower_ru_group
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
