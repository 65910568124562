import {Box, Card, CardActionArea, CardContent, Skeleton, Typography} from '@mui/material';
import React, {FC, useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {BlockchainsQuery, ValidatorGroupItemFragment} from '../../generated/graphql';
import {abbreviateNumberSeparated} from '../../utils/abbreviateNumber';
import {coinToFloatWithName} from '../../utils/coinToFloat';
import ValidatorAvatar from './validator-avatar';

interface OwnProps {
  blockchains: BlockchainsQuery | undefined;
  validatorGroup: ValidatorGroupItemFragment | undefined;
  showValidators?: boolean;
  index: number;
}

export type Sizes = {
  pl: string;
  p: string;
  pS: string;
  label: string;
  icon: string;
  iconS: string;
  iconSS: string;
  iconSMargin: string;
  iconTops: string;
  name: string;
  num: string;
  numS: string;
  badgeMargin: string;
};

const ValidatorGroupListItem: FC<OwnProps> = ({ blockchains, validatorGroup, index, showValidators = true }) => {
  const username = validatorGroup?.fullName || validatorGroup?.usernameCased || validatorGroup?.username || 'VP';
  let backgroundImage: string;

  switch (index) {
    case 1: {
      backgroundImage = 'url(/img/top1.svg)';
      break;
    }
    case 2: {
      backgroundImage = 'url(/img/top2.svg)';
      break;
    }
    case 3: {
      backgroundImage = 'url(/img/top3.svg)';
      break;
    }
    default:
      backgroundImage = '';
  }

  const weight: { value: string; suffix: string } = useMemo(() => {
    if (validatorGroup) {
      return abbreviateNumberSeparated(validatorGroup.weight);
    }
    return { value: '0', suffix: '' };
  }, [validatorGroup]);

  const createCard = (isBig: boolean) => {
    const allSizes: Sizes[] = [
      {
        pl: '5vw',
        p: '1vw',
        pS: '0.5vw',
        label: '7vw',
        icon: '5vw',
        iconS: '1.6vw',
        iconSS: '2vw',
        iconSMargin: '0.3vw',
        iconTops: '7vw',
        name: '3vw',
        num: '5vw',
        numS: '1.6vw',
        badgeMargin: '-1vw',
      },
      {
        pl: '50px',
        p: '10px',
        pS: '5px',
        label: '70px',
        icon: '50px',
        iconS: '16px',
        iconSS: '20px',
        iconSMargin: '3px',
        iconTops: '70px',
        name: '30px',
        num: '50px',
        numS: '16px',
        badgeMargin: '-10px',
      },
    ];

    const sizes = isBig ? allSizes[0] : allSizes[1];

    return (
      <Card
        sx={{
          backgroundImage,
          backgroundSize: 'cover',
          marginBottom: sizes.p,
          display: { xs: isBig ? 'block' : 'none', lg: isBig ? 'none' : 'block' },
        }}
      >
        <CardActionArea component={RouterLink} to={`/validator-group/${validatorGroup ? validatorGroup.id : 'error'}`}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: sizes.p,
              paddingLeft: sizes.pl,
            }}
          >
            <Box
              mr={sizes.p}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                minWidth: sizes.label,
              }}
            >
              {validatorGroup ? (
                  <Typography
                      sx={{
                          fontFamily: `'Russo One', sans-serif`,
                          fontSize: '48px',
                          lineHeight: '1',
                          fontWeight: 'bold',
                          opacity: 0.7,
                      }}
                  >
                      {validatorGroup.topPlace}
                  </Typography>

              ) : (
                <Skeleton variant="circular" width={sizes.label} height={sizes.label} animation="wave" />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                ml: sizes.p,
                mr: sizes.p,
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {validatorGroup ? (
                  <ValidatorAvatar
                    badgeId={validatorGroup.topPlace <= 3 ? validatorGroup.topPlace.toString() : undefined}
                    isBig={isBig}
                    username={username}
                    img={validatorGroup.picturePrimary}
                    sx={{ width: sizes.icon, height: sizes.icon }}
                  />
                ) : (
                  <Skeleton animation="wave" variant="circular" sx={{ width: sizes.icon, height: sizes.icon }} />
                )}
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h4"
                    ml={sizes.p}
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      '-webkit-line-clamp': '2',
                      '-webkit-box-orient': 'vertical',
                      fontSize: `${sizes.name}!important`,
                      overflowWrap: 'anywhere',
                      fontFamily: 'Inter',
                    }}
                    title={username}
                  >
                    {validatorGroup ? username : <Skeleton animation="wave" width="20vw" />}
                  </Typography>
                </Box>
              </Box>
              {showValidators && (
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} mt={sizes.pS}>
                  {validatorGroup && blockchains
                    ? validatorGroup.validators.map((validator) => {
                        const blockchain = blockchains.blockchains.find((b) => b.id === validator.blockchainId);
                        if (!blockchain) {
                          return null;
                        }

                        return (
                          <Box
                            key={validator.id}
                            sx={{
                              marginRight: sizes.iconSMargin,
                              marginBottom: sizes.iconSMargin,
                              borderRadius: '50%',
                              width: sizes.iconS,
                              height: sizes.iconS,
                              position: 'relative',
                            }}
                          >
                            <img
                              style={{
                                objectFit: 'contain',
                                width: sizes.iconS,
                                height: sizes.iconS,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                              }}
                              src={blockchain.icon}
                              alt={blockchain.prettyName}
                              title={`[${blockchain.prettyName}] ${validator.moniker}: ${coinToFloatWithName(
                                validator.weight,
                                0,
                                '$'
                              )}`}
                            />
                          </Box>
                        );
                      })
                    : Array.from(new Array(5)).map((_, index) => (
                        <Skeleton
                          key={index}
                          sx={{ marginRight: sizes.iconSMargin, width: sizes.iconS, height: sizes.iconS }}
                          animation="wave"
                          variant="circular"
                        />
                      ))}
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: sizes.p }}>
              {validatorGroup ? (
                validatorGroup.weight > 0.01 ? (
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                    <Typography
                      sx={{
                        fontSize: sizes.numS,
                        fontFamily: 'Inter',
                        lineHeight: 1,
                      }}
                    >
                      $
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: sizes.num,
                        fontFamily: 'Inter',
                        fontWeight: '300',
                        lineHeight: 1,
                        marginRight: sizes.pS,
                        marginLeft: sizes.pS,
                      }}
                    >
                      {weight.value}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: sizes.numS,
                        fontFamily: 'Inter',
                        lineHeight: 1,
                      }}
                    >
                      {weight.suffix}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                    <Typography
                      sx={{
                        fontSize: sizes.numS,
                        fontFamily: 'Inter',
                        lineHeight: 1,
                      }}
                    >
                      $
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: sizes.num,
                        fontFamily: 'Inter',
                        fontWeight: '300',
                        lineHeight: 1,
                        marginRight: sizes.pS,
                        marginLeft: sizes.pS,
                      }}
                    >
                      &lt; 0.01
                    </Typography>
                  </Box>
                )
              ) : (
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Typography
                    sx={{
                      fontSize: sizes.num,
                      marginRight: sizes.pS,
                      marginLeft: sizes.pS,
                    }}
                  >
                    <Skeleton animation="wave" width="15vw" />
                  </Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <>
      {createCard(true)}
      {createCard(false)}
    </>
  );
};

export default ValidatorGroupListItem;
