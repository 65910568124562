import { Grid, Link } from '@mui/material';
import React, { FC } from 'react';
import { Explorer } from '../../types';

interface OwnProps {
  explorer: Explorer;
  address: string;
}

interface KnownExplorer {
  img: string;
  url: string;
}

type KnownExplorers = { [p: string]: KnownExplorer };

const known: KnownExplorers = {
  mintscan: {
    img: '/img/profiles/cosmostation.svg',
    url: '/validators/',
  },
  MintScan: {
    img: '/img/profiles/cosmostation.svg',
    url: '/validators/',
  },
  'ping.pub': {
    img: '/img/profiles/ping-pub.svg',
    url: '/staking/',
  },
  bigdipper: {
    img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACjElEQVR4AbzWA4ycURhG4bu2bTuo3Qa1bQZ1UEd17NqKNqiN2aBmUNs2/2Qyuj1JFjfDu5oveaLFexYDUXfG8EEByMN83EINKkQz76nIrEANbmE+8hAgnK92/CqssOMPzqJtM8bb4iz+wA4rriLPXUAVvkDiHnqhLbKaEZCFtuiFe5D4gipfAesRjsGYhMmYgjKN0TJMwWRMwmCEY72vgGzcgQUD0R7fIWsZGKARMAAGZK3vaI+BsOAOsoW7qx0+gzQsg1TcQZpGQBruQCqWIQ1nMFBwngKCkINYmCAVOxAOYMSg8D99+4S/iMgP55s62wGpMCEWOQgSvo6BtvgKqXiMczAZIMBEgIkAE99UdQ6PIRVfof9oYmAhpDcESAIkAbUjPi3UHY/COc0ABwEOzYBziNIJ6IIfrRDwA110AtZCtngAsNbXeBJuQCq+418TAgx8h1TcQJK3gAEwIGtZMQt9sQfvNALeYx/6YzasTlEDPI0HYBOkwoKViK/9nLEwewkwY1ztE1IcVsACqdjkKSAbDyGdWHEBQ9AfhpcAA/0xBBdghXTyENnuAibCCunBH9yExUuABTfxB9IDKyY6jwejGlKHlwBd1QhWA8rx1o8Bb1GuBsyA3Y8BdsxQA4pxHA4/BDhwHMXO/wcp2AJzKwaYsRkpnh6KkViGn60Q8BPLEOnr6TgI4/GqBQNeYTyChO4x0h03WyDgJrqLphxDJTgKexMC7Dii/rM1NSIZG2GGboAZG5AsWuIYjMBi/NAI+I5FiBAteYwGYQxeeAl4gdEIFK11jHfFdTdvSq+hi/DHEVBkjBh8kADbi8h821ORcYDxQvF/9ATfw/zEPjg4LnjArzz/JoOYKLnmAABQEF/NTrk64QAAAABJRU5ErkJggg==',
    url: '/validators/',
  },
  blockscout: {
    img: '/img/profiles/escan-circle.png',
    url: '/validator/',
  },
  tcnetwork: {
    img: '/img/profiles/tcnetwork.svg',
    url: '/validator/',
  },
  'TC Network': {
    img: '/img/profiles/tcnetwork.svg',
    url: '/validator/',
  },
  unichain: {
    img: '/img/profiles/unichain.png',
    url: '/validator/',
  },
  'explorers.guru': {
    img: '/img/profiles/explorers.guru.png',
    url: '/validator/',
  },
  'Nodes.Guru': {
    img: '/img/profiles/explorers.guru.png',
    url: '/validator/',
  },
  atomscan: {
    img: '/img/profiles/atomscan.png',
    url: '/validators/',
  },
  Stakeflow: {
    img: '/img/profiles/Stakeflow.png',
    url: '/validators/',
  },
};

const ValidatorExplorerIconLink: FC<OwnProps> = ({ explorer, address }) => {
  const knownExplorer = known[explorer.kind];
  if (knownExplorer) {
    return (
      <Grid item title={`${explorer.kind} profile`} sx={{ display: 'flex', alignItems: 'center' }}>
        <Link href={`${explorer.url}${knownExplorer.url}${address}`} target="_blank">
          <img src={knownExplorer.img} alt={explorer.kind} width="28px" />
        </Link>
      </Grid>
    );
  }
  return null;
};

export default ValidatorExplorerIconLink;
