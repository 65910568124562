import { Box, Container } from '@mui/material';
import React, { FC } from 'react';

const drawerWidth = 240;

interface OwnProps {
  children: React.ReactNode;
}

const ColumnsLayout: FC<OwnProps> = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Box display="flex" justifyContent="center" flexGrow={1}>
        <Container>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ColumnsLayout;
