import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { FC, ReactElement } from 'react';

interface OwnProps {
  content: React.ReactNode;
  children: ReactElement<any, any>;
}

const CustomTooltip: FC<OwnProps> = ({ content, children }) => {
  return <Tooltip title={content}>{children}</Tooltip>;
};

export default CustomTooltip;
