import React, { FC } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import AboutPage from './pages/about';
import BlockchainPage from './pages/blockchain';
import ErrorPage from './pages/error';

import IndexPage from './pages/index';
import ProposalPage from './pages/proposal';
import ValidatorGroupPage from './pages/validator-group';
import TopValidatorsPage from './pages/validators';

const Routes: FC<{}> = () => (
  <ReactRoutes>
    <Route path="/" element={<IndexPage />} />
    <Route path="/blockchains" element={<BlockchainPage />} />
    <Route path="/blockchains/:blockchainId" element={<BlockchainPage />} />
    <Route path="/top-validators" element={<TopValidatorsPage />} />
    <Route path="/validator-group/:validatorGroupId" element={<ValidatorGroupPage />} />
    <Route path="/proposals" element={<IndexPage />} />
    <Route path="/proposals/:proposalId" element={<ProposalPage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="*" element={<ErrorPage />} />
  </ReactRoutes>
);

export default Routes;
