import { Skeleton, Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from '../../../constants/colors';
import { texts } from '../../../constants/texts';
import { useWalletContext } from '../../../context/wallet-context';
import { VoteOption } from '../../../enum';
import { VotesListItemFragment } from '../../../generated/graphql';

type OwnProps = {
  votes: VotesListItemFragment[];
  loaded: boolean;
};

const VotingMyVotes: FC<OwnProps> = ({ votes, loaded }) => {
  const { mainWallet } = useWalletContext();

  if (!mainWallet || !mainWallet.wallet || !mainWallet.address) {
    return null
  }

  if (!loaded) {
    return <Skeleton animation="wave" width="60px" />;
  }

  if (!votes.length) {
    return (
      <Typography
        mt={1}
        variant="body1"
        color="text.primary"
        textAlign="justify"
        component="div"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        Not Voted
      </Typography>
    );
  }

  return (
    <Typography mt={1} variant="body1" color="text.primary" textAlign="justify" component="div">
      Voted:{' '}
      {votes.map((vote) => (
        <Typography
          key={vote.id}
          component="span"
          sx={{
            backgroundColor: colors.votes[vote.option as VoteOption],
            paddingLeft: 2,
            paddingRight: 2,
            marginRight: 1,
            borderRadius: 100,
            color: '#ffffff',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {texts.votes[vote.option as VoteOption]}
        </Typography>
      ))}
    </Typography>
  );
};

export default VotingMyVotes;
