import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ValidatorGroupListModule from '../components/validator-group-list/validator-group-list-module';

function ValidatorsPage() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Top Validators' });
  }, []);
  return <ValidatorGroupListModule />;
}

export default ValidatorsPage;
