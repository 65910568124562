export enum ProposalStatus {
  PROPOSAL_STATUS_UNSPECIFIED,
  PROPOSAL_STATUS_DEPOSIT_PERIOD,
  PROPOSAL_STATUS_VOTING_PERIOD,
  PROPOSAL_STATUS_PASSED,
  PROPOSAL_STATUS_REJECTED,
  PROPOSAL_STATUS_FAILED,
  UNRECOGNIZED = -1,
}

export enum VoteOption {
  VOTE_OPTION_UNSPECIFIED,
  VOTE_OPTION_YES,
  VOTE_OPTION_ABSTAIN,
  VOTE_OPTION_NO,
  VOTE_OPTION_NO_WITH_VETO,
}

export enum Tally {
  yes = 1,
  abstain,
  no,
  noWithVeto,
}

export enum ChainFilterType {
  ALL,
  CONNECTED,
  CUSTOM,
}
