import Apexcharts from 'apexcharts';
import React, { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import colors from '../../../constants/colors';
import { VoteOption } from '../../../enum';
import { ProposalsListItemFragment } from '../../../generated/graphql';
import { TallyResult } from '../../../types';
import { coinToFloatWithName } from '../../../utils/coinToFloat';

type OwnProps = {
  proposal: ProposalsListItemFragment;
  tally: TallyResult;
  total: BigInt;
};

const PieChart: FC<OwnProps> = ({ proposal, tally, total }) => {
  const votes = [BigInt(tally.yes), BigInt(tally.no), BigInt(tally.noWithVeto), BigInt(tally.abstain)];

  let selected = BigInt(0);
  for (let i in votes) {
    if (votes[i] > selected) {
      selected = votes[i];
    }
  }

  const selectedIndex = votes.findIndex((v) => v === selected);

  const options: Apexcharts.ApexOptions = {
    fill: {
      type: 'solid',
      colors: [
        colors.votes[VoteOption.VOTE_OPTION_YES],
        colors.votes[VoteOption.VOTE_OPTION_NO],
        colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
        colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
      ],
    },
    stroke: {
      width: 1,
      colors: ['#13182C'],
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      width: 300,
      type: 'donut',
      events: {
        animationEnd: function (ctx) {
          setTimeout(() => {
            ctx.toggleDataPointSelection(selectedIndex);
            ctx.toggleDataPointSelection(selectedIndex);
            ctx.toggleDataPointSelection(selectedIndex);
          }, 100);
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: (value) =>
          coinToFloatWithName(value, proposal.blockchain.assets[0].denom, proposal.blockchain.assets[0].symbol, 2),
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
        expandOnClick: false,
        donut: {
          size: '80%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '2em',
              offsetY: -8,
            },
            value: {
              show: true,
              fontSize: '2em',
              fontWeight: 500,
              color: '#ffffff',
              offsetY: 20,
              formatter: (v, ...p) => {
                const value = BigInt(+v);
                return +((Number(value) * 100) / Number(total)).toFixed(2) + '%';
              },
            },
          },
        },
      },
    },
    labels: ['YES', 'NO', 'NWV', 'ABSTAIN'],
    colors: [
      colors.votes[VoteOption.VOTE_OPTION_YES],
      colors.votes[VoteOption.VOTE_OPTION_NO],
      colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
      colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
    ],
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: '100%',
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  name: {
                    fontSize: '2em',
                    offsetY: -8,
                  },
                  value: {
                    fontSize: '2.2em',
                    offsetY: 22,
                  },
                },
              },
            },
          },
          legend: {
            show: false,
          },
        },
      },
      {
        breakpoint: 4080,
        options: {
          chart: {
            width: 200,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  name: {
                    fontSize: '1.2em',
                    offsetY: -4,
                  },
                  value: {
                    fontSize: '1.3em',
                    offsetY: 10,
                  },
                },
              },
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  // @ts-ignore
  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart options={options} series={votes.map((v) => Number(v))} type="donut" />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
