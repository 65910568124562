import { SearchOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import {
  BlockchainListItemFragment,
  useValidatorsByBlockchainIdQuery,
  ValidatorItemFragment,
} from '../../../generated/graphql';
import ValidatorAvatar from '../../validator-group-list/validator-avatar';

interface OwnProps {
  isOpened: boolean;
  blockchain: BlockchainListItemFragment;
  onClose: (selected?: ValidatorItemFragment) => void;
  selected: string | undefined;
}

const SelectValidator: FC<OwnProps> = ({ blockchain, isOpened, onClose, selected }) => {
  const [isActive, setIsActive] = useState<boolean>(true);

  const { data: validators, refetch } = useValidatorsByBlockchainIdQuery({
    variables: { blockchainId: blockchain.id, isActive },
    fetchPolicy: 'cache-first',
  });
  const [searchText, setSearchText] = useState<string>('');
  const [validatorList, setValidatorList] = useState<ValidatorItemFragment[]>([]);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (isOpened) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpened]);

  useEffect(() => {
    if (validators) {
      if (searchText) {
        setValidatorList(
          validators.validatorsByBlockchainId.filter(
            (v) =>
              v.moniker.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
              v.rank.toString().indexOf(searchText) !== -1
          )
        );
      } else {
        setValidatorList(validators.validatorsByBlockchainId);
      }
    }
  }, [searchText, validators]);

  const toggleActive = () => {
    setIsActive((isActive) => {
      refetch({ blockchainId: blockchain.id, isActive: !isActive });
      return !isActive;
    });
  };

  return (
    <Dialog open={isOpened} onClose={() => onClose()} scroll="paper" maxWidth="xs">
      <DialogTitle>Select validator</DialogTitle>
      <Box display="flex" flexDirection="row" pl={1} pr={1} pb={1} mt={-1}>
        <TextField
          label="Search"
          sx={{ marginRight: '8px', flex: 1 }}
          size="small"
          value={searchText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton sx={{ marginRight: -2 }}>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
        <FormControl size="small">
          <Select value={isActive} onChange={toggleActive}>
            <MenuItem value={true as any}>Active</MenuItem>
            <MenuItem value={false as any}>Inactive</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <List sx={{ p: 0 }} dense={true}>
          {validatorList.map((validator) => (
            <ListItemButton
              divider={true}
              key={validator.id}
              selected={validator.id === selected}
              onClick={() => onClose(validator)}
            >
              <ListItemAvatar>
                <ValidatorAvatar
                  img={validator.icon}
                  username={`#${validator.rank} - ${validator.moniker}`}
                  sx={{ width: '40px', height: '40px' }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`#${validator.rank} - ${validator.moniker}`}
                sx={{ display: 'flex', flexGrow: 1 }}
              />
              <Typography>{validator.commissionSimple}%</Typography>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectValidator;
