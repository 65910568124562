import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Button, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { FormikProps, withFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Actions, useWalletContext } from '../../../context/wallet-context';
import {
  BlockchainListItemFragment,
  useValidatorsByBlockchainIdQuery,
  ValidatorItemFragment,
} from '../../../generated/graphql';
import { MyDelegations } from '../../../types';
import { coinToFloat, coinToFloatWithName } from '../../../utils/coinToFloat';
import SelectValidator from '../../common/select-validator/select-validator';
import ValidatorAvatar from '../validator-avatar';

interface FormValues {
  amount: string;
  memo: string;
  gas: number;
  validatorSrcAddress: string;
  validatorDstAddress: string;
}

interface OwnProps {
  defaultValidator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
  myDelegations: MyDelegations;
  actions: Actions;
  onClose: () => void;
}

const createValidationSchema = (blockchain: BlockchainListItemFragment, myDelegations: MyDelegations) => {
  return Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount must be a number.')
      .required('Amount is required.')
      .min(10 ** -blockchain.assets[0].denom, "You can't delegate so little.")
      .test('amount', 'Insufficient staked balance.', (value, context) => {
        const delegation = myDelegations[context.parent.validatorSrcAddress] || {};
        const delegated = +(delegation?.delegations?.amount || 0) * 10 ** -blockchain.assets[0].denom;
        return value <= delegated;
      }),
    memo: Yup.string(),
    validatorSrcAddress: Yup.string().required('You should choose source validator'),
    validatorDstAddress: Yup.string().required('You should choose destination validator'),
  });
};

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm: FC<OwnProps & FormikProps<FormValues>> = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  defaultValidator,
  blockchain,
  myDelegations,
  actions,
}) => {
  const { data: validators } = useValidatorsByBlockchainIdQuery({
    variables: { blockchainId: blockchain.id, isActive: true },
  });

  const { accounts } = useWalletContext();

  const [isAdditionalOpened, setIsAdditionalOpened] = useState<boolean>(false);
  const [isSelectValidatorOpened, setIsSelectValidatorOpened] = useState<boolean>(false);
  const [validator, setValidator] = useState<ValidatorItemFragment>(defaultValidator);
  const [isSelectValidatorToOpened, setIsSelectValidatorToOpened] = useState<boolean>(false);
  const [toValidator, setToValidator] = useState<ValidatorItemFragment | null>(null);
  const delegation = myDelegations[values.validatorSrcAddress] || {};

  useEffect(() => {
    if (validators) {
      const val: ValidatorItemFragment =
        (validator.id && validators.validatorsByBlockchainId.find((v) => v.id === validator.id)) || defaultValidator;

      setFieldValue('validatorSrcAddress', val.operatorAddress);
      setValidator(val);
    }
  }, [validators, validator.id]);

  useEffect(() => {
    if (validators && toValidator) {
      const val: ValidatorItemFragment | null =
        (toValidator.id && validators.validatorsByBlockchainId.find((v) => v.id === toValidator.id)) || null;

      if (val) {
        setFieldValue('validatorDstAddress', val.operatorAddress);
        setToValidator(val);
      }
    }
  }, [validators, toValidator]);

  useEffect(() => {
    const getGas = async () => {
      let gasUsed = 0;
      const coin = accounts[blockchain.chainName]?.balance || null;
      if (coin) {
        gasUsed = (await actions.simulateDelegation(blockchain.chainName, coin, defaultValidator.operatorAddress)) || 0;
      }
      await setFieldValue('gas', gasUsed);
    };
    getGas();
  }, []);

  const undelegateAll = async () => {
    if (!delegation?.delegations) {
      return;
    }

    await setFieldValue('amount', coinToFloat(+delegation.delegations.amount, blockchain.assets[0].denom));
  };

  const onSelectValidator = (selected?: ValidatorItemFragment) => {
    setIsSelectValidatorOpened(false);
    if (selected) {
      setValidator(selected);
    }
  };

  const onSelectValidatorTo = (selected?: ValidatorItemFragment) => {
    setIsSelectValidatorToOpened(false);
    if (selected) {
      setToValidator(selected);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SelectValidator
        isOpened={isSelectValidatorOpened}
        onClose={onSelectValidator}
        blockchain={blockchain}
        selected={validator.id}
      />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6" onClick={() => setIsSelectValidatorOpened(true)}>
          from
        </Typography>
        <ValidatorAvatar
          img={validator.icon}
          username={validator.moniker}
          sx={{ width: '24px', height: '24px', marginLeft: 1, marginRight: 1 }}
        />
        <Typography
          flexGrow={1}
          variant="h6"
          onClick={() => setIsSelectValidatorOpened(true)}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '400px',
            overflow: 'hidden',
            marginRight: 2,
          }}
        >
          {validator.moniker}
        </Typography>
        <Typography variant="h6" onClick={() => setIsSelectValidatorOpened(true)}>
          {validator.commissionSimple}%
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={() => setIsSelectValidatorOpened(true)}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>

      <SelectValidator
        isOpened={isSelectValidatorToOpened}
        onClose={onSelectValidatorTo}
        blockchain={blockchain}
        selected={toValidator?.id}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={(theme) => ({
          border: '1px solid transparent',
          borderBottomColor:
            touched.validatorDstAddress && !!errors.validatorDstAddress ? theme.palette.error.main : 'transparent',
        })}
      >
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)} mr={3}>
          to
        </Typography>
        <ValidatorAvatar
          img={toValidator?.icon}
          username={toValidator?.moniker || 'Select validator'}
          sx={{ width: '24px', height: '24px', marginLeft: 1, marginRight: 1 }}
        />
        <Typography
          flexGrow={1}
          variant="h6"
          onClick={() => setIsSelectValidatorToOpened(true)}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '400px',
            overflow: 'hidden',
            marginRight: 2,
          }}
        >
          {toValidator?.moniker || 'Select validator'}
        </Typography>
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)}>
          {toValidator?.commissionSimple || '-'}%
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={() => setIsSelectValidatorToOpened(true)}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      {touched.validatorDstAddress && !!errors.validatorDstAddress ? (
        <Typography variant="caption" color="error" ml={1.8} onClick={() => setIsSelectValidatorToOpened(true)}>
          {errors.validatorDstAddress}
        </Typography>
      ) : null}

      <Box mt={3} mb={3}>
        <TextField
          fullWidth
          error={touched.amount && !!errors.amount}
          helperText={touched.amount ? errors.amount : ''}
          placeholder="&infin;"
          label="Amount"
          InputProps={{
            endAdornment: <Typography>{blockchain.assets[0].symbol}</Typography>,
          }}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
        />
        <Box display="flex" justifyContent="flex-end">
          <Link sx={{ color: 'action.disabled', textDecoration: 'none', cursor: 'pointer' }} onClick={undelegateAll}>
            Staked:{' '}
            {delegation.delegations
              ? coinToFloatWithName(
                  delegation.delegations.amount,
                  blockchain.assets[0].denom,
                  blockchain.assets[0].symbol
                )
              : 0}
          </Link>
        </Box>
        <Link mt={1} alignItems="center" onClick={() => setIsAdditionalOpened(!isAdditionalOpened)}>
          Additional
        </Link>
        <Box display={isAdditionalOpened ? 'block' : 'none'} mt={1}>
          <TextField
            fullWidth
            error={touched.memo && !!errors.memo}
            helperText={touched.memo ? errors.memo : ''}
            multiline={true}
            label="Memo"
            placeholder="Memo"
            name="memo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.memo}
            InputProps={{
              endAdornment: (
                <Tooltip title="A note or comment to send with the transaction.">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              ),
            }}
          />
        </Box>
      </Box>
      <Alert severity="success">You will instantly stake your assets to another validator.</Alert>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button variant="contained" type="submit" disabled={isSubmitting}>
          Redelegate
        </Button>
      </Box>
    </form>
  );
};

// Wrap our form with the withFormik HoC
const RedelegationForm = withFormik<OwnProps, FormValues>({
  validationSchema: (props: OwnProps) => createValidationSchema(props.blockchain, props.myDelegations),
  mapPropsToValues: (props) => {
    return {
      validatorSrcAddress: props.defaultValidator.operatorAddress,
      validatorDstAddress: '',
      amount: '',
      memo: 'votingpower.org',
      gas: 0,
    };
  },

  handleSubmit: async (values, { props: { blockchain, actions, onClose }, resetForm }) => {
    const success = await actions.redelegate(
      blockchain.chainName,
      { amount: (+values.amount * 10 ** blockchain.assets[0].denom).toString(10), denom: blockchain.assets[0].base },
      values.validatorSrcAddress,
      values.validatorDstAddress,
      values.gas,
      values.memo
    );
    if (success) {
      resetForm();
      onClose();
      await actions.reloadAccount(blockchain.chainName);
    }
  },
})(InnerForm);

export default RedelegationForm;
