import { ProposalStatus, VoteOption } from "../enum";

const statuses = {
  [ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD]: "Deposit period",
  [ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD]: "Voting period",
  [ProposalStatus.PROPOSAL_STATUS_FAILED]: "Failed",
  [ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED]: "Unspecified",
  [ProposalStatus.PROPOSAL_STATUS_REJECTED]: "Rejected",
  [ProposalStatus.PROPOSAL_STATUS_PASSED]: "Passed",
  [ProposalStatus.UNRECOGNIZED]: "Unrecognized",
};

const votes = {
  [VoteOption.VOTE_OPTION_UNSPECIFIED]: "Unspecified",
  [VoteOption.VOTE_OPTION_YES]: "Yes",
  [VoteOption.VOTE_OPTION_ABSTAIN]: "Abstain",
  [VoteOption.VOTE_OPTION_NO]: "No",
  [VoteOption.VOTE_OPTION_NO_WITH_VETO]: "Veto",
}

export const texts = {
  statuses,
  votes,
}