import { Menu as MenuIcon } from '@mui/icons-material';

import { Box, IconButton, Toolbar } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  handleDrawerToggle: () => void;
}

const Header: FC<OwnProps> = ({ handleDrawerToggle }) => {
  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' }, color: 'text.primary' }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, marginLeft: '-44px', display: 'flex', justifyContent: 'center' }}>
          <Link to={'/'}>
            <img alt="logo" src="/img/logo-full.svg" height="40" />
          </Link>
        </Box>
      </Toolbar>
    </Box>
  );
};
export default Header;
