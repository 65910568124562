import React, { FC, useMemo } from "react";

import "./json-box.css";

const ReactJsonSyntaxHighlighter: FC<OwnProps> = ({ obj }) => {
  if (!obj || Object.keys(obj).length === 0) {
    return (
      <div className="ReactJsonSyntaxHighlighter">
        {JSON.stringify(obj)}
      </div>
    );
  }

  let json = JSON.stringify(obj, undefined, 2);
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  json = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (match) => {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return `<span class='${cls}'>${match}</span>`;
    }
  );

  return (
    <div className="ReactJsonSyntaxHighlighter">
      <pre dangerouslySetInnerHTML={{ __html: json }} />
    </div>
  );
};

type OwnProps = {
  obj: any;
};

const isArrayOrObject = (el: string) => el[0] === "{" || el[0] === "[";

const parseJSON = (obj: any): any => {
  if (typeof obj === "string" || obj === null) {
    return obj;
  }

  let res: any = Array.isArray(obj) ? [] : {};
  Object.keys(obj).forEach((key) => {
    if (isArrayOrObject(obj)) {
      res[key] = parseJSON(obj[key]);
      return;
    }

    try {
      res[key] = JSON.parse(obj[key]);
    } catch (e) {
      res[key] = obj[key];
    }
  });
  return res;
};

const JSONBox: FC<OwnProps> = ({ obj }) => {
  const value = useMemo(() => parseJSON(obj), [obj]);
  return <ReactJsonSyntaxHighlighter obj={value} />;
};

export default JSONBox;
