import React from 'react';
import { Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { darken } from '@mui/system/colorManipulator';
import colors from '../../../constants/colors';
import { ProposalStatus } from '../../../enum';

const stylesMain = {
  borderRadius: 0,
  whiteSpace: 'nowrap',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
};

interface OwnProps {
  deposited: boolean;
  onClick: () => void;
}

function DepositButton({ deposited, onClick }: OwnProps) {
  return (
    <Grid container>
      <Grid item xs>
        <Button fullWidth size="small" onClick={onClick} sx={deposited ? styles.selected : styles.default}>
          Deposit
        </Button>
      </Grid>
    </Grid>
  );
}

export default DepositButton;

const styles: any = {
  default: {
    ...stylesMain,
    color: colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD],
    borderTopColor: colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD],
    '&:hover': {
      backgroundColor: colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD],
      color: '#ffffff',
    },
    '&:disabled': {
      backgroundColor: grey[500],
    },
  },
  selected: {
    ...stylesMain,
    borderTopColor: colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD],
    backgroundColor: colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD],
    color: '#ffffff',
    '&:hover': {
      backgroundColor: darken(colors.statuses[ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD], 0.2),
    },
    '&:disabled': {
      backgroundColor: grey[500],
    },
  },
};
