import { WalletStatus } from '@cosmos-kit/core';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button } from '@mui/material';
import React from 'react';
import { useWalletContext } from '../../context/wallet-context';

const ConnectButton = () => {
  const {
    mainWallet: { username, wallet, status },
    actions,
  } = useWalletContext();
  const accountName = username
    ? username.length && username.length > 14
      ? username?.slice(0, 12) + '...'
      : username
    : wallet?.prettyName;

  switch (status) {
    case WalletStatus.Connected:
      return (
        <Button
          fullWidth
          variant="outlined"
          style={{ justifyContent: 'space-between' }}
          startIcon={<img src={wallet?.logo} alt={wallet?.prettyName} width={18} height={18} />}
          onClick={() => actions.disconnectWallet()}
          endIcon={<LinkOffIcon />}
        >
          {accountName}
        </Button>
      );
    case WalletStatus.Connecting:
      return (
        <Button fullWidth variant="outlined"
                style={{ justifyContent: 'flex-start' }}
        >
          Connecting...
        </Button>
      );
    default:
      return (
        <Button fullWidth variant="outlined" onClick={() => actions.connectWallet()}
                style={{ justifyContent: 'space-between' }}
                endIcon={<LinkIcon />}

        >
          Connect Wallet
        </Button>
      );
  }
};

export default ConnectButton;
