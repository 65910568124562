import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import VotingModule from '../components/voting/voting-module';

function VotePowerPage() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Proposals' });
  }, []);
  return <VotingModule />;
}

export default VotePowerPage;
