import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddVoteInput = {
  chainId: Scalars['String'];
  chainProposalId: Scalars['String'];
  option: Scalars['Int'];
  voterAddress: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  address?: Maybe<Scalars['String']>;
  base: Scalars['String'];
  coingeckoId?: Maybe<Scalars['String']>;
  denom: Scalars['Float'];
  denomUnits: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords?: Maybe<Scalars['String']>;
  logoURIs?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceInUSD: Scalars['Float'];
  symbol: Scalars['String'];
  typeAsset?: Maybe<Scalars['String']>;
};

export type Blockchain = {
  __typename?: 'Blockchain';
  TVL: Scalars['Float'];
  TVLPercent: Scalars['Float'];
  TVLinUSD: Scalars['Float'];
  apis: Scalars['String'];
  assets: Array<Asset>;
  bech32Prefix: Scalars['String'];
  chainId: Scalars['String'];
  chainName: Scalars['String'];
  createdAt: Scalars['String'];
  distributionParams?: Maybe<Scalars['String']>;
  explorers: Scalars['String'];
  feeToken: Scalars['String'];
  govParams?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  mintParams?: Maybe<Scalars['String']>;
  networkType: Scalars['String'];
  params?: Maybe<Scalars['String']>;
  prettyName: Scalars['String'];
  raw?: Maybe<Scalars['String']>;
  slashingParams?: Maybe<Scalars['String']>;
  stakingParams?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  website: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addVote: Vote;
};


export type MutationAddVoteArgs = {
  data: AddVoteInput;
};

export type Proposal = {
  __typename?: 'Proposal';
  blockchain: Blockchain;
  blockchainId: Scalars['ID'];
  chainProposalId: Scalars['String'];
  content: Scalars['String'];
  currentTallyResult: Scalars['String'];
  depositEndTime: Scalars['DateTime'];
  description: Scalars['String'];
  finalTallyResult: Scalars['String'];
  id: Scalars['ID'];
  isEmpty: Scalars['Boolean'];
  isExpedited: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isScam: Scalars['Boolean'];
  status: Scalars['Float'];
  submitTime: Scalars['DateTime'];
  title: Scalars['String'];
  totalDeposit: Scalars['String'];
  type: Scalars['String'];
  votes: Vote;
  votingEndTime: Scalars['DateTime'];
  votingStartTime: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  blockchainById: Blockchain;
  blockchains: Array<Blockchain>;
  proposalById: Proposal;
  proposals: Array<Proposal>;
  tallyByProposal: Tally;
  validatorGroupById: ValidatorGroup;
  validatorGroups: Array<ValidatorGroup>;
  validatorsByBlockchainId: Array<Validator>;
  votes: Array<Vote>;
  votesByProposal: Array<Vote>;
};


export type QueryBlockchainByIdArgs = {
  blockchainId: Scalars['ID'];
};


export type QueryProposalByIdArgs = {
  proposalId: Scalars['ID'];
};


export type QueryProposalsArgs = {
  blockchainIds: Array<Scalars['ID']>;
  includeScam: Scalars['Boolean'];
  isActual: Scalars['Boolean'];
  searchText: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  statuses: Array<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryTallyByProposalArgs = {
  proposalId: Scalars['ID'];
};


export type QueryValidatorGroupByIdArgs = {
  validatorGroupId: Scalars['ID'];
};


export type QueryValidatorGroupsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryValidatorsByBlockchainIdArgs = {
  blockchainId: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type QueryVotesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  voterAddresses: Array<Scalars['String']>;
};


export type QueryVotesByProposalArgs = {
  proposalId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type Tally = {
  __typename?: 'Tally';
  abstain: Scalars['String'];
  id: Scalars['ID'];
  no: Scalars['String'];
  noWithVeto: Scalars['String'];
  proposal: Proposal;
  proposalId: Scalars['ID'];
  yes: Scalars['String'];
};

export type Validator = {
  __typename?: 'Validator';
  accountAddress: Scalars['String'];
  blockchain: Blockchain;
  blockchainId: Scalars['ID'];
  commission: Scalars['String'];
  commissionSimple: Scalars['String'];
  delegatorShares: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jailed: Scalars['Boolean'];
  minSelfDelegation: Scalars['String'];
  moniker: Scalars['String'];
  operatorAddress: Scalars['String'];
  rank: Scalars['Float'];
  securityContact?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
  tokens: Scalars['String'];
  unbondingHeight?: Maybe<Scalars['String']>;
  unbondingTime?: Maybe<Scalars['String']>;
  validatorGroup: ValidatorGroup;
  validatorGroupId: Scalars['Float'];
  website?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export type ValidatorGroup = {
  __typename?: 'ValidatorGroup';
  bio?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identity?: Maybe<Scalars['String']>;
  keybaseId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  picturePrimary?: Maybe<Scalars['String']>;
  proofs?: Maybe<Array<ValidatorIdentityProof>>;
  topPlace: Scalars['Float'];
  username?: Maybe<Scalars['String']>;
  usernameCased?: Maybe<Scalars['String']>;
  validators: Array<Validator>;
  weight: Scalars['Float'];
};

export type ValidatorIdentityProof = {
  __typename?: 'ValidatorIdentityProof';
  humanUrl?: Maybe<Scalars['String']>;
  nametag?: Maybe<Scalars['String']>;
  presentationGroup?: Maybe<Scalars['String']>;
  proofId: Scalars['String'];
  proofType?: Maybe<Scalars['String']>;
  serviceUrl?: Maybe<Scalars['String']>;
};

export type Vote = {
  __typename?: 'Vote';
  chainProposalId: Scalars['String'];
  id: Scalars['ID'];
  option: Scalars['Float'];
  proposal: Proposal;
  proposalId: Scalars['ID'];
  voterAddress: Scalars['String'];
};

export type AssetItemFragment = { __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null };

export type BlockchainListItemFragment = { __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> };

export type BlockchainsQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockchainsQuery = { __typename?: 'Query', blockchains: Array<{ __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> }> };

export type BlockchainByIdQueryVariables = Exact<{
  blockchainId: Scalars['ID'];
}>;


export type BlockchainByIdQuery = { __typename?: 'Query', blockchainById: { __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> } };

export type BlockchainByIdWithParamsQueryVariables = Exact<{
  blockchainId: Scalars['ID'];
}>;


export type BlockchainByIdWithParamsQuery = { __typename?: 'Query', blockchainById: { __typename: 'Blockchain', params?: string | null, govParams?: string | null, mintParams?: string | null, distributionParams?: string | null, slashingParams?: string | null, stakingParams?: string | null, id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> } };

export type ProposalsListItemFragment = { __typename: 'Proposal', id: string, blockchainId: string, chainProposalId: string, type: string, title: string, description: string, status: number, finalTallyResult: string, currentTallyResult: string, content: string, submitTime: any, depositEndTime: any, totalDeposit: string, votingStartTime: any, votingEndTime: any, isExpedited: boolean, isEmpty: boolean, isScam: boolean, blockchain: { __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> } };

export type ProposalsQueryVariables = Exact<{
  blockchainIds: Array<Scalars['ID']> | Scalars['ID'];
  isActual: Scalars['Boolean'];
  statuses: Array<Scalars['Int']> | Scalars['Int'];
  searchText: Scalars['String'];
  includeScam: Scalars['Boolean'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ProposalsQuery = { __typename?: 'Query', proposals: Array<{ __typename: 'Proposal', id: string, blockchainId: string, chainProposalId: string, type: string, title: string, description: string, status: number, finalTallyResult: string, currentTallyResult: string, content: string, submitTime: any, depositEndTime: any, totalDeposit: string, votingStartTime: any, votingEndTime: any, isExpedited: boolean, isEmpty: boolean, isScam: boolean, blockchain: { __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> } }> };

export type ProposalByIdQueryVariables = Exact<{
  proposalId: Scalars['ID'];
}>;


export type ProposalByIdQuery = { __typename?: 'Query', proposalById: { __typename: 'Proposal', id: string, blockchainId: string, chainProposalId: string, type: string, title: string, description: string, status: number, finalTallyResult: string, currentTallyResult: string, content: string, submitTime: any, depositEndTime: any, totalDeposit: string, votingStartTime: any, votingEndTime: any, isExpedited: boolean, isEmpty: boolean, isScam: boolean, blockchain: { __typename: 'Blockchain', id: string, chainName: string, chainId: string, prettyName: string, bech32Prefix: string, status: string, networkType: string, website: string, icon: string, feeToken: string, apis: string, explorers: string, TVL: number, TVLPercent: number, TVLinUSD: number, createdAt: string, assets: Array<{ __typename: 'Asset', id: string, typeAsset?: string | null, display: string, symbol: string, address?: string | null, keywords?: string | null, name: string, base: string, denom: number, denomUnits: string, description?: string | null, logoURIs?: string | null, priceInUSD: number, coingeckoId?: string | null }> } } };

export type TalliesListItemFragment = { __typename: 'Tally', id: string, yes: string, no: string, abstain: string, noWithVeto: string, proposalId: string };

export type TallyByProposalQueryVariables = Exact<{
  proposalId: Scalars['ID'];
}>;


export type TallyByProposalQuery = { __typename?: 'Query', tallyByProposal: { __typename: 'Tally', id: string, yes: string, no: string, abstain: string, noWithVeto: string, proposalId: string } };

export type KeybaseProofItemFragment = { __typename: 'ValidatorIdentityProof', proofId: string, humanUrl?: string | null, nametag?: string | null, presentationGroup?: string | null, serviceUrl?: string | null, proofType?: string | null };

export type ValidatorGroupItemFragment = { __typename: 'ValidatorGroup', id: string, identity?: string | null, keybaseId?: string | null, username?: string | null, topPlace: number, usernameCased?: string | null, picturePrimary?: string | null, bio?: string | null, fullName?: string | null, location?: string | null, weight: number, proofs?: Array<{ __typename: 'ValidatorIdentityProof', proofId: string, humanUrl?: string | null, nametag?: string | null, presentationGroup?: string | null, serviceUrl?: string | null, proofType?: string | null }> | null, validators: Array<{ __typename: 'Validator', id: string, validatorGroupId: number, rank: number, moniker: string, securityContact?: string | null, details?: string | null, website?: string | null, accountAddress: string, operatorAddress: string, commission: string, commissionSimple: string, jailed: boolean, icon?: string | null, status: number, tokens: string, delegatorShares: string, minSelfDelegation: string, unbondingHeight?: string | null, unbondingTime?: string | null, blockchainId: string, weight: number }> };

export type ValidatorItemFragment = { __typename: 'Validator', id: string, validatorGroupId: number, rank: number, moniker: string, securityContact?: string | null, details?: string | null, website?: string | null, accountAddress: string, operatorAddress: string, commission: string, commissionSimple: string, jailed: boolean, icon?: string | null, status: number, tokens: string, delegatorShares: string, minSelfDelegation: string, unbondingHeight?: string | null, unbondingTime?: string | null, blockchainId: string, weight: number };

export type ValidatorGroupsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ValidatorGroupsQuery = { __typename?: 'Query', validatorGroups: Array<{ __typename: 'ValidatorGroup', id: string, identity?: string | null, keybaseId?: string | null, username?: string | null, topPlace: number, usernameCased?: string | null, picturePrimary?: string | null, bio?: string | null, fullName?: string | null, location?: string | null, weight: number, proofs?: Array<{ __typename: 'ValidatorIdentityProof', proofId: string, humanUrl?: string | null, nametag?: string | null, presentationGroup?: string | null, serviceUrl?: string | null, proofType?: string | null }> | null, validators: Array<{ __typename: 'Validator', id: string, validatorGroupId: number, rank: number, moniker: string, securityContact?: string | null, details?: string | null, website?: string | null, accountAddress: string, operatorAddress: string, commission: string, commissionSimple: string, jailed: boolean, icon?: string | null, status: number, tokens: string, delegatorShares: string, minSelfDelegation: string, unbondingHeight?: string | null, unbondingTime?: string | null, blockchainId: string, weight: number }> }> };

export type ValidatorGroupByIdQueryVariables = Exact<{
  validatorGroupId: Scalars['ID'];
}>;


export type ValidatorGroupByIdQuery = { __typename?: 'Query', validatorGroupById: { __typename: 'ValidatorGroup', id: string, identity?: string | null, keybaseId?: string | null, username?: string | null, topPlace: number, usernameCased?: string | null, picturePrimary?: string | null, bio?: string | null, fullName?: string | null, location?: string | null, weight: number, proofs?: Array<{ __typename: 'ValidatorIdentityProof', proofId: string, humanUrl?: string | null, nametag?: string | null, presentationGroup?: string | null, serviceUrl?: string | null, proofType?: string | null }> | null, validators: Array<{ __typename: 'Validator', id: string, validatorGroupId: number, rank: number, moniker: string, securityContact?: string | null, details?: string | null, website?: string | null, accountAddress: string, operatorAddress: string, commission: string, commissionSimple: string, jailed: boolean, icon?: string | null, status: number, tokens: string, delegatorShares: string, minSelfDelegation: string, unbondingHeight?: string | null, unbondingTime?: string | null, blockchainId: string, weight: number }> } };

export type ValidatorsByBlockchainIdQueryVariables = Exact<{
  blockchainId: Scalars['ID'];
  isActive: Scalars['Boolean'];
}>;


export type ValidatorsByBlockchainIdQuery = { __typename?: 'Query', validatorsByBlockchainId: Array<{ __typename: 'Validator', id: string, validatorGroupId: number, rank: number, moniker: string, securityContact?: string | null, details?: string | null, website?: string | null, accountAddress: string, operatorAddress: string, commission: string, commissionSimple: string, jailed: boolean, icon?: string | null, status: number, tokens: string, delegatorShares: string, minSelfDelegation: string, unbondingHeight?: string | null, unbondingTime?: string | null, blockchainId: string, weight: number }> };

export type VotesListItemFragment = { __typename: 'Vote', id: string, chainProposalId: string, voterAddress: string, option: number, proposalId: string };

export type VotesQueryVariables = Exact<{
  voterAddresses: Array<Scalars['String']> | Scalars['String'];
}>;


export type VotesQuery = { __typename?: 'Query', votes: Array<{ __typename: 'Vote', id: string, chainProposalId: string, voterAddress: string, option: number, proposalId: string }> };

export type AddVoteMutationVariables = Exact<{
  data: AddVoteInput;
}>;


export type AddVoteMutation = { __typename?: 'Mutation', addVote: { __typename: 'Vote', chainProposalId: string, id: string, option: number, proposalId: string, voterAddress: string } };

export const AssetItemFragmentDoc = gql`
    fragment AssetItem on Asset {
  __typename
  id
  typeAsset
  display
  symbol
  address
  keywords
  name
  base
  denom
  denomUnits
  description
  logoURIs
  priceInUSD
  coingeckoId
}
    `;
export const BlockchainListItemFragmentDoc = gql`
    fragment BlockchainListItem on Blockchain {
  __typename
  id
  chainName
  chainId
  prettyName
  bech32Prefix
  status
  networkType
  website
  icon
  feeToken
  apis
  explorers
  TVL
  TVLPercent
  TVLinUSD
  createdAt
  assets {
    ...AssetItem
  }
}
    ${AssetItemFragmentDoc}`;
export const ProposalsListItemFragmentDoc = gql`
    fragment ProposalsListItem on Proposal {
  __typename
  id
  blockchainId
  chainProposalId
  type
  title
  description
  status
  finalTallyResult
  currentTallyResult
  content
  submitTime
  depositEndTime
  totalDeposit
  votingStartTime
  votingEndTime
  isExpedited
  isEmpty
  isScam
  blockchain {
    ...BlockchainListItem
  }
}
    ${BlockchainListItemFragmentDoc}`;
export const TalliesListItemFragmentDoc = gql`
    fragment TalliesListItem on Tally {
  __typename
  id
  yes
  no
  abstain
  noWithVeto
  proposalId
}
    `;
export const KeybaseProofItemFragmentDoc = gql`
    fragment KeybaseProofItem on ValidatorIdentityProof {
  __typename
  proofId
  humanUrl
  nametag
  presentationGroup
  serviceUrl
  proofType
}
    `;
export const ValidatorItemFragmentDoc = gql`
    fragment ValidatorItem on Validator {
  __typename
  id
  validatorGroupId
  rank
  moniker
  securityContact
  details
  website
  accountAddress
  operatorAddress
  commission
  commissionSimple
  jailed
  icon
  status
  tokens
  delegatorShares
  minSelfDelegation
  unbondingHeight
  unbondingTime
  blockchainId
  weight
}
    `;
export const ValidatorGroupItemFragmentDoc = gql`
    fragment ValidatorGroupItem on ValidatorGroup {
  __typename
  id
  identity
  keybaseId
  username
  topPlace
  usernameCased
  picturePrimary
  bio
  fullName
  location
  weight
  proofs {
    ...KeybaseProofItem
  }
  validators {
    ...ValidatorItem
  }
}
    ${KeybaseProofItemFragmentDoc}
${ValidatorItemFragmentDoc}`;
export const VotesListItemFragmentDoc = gql`
    fragment VotesListItem on Vote {
  __typename
  id
  chainProposalId
  voterAddress
  option
  proposalId
}
    `;
export const BlockchainsDocument = gql`
    query Blockchains {
  blockchains {
    ...BlockchainListItem
  }
}
    ${BlockchainListItemFragmentDoc}`;

/**
 * __useBlockchainsQuery__
 *
 * To run a query within a React component, call `useBlockchainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockchainsQuery(baseOptions?: Apollo.QueryHookOptions<BlockchainsQuery, BlockchainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockchainsQuery, BlockchainsQueryVariables>(BlockchainsDocument, options);
      }
export function useBlockchainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockchainsQuery, BlockchainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockchainsQuery, BlockchainsQueryVariables>(BlockchainsDocument, options);
        }
export type BlockchainsQueryHookResult = ReturnType<typeof useBlockchainsQuery>;
export type BlockchainsLazyQueryHookResult = ReturnType<typeof useBlockchainsLazyQuery>;
export type BlockchainsQueryResult = Apollo.QueryResult<BlockchainsQuery, BlockchainsQueryVariables>;
export const BlockchainByIdDocument = gql`
    query BlockchainById($blockchainId: ID!) {
  blockchainById(blockchainId: $blockchainId) {
    ...BlockchainListItem
  }
}
    ${BlockchainListItemFragmentDoc}`;

/**
 * __useBlockchainByIdQuery__
 *
 * To run a query within a React component, call `useBlockchainByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainByIdQuery({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *   },
 * });
 */
export function useBlockchainByIdQuery(baseOptions: Apollo.QueryHookOptions<BlockchainByIdQuery, BlockchainByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockchainByIdQuery, BlockchainByIdQueryVariables>(BlockchainByIdDocument, options);
      }
export function useBlockchainByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockchainByIdQuery, BlockchainByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockchainByIdQuery, BlockchainByIdQueryVariables>(BlockchainByIdDocument, options);
        }
export type BlockchainByIdQueryHookResult = ReturnType<typeof useBlockchainByIdQuery>;
export type BlockchainByIdLazyQueryHookResult = ReturnType<typeof useBlockchainByIdLazyQuery>;
export type BlockchainByIdQueryResult = Apollo.QueryResult<BlockchainByIdQuery, BlockchainByIdQueryVariables>;
export const BlockchainByIdWithParamsDocument = gql`
    query BlockchainByIdWithParams($blockchainId: ID!) {
  blockchainById(blockchainId: $blockchainId) {
    ...BlockchainListItem
    params
    govParams
    mintParams
    distributionParams
    slashingParams
    stakingParams
  }
}
    ${BlockchainListItemFragmentDoc}`;

/**
 * __useBlockchainByIdWithParamsQuery__
 *
 * To run a query within a React component, call `useBlockchainByIdWithParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainByIdWithParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainByIdWithParamsQuery({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *   },
 * });
 */
export function useBlockchainByIdWithParamsQuery(baseOptions: Apollo.QueryHookOptions<BlockchainByIdWithParamsQuery, BlockchainByIdWithParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockchainByIdWithParamsQuery, BlockchainByIdWithParamsQueryVariables>(BlockchainByIdWithParamsDocument, options);
      }
export function useBlockchainByIdWithParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockchainByIdWithParamsQuery, BlockchainByIdWithParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockchainByIdWithParamsQuery, BlockchainByIdWithParamsQueryVariables>(BlockchainByIdWithParamsDocument, options);
        }
export type BlockchainByIdWithParamsQueryHookResult = ReturnType<typeof useBlockchainByIdWithParamsQuery>;
export type BlockchainByIdWithParamsLazyQueryHookResult = ReturnType<typeof useBlockchainByIdWithParamsLazyQuery>;
export type BlockchainByIdWithParamsQueryResult = Apollo.QueryResult<BlockchainByIdWithParamsQuery, BlockchainByIdWithParamsQueryVariables>;
export const ProposalsDocument = gql`
    query proposals($blockchainIds: [ID!]!, $isActual: Boolean!, $statuses: [Int!]!, $searchText: String!, $includeScam: Boolean!, $skip: Int, $take: Int) {
  proposals(
    blockchainIds: $blockchainIds
    isActual: $isActual
    statuses: $statuses
    searchText: $searchText
    includeScam: $includeScam
    skip: $skip
    take: $take
  ) {
    ...ProposalsListItem
  }
}
    ${ProposalsListItemFragmentDoc}`;

/**
 * __useProposalsQuery__
 *
 * To run a query within a React component, call `useProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalsQuery({
 *   variables: {
 *      blockchainIds: // value for 'blockchainIds'
 *      isActual: // value for 'isActual'
 *      statuses: // value for 'statuses'
 *      searchText: // value for 'searchText'
 *      includeScam: // value for 'includeScam'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useProposalsQuery(baseOptions: Apollo.QueryHookOptions<ProposalsQuery, ProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalsQuery, ProposalsQueryVariables>(ProposalsDocument, options);
      }
export function useProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalsQuery, ProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalsQuery, ProposalsQueryVariables>(ProposalsDocument, options);
        }
export type ProposalsQueryHookResult = ReturnType<typeof useProposalsQuery>;
export type ProposalsLazyQueryHookResult = ReturnType<typeof useProposalsLazyQuery>;
export type ProposalsQueryResult = Apollo.QueryResult<ProposalsQuery, ProposalsQueryVariables>;
export const ProposalByIdDocument = gql`
    query ProposalById($proposalId: ID!) {
  proposalById(proposalId: $proposalId) {
    ...ProposalsListItem
  }
}
    ${ProposalsListItemFragmentDoc}`;

/**
 * __useProposalByIdQuery__
 *
 * To run a query within a React component, call `useProposalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalByIdQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useProposalByIdQuery(baseOptions: Apollo.QueryHookOptions<ProposalByIdQuery, ProposalByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalByIdQuery, ProposalByIdQueryVariables>(ProposalByIdDocument, options);
      }
export function useProposalByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalByIdQuery, ProposalByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalByIdQuery, ProposalByIdQueryVariables>(ProposalByIdDocument, options);
        }
export type ProposalByIdQueryHookResult = ReturnType<typeof useProposalByIdQuery>;
export type ProposalByIdLazyQueryHookResult = ReturnType<typeof useProposalByIdLazyQuery>;
export type ProposalByIdQueryResult = Apollo.QueryResult<ProposalByIdQuery, ProposalByIdQueryVariables>;
export const TallyByProposalDocument = gql`
    query TallyByProposal($proposalId: ID!) {
  tallyByProposal(proposalId: $proposalId) {
    ...TalliesListItem
  }
}
    ${TalliesListItemFragmentDoc}`;

/**
 * __useTallyByProposalQuery__
 *
 * To run a query within a React component, call `useTallyByProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTallyByProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTallyByProposalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useTallyByProposalQuery(baseOptions: Apollo.QueryHookOptions<TallyByProposalQuery, TallyByProposalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TallyByProposalQuery, TallyByProposalQueryVariables>(TallyByProposalDocument, options);
      }
export function useTallyByProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TallyByProposalQuery, TallyByProposalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TallyByProposalQuery, TallyByProposalQueryVariables>(TallyByProposalDocument, options);
        }
export type TallyByProposalQueryHookResult = ReturnType<typeof useTallyByProposalQuery>;
export type TallyByProposalLazyQueryHookResult = ReturnType<typeof useTallyByProposalLazyQuery>;
export type TallyByProposalQueryResult = Apollo.QueryResult<TallyByProposalQuery, TallyByProposalQueryVariables>;
export const ValidatorGroupsDocument = gql`
    query ValidatorGroups($skip: Int, $take: Int) {
  validatorGroups(skip: $skip, take: $take) {
    ...ValidatorGroupItem
  }
}
    ${ValidatorGroupItemFragmentDoc}`;

/**
 * __useValidatorGroupsQuery__
 *
 * To run a query within a React component, call `useValidatorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorGroupsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useValidatorGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ValidatorGroupsQuery, ValidatorGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidatorGroupsQuery, ValidatorGroupsQueryVariables>(ValidatorGroupsDocument, options);
      }
export function useValidatorGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatorGroupsQuery, ValidatorGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidatorGroupsQuery, ValidatorGroupsQueryVariables>(ValidatorGroupsDocument, options);
        }
export type ValidatorGroupsQueryHookResult = ReturnType<typeof useValidatorGroupsQuery>;
export type ValidatorGroupsLazyQueryHookResult = ReturnType<typeof useValidatorGroupsLazyQuery>;
export type ValidatorGroupsQueryResult = Apollo.QueryResult<ValidatorGroupsQuery, ValidatorGroupsQueryVariables>;
export const ValidatorGroupByIdDocument = gql`
    query ValidatorGroupById($validatorGroupId: ID!) {
  validatorGroupById(validatorGroupId: $validatorGroupId) {
    ...ValidatorGroupItem
  }
}
    ${ValidatorGroupItemFragmentDoc}`;

/**
 * __useValidatorGroupByIdQuery__
 *
 * To run a query within a React component, call `useValidatorGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorGroupByIdQuery({
 *   variables: {
 *      validatorGroupId: // value for 'validatorGroupId'
 *   },
 * });
 */
export function useValidatorGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<ValidatorGroupByIdQuery, ValidatorGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidatorGroupByIdQuery, ValidatorGroupByIdQueryVariables>(ValidatorGroupByIdDocument, options);
      }
export function useValidatorGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatorGroupByIdQuery, ValidatorGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidatorGroupByIdQuery, ValidatorGroupByIdQueryVariables>(ValidatorGroupByIdDocument, options);
        }
export type ValidatorGroupByIdQueryHookResult = ReturnType<typeof useValidatorGroupByIdQuery>;
export type ValidatorGroupByIdLazyQueryHookResult = ReturnType<typeof useValidatorGroupByIdLazyQuery>;
export type ValidatorGroupByIdQueryResult = Apollo.QueryResult<ValidatorGroupByIdQuery, ValidatorGroupByIdQueryVariables>;
export const ValidatorsByBlockchainIdDocument = gql`
    query ValidatorsByBlockchainId($blockchainId: ID!, $isActive: Boolean!) {
  validatorsByBlockchainId(blockchainId: $blockchainId, isActive: $isActive) {
    ...ValidatorItem
  }
}
    ${ValidatorItemFragmentDoc}`;

/**
 * __useValidatorsByBlockchainIdQuery__
 *
 * To run a query within a React component, call `useValidatorsByBlockchainIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorsByBlockchainIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorsByBlockchainIdQuery({
 *   variables: {
 *      blockchainId: // value for 'blockchainId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useValidatorsByBlockchainIdQuery(baseOptions: Apollo.QueryHookOptions<ValidatorsByBlockchainIdQuery, ValidatorsByBlockchainIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidatorsByBlockchainIdQuery, ValidatorsByBlockchainIdQueryVariables>(ValidatorsByBlockchainIdDocument, options);
      }
export function useValidatorsByBlockchainIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatorsByBlockchainIdQuery, ValidatorsByBlockchainIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidatorsByBlockchainIdQuery, ValidatorsByBlockchainIdQueryVariables>(ValidatorsByBlockchainIdDocument, options);
        }
export type ValidatorsByBlockchainIdQueryHookResult = ReturnType<typeof useValidatorsByBlockchainIdQuery>;
export type ValidatorsByBlockchainIdLazyQueryHookResult = ReturnType<typeof useValidatorsByBlockchainIdLazyQuery>;
export type ValidatorsByBlockchainIdQueryResult = Apollo.QueryResult<ValidatorsByBlockchainIdQuery, ValidatorsByBlockchainIdQueryVariables>;
export const VotesDocument = gql`
    query Votes($voterAddresses: [String!]!) {
  votes(voterAddresses: $voterAddresses) {
    ...VotesListItem
  }
}
    ${VotesListItemFragmentDoc}`;

/**
 * __useVotesQuery__
 *
 * To run a query within a React component, call `useVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotesQuery({
 *   variables: {
 *      voterAddresses: // value for 'voterAddresses'
 *   },
 * });
 */
export function useVotesQuery(baseOptions: Apollo.QueryHookOptions<VotesQuery, VotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VotesQuery, VotesQueryVariables>(VotesDocument, options);
      }
export function useVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VotesQuery, VotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VotesQuery, VotesQueryVariables>(VotesDocument, options);
        }
export type VotesQueryHookResult = ReturnType<typeof useVotesQuery>;
export type VotesLazyQueryHookResult = ReturnType<typeof useVotesLazyQuery>;
export type VotesQueryResult = Apollo.QueryResult<VotesQuery, VotesQueryVariables>;
export const AddVoteDocument = gql`
    mutation AddVote($data: AddVoteInput!) {
  addVote(data: $data) {
    __typename
    chainProposalId
    id
    option
    proposalId
    voterAddress
  }
}
    `;
export type AddVoteMutationFn = Apollo.MutationFunction<AddVoteMutation, AddVoteMutationVariables>;

/**
 * __useAddVoteMutation__
 *
 * To run a mutation, you first call `useAddVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoteMutation, { data, loading, error }] = useAddVoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddVoteMutation(baseOptions?: Apollo.MutationHookOptions<AddVoteMutation, AddVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVoteMutation, AddVoteMutationVariables>(AddVoteDocument, options);
      }
export type AddVoteMutationHookResult = ReturnType<typeof useAddVoteMutation>;
export type AddVoteMutationResult = Apollo.MutationResult<AddVoteMutation>;
export type AddVoteMutationOptions = Apollo.BaseMutationOptions<AddVoteMutation, AddVoteMutationVariables>;
export type AssetKeySpecifier = ('address' | 'base' | 'coingeckoId' | 'denom' | 'denomUnits' | 'description' | 'display' | 'icon' | 'id' | 'keywords' | 'logoURIs' | 'name' | 'priceInUSD' | 'symbol' | 'typeAsset' | AssetKeySpecifier)[];
export type AssetFieldPolicy = {
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	base?: FieldPolicy<any> | FieldReadFunction<any>,
	coingeckoId?: FieldPolicy<any> | FieldReadFunction<any>,
	denom?: FieldPolicy<any> | FieldReadFunction<any>,
	denomUnits?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	display?: FieldPolicy<any> | FieldReadFunction<any>,
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	keywords?: FieldPolicy<any> | FieldReadFunction<any>,
	logoURIs?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	priceInUSD?: FieldPolicy<any> | FieldReadFunction<any>,
	symbol?: FieldPolicy<any> | FieldReadFunction<any>,
	typeAsset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BlockchainKeySpecifier = ('TVL' | 'TVLPercent' | 'TVLinUSD' | 'apis' | 'assets' | 'bech32Prefix' | 'chainId' | 'chainName' | 'createdAt' | 'distributionParams' | 'explorers' | 'feeToken' | 'govParams' | 'icon' | 'id' | 'mintParams' | 'networkType' | 'params' | 'prettyName' | 'raw' | 'slashingParams' | 'stakingParams' | 'status' | 'website' | BlockchainKeySpecifier)[];
export type BlockchainFieldPolicy = {
	TVL?: FieldPolicy<any> | FieldReadFunction<any>,
	TVLPercent?: FieldPolicy<any> | FieldReadFunction<any>,
	TVLinUSD?: FieldPolicy<any> | FieldReadFunction<any>,
	apis?: FieldPolicy<any> | FieldReadFunction<any>,
	assets?: FieldPolicy<any> | FieldReadFunction<any>,
	bech32Prefix?: FieldPolicy<any> | FieldReadFunction<any>,
	chainId?: FieldPolicy<any> | FieldReadFunction<any>,
	chainName?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	distributionParams?: FieldPolicy<any> | FieldReadFunction<any>,
	explorers?: FieldPolicy<any> | FieldReadFunction<any>,
	feeToken?: FieldPolicy<any> | FieldReadFunction<any>,
	govParams?: FieldPolicy<any> | FieldReadFunction<any>,
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	mintParams?: FieldPolicy<any> | FieldReadFunction<any>,
	networkType?: FieldPolicy<any> | FieldReadFunction<any>,
	params?: FieldPolicy<any> | FieldReadFunction<any>,
	prettyName?: FieldPolicy<any> | FieldReadFunction<any>,
	raw?: FieldPolicy<any> | FieldReadFunction<any>,
	slashingParams?: FieldPolicy<any> | FieldReadFunction<any>,
	stakingParams?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	website?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addVote' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addVote?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProposalKeySpecifier = ('blockchain' | 'blockchainId' | 'chainProposalId' | 'content' | 'currentTallyResult' | 'depositEndTime' | 'description' | 'finalTallyResult' | 'id' | 'isEmpty' | 'isExpedited' | 'isPublished' | 'isScam' | 'status' | 'submitTime' | 'title' | 'totalDeposit' | 'type' | 'votes' | 'votingEndTime' | 'votingStartTime' | ProposalKeySpecifier)[];
export type ProposalFieldPolicy = {
	blockchain?: FieldPolicy<any> | FieldReadFunction<any>,
	blockchainId?: FieldPolicy<any> | FieldReadFunction<any>,
	chainProposalId?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	currentTallyResult?: FieldPolicy<any> | FieldReadFunction<any>,
	depositEndTime?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	finalTallyResult?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isEmpty?: FieldPolicy<any> | FieldReadFunction<any>,
	isExpedited?: FieldPolicy<any> | FieldReadFunction<any>,
	isPublished?: FieldPolicy<any> | FieldReadFunction<any>,
	isScam?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	submitTime?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	totalDeposit?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	votes?: FieldPolicy<any> | FieldReadFunction<any>,
	votingEndTime?: FieldPolicy<any> | FieldReadFunction<any>,
	votingStartTime?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('blockchainById' | 'blockchains' | 'proposalById' | 'proposals' | 'tallyByProposal' | 'validatorGroupById' | 'validatorGroups' | 'validatorsByBlockchainId' | 'votes' | 'votesByProposal' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	blockchainById?: FieldPolicy<any> | FieldReadFunction<any>,
	blockchains?: FieldPolicy<any> | FieldReadFunction<any>,
	proposalById?: FieldPolicy<any> | FieldReadFunction<any>,
	proposals?: FieldPolicy<any> | FieldReadFunction<any>,
	tallyByProposal?: FieldPolicy<any> | FieldReadFunction<any>,
	validatorGroupById?: FieldPolicy<any> | FieldReadFunction<any>,
	validatorGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	validatorsByBlockchainId?: FieldPolicy<any> | FieldReadFunction<any>,
	votes?: FieldPolicy<any> | FieldReadFunction<any>,
	votesByProposal?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TallyKeySpecifier = ('abstain' | 'id' | 'no' | 'noWithVeto' | 'proposal' | 'proposalId' | 'yes' | TallyKeySpecifier)[];
export type TallyFieldPolicy = {
	abstain?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	no?: FieldPolicy<any> | FieldReadFunction<any>,
	noWithVeto?: FieldPolicy<any> | FieldReadFunction<any>,
	proposal?: FieldPolicy<any> | FieldReadFunction<any>,
	proposalId?: FieldPolicy<any> | FieldReadFunction<any>,
	yes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ValidatorKeySpecifier = ('accountAddress' | 'blockchain' | 'blockchainId' | 'commission' | 'commissionSimple' | 'delegatorShares' | 'details' | 'icon' | 'id' | 'jailed' | 'minSelfDelegation' | 'moniker' | 'operatorAddress' | 'rank' | 'securityContact' | 'status' | 'tokens' | 'unbondingHeight' | 'unbondingTime' | 'validatorGroup' | 'validatorGroupId' | 'website' | 'weight' | ValidatorKeySpecifier)[];
export type ValidatorFieldPolicy = {
	accountAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	blockchain?: FieldPolicy<any> | FieldReadFunction<any>,
	blockchainId?: FieldPolicy<any> | FieldReadFunction<any>,
	commission?: FieldPolicy<any> | FieldReadFunction<any>,
	commissionSimple?: FieldPolicy<any> | FieldReadFunction<any>,
	delegatorShares?: FieldPolicy<any> | FieldReadFunction<any>,
	details?: FieldPolicy<any> | FieldReadFunction<any>,
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	jailed?: FieldPolicy<any> | FieldReadFunction<any>,
	minSelfDelegation?: FieldPolicy<any> | FieldReadFunction<any>,
	moniker?: FieldPolicy<any> | FieldReadFunction<any>,
	operatorAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	rank?: FieldPolicy<any> | FieldReadFunction<any>,
	securityContact?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	unbondingHeight?: FieldPolicy<any> | FieldReadFunction<any>,
	unbondingTime?: FieldPolicy<any> | FieldReadFunction<any>,
	validatorGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	validatorGroupId?: FieldPolicy<any> | FieldReadFunction<any>,
	website?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ValidatorGroupKeySpecifier = ('bio' | 'fullName' | 'id' | 'identity' | 'keybaseId' | 'location' | 'picturePrimary' | 'proofs' | 'topPlace' | 'username' | 'usernameCased' | 'validators' | 'weight' | ValidatorGroupKeySpecifier)[];
export type ValidatorGroupFieldPolicy = {
	bio?: FieldPolicy<any> | FieldReadFunction<any>,
	fullName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	identity?: FieldPolicy<any> | FieldReadFunction<any>,
	keybaseId?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	picturePrimary?: FieldPolicy<any> | FieldReadFunction<any>,
	proofs?: FieldPolicy<any> | FieldReadFunction<any>,
	topPlace?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	usernameCased?: FieldPolicy<any> | FieldReadFunction<any>,
	validators?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ValidatorIdentityProofKeySpecifier = ('humanUrl' | 'nametag' | 'presentationGroup' | 'proofId' | 'proofType' | 'serviceUrl' | ValidatorIdentityProofKeySpecifier)[];
export type ValidatorIdentityProofFieldPolicy = {
	humanUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	nametag?: FieldPolicy<any> | FieldReadFunction<any>,
	presentationGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	proofId?: FieldPolicy<any> | FieldReadFunction<any>,
	proofType?: FieldPolicy<any> | FieldReadFunction<any>,
	serviceUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VoteKeySpecifier = ('chainProposalId' | 'id' | 'option' | 'proposal' | 'proposalId' | 'voterAddress' | VoteKeySpecifier)[];
export type VoteFieldPolicy = {
	chainProposalId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	option?: FieldPolicy<any> | FieldReadFunction<any>,
	proposal?: FieldPolicy<any> | FieldReadFunction<any>,
	proposalId?: FieldPolicy<any> | FieldReadFunction<any>,
	voterAddress?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Asset?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AssetKeySpecifier | (() => undefined | AssetKeySpecifier),
		fields?: AssetFieldPolicy,
	},
	Blockchain?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BlockchainKeySpecifier | (() => undefined | BlockchainKeySpecifier),
		fields?: BlockchainFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	Proposal?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProposalKeySpecifier | (() => undefined | ProposalKeySpecifier),
		fields?: ProposalFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Tally?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TallyKeySpecifier | (() => undefined | TallyKeySpecifier),
		fields?: TallyFieldPolicy,
	},
	Validator?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ValidatorKeySpecifier | (() => undefined | ValidatorKeySpecifier),
		fields?: ValidatorFieldPolicy,
	},
	ValidatorGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ValidatorGroupKeySpecifier | (() => undefined | ValidatorGroupKeySpecifier),
		fields?: ValidatorGroupFieldPolicy,
	},
	ValidatorIdentityProof?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ValidatorIdentityProofKeySpecifier | (() => undefined | ValidatorIdentityProofKeySpecifier),
		fields?: ValidatorIdentityProofFieldPolicy,
	},
	Vote?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VoteKeySpecifier | (() => undefined | VoteKeySpecifier),
		fields?: VoteFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;