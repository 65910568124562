import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Grid, IconButton, Paper, Skeleton, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWalletContext } from '../../context/wallet-context';
import { ChainFilterType, ProposalStatus } from '../../enum';
import { ProposalsListItemFragment, useProposalsLazyQuery } from '../../generated/graphql';
import { useDebounce } from '../../hooks/useDebounce';
import { VotingFilterState } from '../../types';
import VotingCard from './voting-card/voting-card';
import VotingFiltersMenu from './voting-filters/voting-filters-menu';

function VotingModule() {
  const [filter, setFilter] = useState<VotingFilterState>({
    chainsType: ChainFilterType.CONNECTED,
    searchText: '',
    isActual: false,
    selectedChains: [],
    statuses: [ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD],
    loaded: false,
    includeScam: false,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [hasMoreProposals, setHasMoreProposals] = useState<boolean>(true);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const dSearchText = useDebounce<string>(searchText, 1000);
  const {
    blockchainsQuery: { data: blockchains },
    enabledChains,
  } = useWalletContext();

  const blockchainIds = useMemo(() => {
    if (!blockchains) {
      return [];
    }

    switch (filter.chainsType) {
      case ChainFilterType.ALL:
        return blockchains?.blockchains?.map((b) => b.id) || [];
      case ChainFilterType.CONNECTED:
        if (enabledChains.length) {
          return (
            blockchains?.blockchains?.filter((b) => enabledChains.indexOf(b.chainName) !== -1).map((b) => b.id) || []
          );
        } else {
          return ['1', '2'];
        }
      default:
        return filter.selectedChains;
    }
  }, [filter, enabledChains, blockchains]);

  const [loadProposals, { data: proposalsData, loading: proposalsLoading, fetchMore: fetchMoreProposals }] =
    useProposalsLazyQuery();

  useEffect(() => {
    onFilter({ searchText: dSearchText });
  }, [dSearchText]);

  useEffect(() => {
    const load = async () => {
      if (blockchainIds.length) {
        const proposalsResult = await loadProposals({
          variables: {
            take: 50,
            skip: 0,
            blockchainIds,
            isActual: filter.isActual,
            statuses: filter.statuses,
            searchText: filter.searchText,
            includeScam: filter.includeScam,
          },
        });
        if (
          !proposalsResult?.data?.proposals.length ||
          proposalsResult.data.proposals.length < (proposalsResult.variables?.take || 50)
        ) {
          setHasMoreProposals(false);
        }
      } else if (filter.loaded) {
        setHasMoreProposals(false);
      }
    };
    load();
  }, [blockchainIds, filter, dSearchText, loadProposals]);

  const onFilter = (update: Partial<VotingFilterState>): void => {
    setHasMoreProposals(true);
    setFilter((current) => ({ ...current, ...update }));
  };

  const loadMoreProposals = () => {
    const take = 10;
    fetchMoreProposals({
      variables: {
        take,
        skip: proposalsData ? proposalsData.proposals.length : 0,
        blockchainIds,
        isActual: filter.isActual,
        statuses: filter.statuses,
        searchText: dSearchText,
        includeScam: filter.includeScam,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult.proposals.length < take) {
          setHasMoreProposals(false);
        }

        if (!fetchMoreResult) {
          setHasMoreProposals(false);
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          proposals: [...previousResult.proposals, ...fetchMoreResult.proposals],
        });
      },
    });
  };

  return (
    <Box m={2}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Proposals
          </Typography>
        </Grid>
        <Grid item alignSelf="flex-end" xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <TextField
              sx={{ marginTop: 1 }}
              fullWidth
              label="Search"
              placeholder="title"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton sx={{ marginRight: -2 }} disabled={!searchText}>
                    <HighlightOffIcon />
                  </IconButton>
                ),
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={(event) => setFilterAnchorEl(event.currentTarget)} color="default">
                <TuneIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <VotingFiltersMenu
          filter={filter}
          onChange={onFilter}
          anchorEl={filterAnchorEl}
          onClose={() => setFilterAnchorEl(null)}
        />
        <Grid item xs={12} sx={{ position: 'relative', minHeight: 300, marginTop: 2 }}>
          <InfiniteScroll
            dataLength={proposalsData ? proposalsData.proposals.length : 0}
            next={loadMoreProposals}
            hasMore={hasMoreProposals}
            loader={
              <Skeleton
                animation="wave"
                height="180px"
                sx={{ transform: 'none', marginTop: 3, backgroundColor: 'background.paper' }}
              />
            }
          >
            <Grid container spacing={2}>
              {!!proposalsData &&
                proposalsData.proposals.map((proposal: ProposalsListItemFragment) => (
                  <VotingCard key={proposal.blockchainId + ' ' + proposal.chainProposalId} proposal={proposal} />
                ))}
            </Grid>
          </InfiniteScroll>
          {!proposalsData?.proposals.length && !proposalsLoading && !hasMoreProposals && (
            <Paper>
              <Box p={5}>
                <Typography textAlign="center" sx={{ color: (theme) => theme.palette.text.disabled }}>
                  No results were found for selected filters...
                </Typography>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default VotingModule;
