import { Box } from '@mui/material';
import React, { FC } from 'react';

interface OwnProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<OwnProps> = ({ value, index, children }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);

export default TabPanel;
