import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { FormikProps, withFormik } from 'formik';
import { Coin } from 'osmojs/types/codegen/cosmos/base/v1beta1/coin';
import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useWalletContext } from '../../../context/wallet-context';
import {
  BlockchainListItemFragment,
  useValidatorsByBlockchainIdQuery,
  ValidatorItemFragment,
} from '../../../generated/graphql';
import { FeeToken, MyDelegations } from '../../../types';
import { coinToFloat, coinToFloatWithName } from '../../../utils/coinToFloat';
import SelectValidator from '../../common/select-validator/select-validator';
import ValidatorAvatar from '../validator-avatar';

interface FormValues {
  amount: string;
  memo: string;
  gas: number;
  operatorAddress: string;
}

interface OwnProps {
  defaultValidator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
  myDelegations: MyDelegations;
  actions: any;
  balance: Coin;
  onClose: () => void;
}

const createValidationSchema = (blockchain: BlockchainListItemFragment, accountBalance: Coin) => {
  return Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount must be a number.')
      .required('Amount is required.')
      .min(10 ** -blockchain.assets[0].denom, "You can't delegate so little.")
      .test('amount', 'Insufficient balance.', (value) => {
        const balance = +(accountBalance?.amount || 0) * 10 ** -blockchain.assets[0].denom;
        return value < balance;
      }),
    memo: Yup.string(),
  });
};

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm: FC<OwnProps & FormikProps<FormValues>> = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  defaultValidator,
  blockchain,
  myDelegations,
  actions,
}) => {
  const { accounts } = useWalletContext();

  const { data: validators } = useValidatorsByBlockchainIdQuery({
    variables: { blockchainId: blockchain.id, isActive: true },
  });

  const [isAdditionalOpened, setIsAdditionalOpened] = useState<boolean>(false);
  const [isSelectValidatorToOpened, setIsSelectValidatorToOpened] = useState<boolean>(false);
  const [validator, setValidator] = useState<ValidatorItemFragment>(defaultValidator);

  const feeObj = JSON.parse(blockchain.feeToken) as FeeToken;

  const balance = accounts[blockchain.chainName]?.balance;
  const delegation = myDelegations[values.operatorAddress] || {};

  useEffect(() => {
    if (validators) {
      const val: ValidatorItemFragment =
        (validator.id && validators.validatorsByBlockchainId.find((v) => v.id === validator.id)) || defaultValidator;

      setFieldValue('operatorAddress', val.operatorAddress);
      setValidator(val);
    }
  }, [validators, validator.id]);

  useEffect(() => {
    const getGas = async () => {
      const gasUsed =
        (await actions.simulateDelegation(blockchain.chainName, balance, defaultValidator.operatorAddress)) || 0;
      await setFieldValue('gas', gasUsed);
    };
    getGas();
  }, []);

  const delegateAll = async () => {
    if (!delegation?.balance) {
      return;
    }

    await setFieldValue(
      'amount',
      coinToFloat(
        +delegation.balance.amount - Math.round(values.gas * feeObj.average_gas_price),
        blockchain.assets[0].denom
      )
    );
  };

  const onSelectValidatorTo = (selected?: ValidatorItemFragment) => {
    setIsSelectValidatorToOpened(false);
    if (selected) {
      setValidator(selected);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SelectValidator
        isOpened={isSelectValidatorToOpened}
        onClose={onSelectValidatorTo}
        blockchain={blockchain}
        selected={validator.id || defaultValidator.id}
      />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)}>
          to
        </Typography>
        <ValidatorAvatar
          img={validator.icon}
          username={validator.moniker}
          sx={{ width: '24px', height: '24px', marginLeft: 1, marginRight: 1 }}
        />
        <Typography
          flexGrow={1}
          variant="h6"
          onClick={() => setIsSelectValidatorToOpened(true)}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '400px',
            overflow: 'hidden',
            marginRight: 2,
          }}
        >
          {validator.moniker}
        </Typography>
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)}>
          {validator.commissionSimple}%
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={() => setIsSelectValidatorToOpened(true)}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Box mt={3} mb={3}>
        <TextField
          fullWidth
          error={touched.amount && !!errors.amount}
          helperText={touched.amount ? errors.amount : ''}
          placeholder="&infin;"
          label="Amount"
          InputProps={{
            endAdornment: <Typography>{blockchain.assets[0].symbol}</Typography>,
          }}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
        />
        {balance && (
          <Box display="flex" justifyContent="flex-end">
            <Link sx={{ color: 'action.disabled', textDecoration: 'none', cursor: 'pointer' }} onClick={delegateAll}>
              Balance: {coinToFloatWithName(balance.amount, blockchain.assets[0].denom, blockchain.assets[0].symbol)}
            </Link>
          </Box>
        )}
        <Link mt={1} alignItems="center" onClick={() => setIsAdditionalOpened(!isAdditionalOpened)}>
          Additional
        </Link>
        <Box display={isAdditionalOpened ? 'block' : 'none'} mt={1}>
          <TextField
            fullWidth
            error={touched.memo && !!errors.memo}
            helperText={touched.memo ? errors.memo : ''}
            multiline={true}
            label="Memo"
            placeholder="Memo"
            name="memo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.memo}
            InputProps={{
              endAdornment: (
                <Tooltip title="A note or comment to send with the transaction.">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              ),
            }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" type="submit" disabled={isSubmitting}>
          Delegate
        </Button>
      </Box>
    </form>
  );
};

// Wrap our form with the withFormik HoC
const DelegationForm = withFormik<OwnProps, FormValues>({
  validationSchema: (props: OwnProps) => createValidationSchema(props.blockchain, props.balance),
  mapPropsToValues: (props) => {
    return {
      operatorAddress: props.defaultValidator.operatorAddress,
      amount: '',
      memo: 'votingpower.org',
      gas: 0,
    };
  },

  handleSubmit: async (values, { props: { blockchain, actions, onClose }, resetForm }) => {
    const success = await actions.delegate(
      blockchain.chainName,
      { amount: (+values.amount * 10 ** blockchain.assets[0].denom).toString(10), denom: blockchain.assets[0].base },
      values.operatorAddress,
      values.gas,
      values.memo
    );
    if (success) {
      resetForm();
      onClose();
      await actions.reloadAccount(blockchain.chainName);
    }
  },
})(InnerForm);

export default DelegationForm;
