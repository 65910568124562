import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, Divider, IconButton, Link, styled, Typography } from '@mui/material';
import { BondStatus } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import colors from '../../constants/colors';
import { BlockchainListItemFragment, ValidatorItemFragment } from '../../generated/graphql';
import { coinToFloatWithName } from '../../utils/coinToFloat';
import ValidatorExpandedInfo from '../common/validator-expanded-info';

interface OwnProps {
  validator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
}

const ValidatorItem: FC<OwnProps> = ({ validator, blockchain }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper>
      <Box>
        <Box display="flex" flexDirection="row" mb={1}>
          <Box>
            <Typography
              variant="body1"
              sx={{
                padding: '0px 8px',
                backgroundColor: colors.validatorStatus[validator.status as BondStatus],
                border: '1px solid',
                borderColor: colors.validatorStatus[validator.status as BondStatus],
                borderRadius: '4px',
              }}
            >
              {BondStatus[validator.status].replace('BOND_STATUS_', '')}
            </Typography>
          </Box>
          <Box ml={1}>
            <Typography
              variant="body1"
              sx={{
                padding: '0px 8px',
                border: '1px solid',
                borderColor: 'text.default',
                borderRadius: '4px',
              }}
            >
              Rank #{validator.rank}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1} mt={1}>
          <Box display="flex" flexDirection="row">
            <img
              style={{ width: '50px', height: '50px' }}
              src={blockchain.icon}
              alt={blockchain.prettyName}
              title={`[${blockchain.prettyName}] ${validator.moniker}: ${coinToFloatWithName(
                validator.weight,
                0,
                '$'
              )}`}
            />
            <Box display="flex" flexDirection="column" ml={2}>
              <Link
                variant="subtitle1"
                component={RouterLink}
                to={`/blockchains/${blockchain.id}`}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {blockchain.prettyName}
              </Link>
              <Typography variant="subtitle1" sx={{ marginTop: '-8px' }}>
                VP: {coinToFloatWithName(validator.tokens, blockchain.assets[0].denom, blockchain.assets[0].symbol)} (
                {coinToFloatWithName(validator.weight, 0, '$')})
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
        <ValidatorExpandedInfo validator={validator} blockchain={blockchain} expanded={isExpanded} />
      </Box>
    </Wrapper>
  );
};

export default ValidatorItem;

const Wrapper = styled(Card)({
  marginBottom: '8px',
  padding: '16px',
});
