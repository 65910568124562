import { Box, Typography } from '@mui/material';
import React from 'react';
import colors, { votingKeysArr } from '../../../constants/colors';
import { Tally, VoteOption } from '../../../enum';
import { ProposalsListItemFragment, TalliesListItemFragment } from '../../../generated/graphql';
import { TallyResult } from '../../../types';
import { coinToFloatWithName } from '../../../utils/coinToFloat';
import finalTallyResultsToTallyResults from '../../../utils/finalTallyResultsToTallyResults';

import CustomTooltip from '../../common/Tooltip/Tooltip';

function VotingProgress({ proposal }: { proposal: ProposalsListItemFragment; tally?: TalliesListItemFragment }) {
  const result: TallyResult = finalTallyResultsToTallyResults(proposal);
  const votedSum: number = +result.yes + +result.no + +result.abstain + +result.noWithVeto;

  const voted = votedSum
    ? [
        {
          value: (+result.yes * 100) / votedSum,
          key: VoteOption.VOTE_OPTION_YES,
        },
        {
          value: (+result.no * 100) / votedSum,
          key: VoteOption.VOTE_OPTION_NO,
        },
        {
          value: (+result.noWithVeto * 100) / votedSum,
          key: VoteOption.VOTE_OPTION_NO_WITH_VETO,
        },
        {
          value: (+result.abstain * 100) / votedSum,
          key: VoteOption.VOTE_OPTION_ABSTAIN,
        },
      ]
    : [
        { value: 0, key: VoteOption.VOTE_OPTION_YES },
        { value: 0, key: VoteOption.VOTE_OPTION_NO },
        { value: 0, key: VoteOption.VOTE_OPTION_NO_WITH_VETO },
        { value: 0, key: VoteOption.VOTE_OPTION_ABSTAIN },
      ];

  return (
    <CustomTooltip
      content={
        <React.Fragment>
          <div
            style={{
              position: 'relative',
              width: '100%',
              zIndex: 0,
            }}
          >
            {voted.map((v, i) => (
              <Box
                key={i}
                sx={{
                  top: (i + 1) * 24,
                  position: 'absolute',
                  width: `${v.value}%`,
                  height: '24px',
                  backgroundColor: colors.votes[v.key],
                  zIndex: 0,
                }}
              />
            ))}
          </div>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              zIndex: 1,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              paddingX={1}
              sx={{
                borderBottom: '1px solid #fff',
              }}
            >
              <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, fontWeight: 'bold' }}>
                Total:
              </Typography>
              <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, marginLeft: 1, fontWeight: 'bold' }}>
                {coinToFloatWithName(
                  votedSum,
                  proposal.blockchain.assets[0].denom,
                  proposal.blockchain.assets[0].symbol,
                  0
                )}
              </Typography>
            </Box>
            {votingKeysArr.map((k, i) => (
              <Box key={k} display="flex" justifyContent="space-between" paddingX={1}>
                <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, marginRight: '20px' }}>
                  {(k.charAt(0).toUpperCase() + k.slice(1)).replace('NoWithVeto', 'NWV')}:
                </Typography>
                <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, marginLeft: 1 }}>
                  {coinToFloatWithName(
                    result[k as keyof typeof Tally],
                    proposal.blockchain.assets[0].denom,
                    proposal.blockchain.assets[0].symbol,
                    0,
                    false
                  )}{' '}
                  ({voted[i].value.toFixed(2)}%)
                </Typography>
              </Box>
            ))}
          </Box>
        </React.Fragment>
      }
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '10px',
        }}
      >
        <Box
          sx={{
            marginLeft: '0',
            position: 'absolute',
            width: `${voted[0].value}%`,
            height: '100%',
            backgroundColor: colors.votes[VoteOption.VOTE_OPTION_YES],
          }}
        />
        <Box
          sx={{
            marginLeft: `${voted[0].value}%`,
            position: 'absolute',
            width: `${voted[1].value}%`,
            height: '100%',
            borderLeft: '1px solid',
            borderColor: 'background.paper',
            backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO],
          }}
        />
        <Box
          sx={{
            marginLeft: `${voted[0].value + voted[1].value}%`,
            position: 'absolute',
            width: `${voted[2].value}%`,
            height: '100%',
            borderLeft: '1px solid',
            borderColor: 'background.paper',
            backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
          }}
        />
        <Box
          sx={{
            marginLeft: `${voted[0].value + voted[1].value + voted[2].value}%`,
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderLeft: '1px solid',
            borderColor: 'background.paper',
            backgroundColor: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
          }}
        />
      </div>
    </CustomTooltip>
  );
}

export default VotingProgress;
