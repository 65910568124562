import { WalletStatus } from '@cosmos-kit/core';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Box, Card, CardActionArea, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useWalletContext } from '../../../context/wallet-context';
import { BlockchainListItemFragment } from '../../../generated/graphql';
import { coinToFloatWithName } from '../../../utils/coinToFloat';

type OwnProps = {
  chain: BlockchainListItemFragment;
  onClose?: () => void;
};

const ChainListItem: FC<OwnProps> = ({ chain, onClose }) => {
  const { accounts, actions, enabledChains, mainWallet } = useWalletContext();

  const isConnected: Boolean = useMemo(() => {
    return Boolean(enabledChains && enabledChains.indexOf(chain.chainName) !== -1);
  }, [enabledChains, chain.chainName]);

  const toggleChain = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      onClose?.();

      if (isConnected) {
        await actions.disableChain(chain.chainName);
      } else {
        await actions.enableChain(chain.chainName);
      }
    },
    [isConnected, actions]
  );

  const walletConnected = mainWallet.status === WalletStatus.Connected || mainWallet.status === WalletStatus.Connecting;

  const balance: { balance: string; title: string } | undefined = useMemo(() => {
    if (walletConnected && isConnected) {
      const account = accounts[chain.chainName];
      if (account) {
        const amount = Number(account.stakedBalance?.amount) * (account.assets[0].priceInUSD || 0);
        const denom = account.stakedBalance?.denom;

        if (amount && denom) {
          return {
            balance: 'VP: ' + coinToFloatWithName(amount * 10 ** -account.assets[0].denom, 0, '$'),
            title: account.stakedBalance?.amount
              ? coinToFloatWithName(account.stakedBalance?.amount, account.assets[0].denom, account.assets[0].symbol)
              : '',
          };
        } else {
          return { balance: 'VP: 0', title: '0' };
        }
      } else {
        if (mainWallet.isWalletConnecting || mainWallet.isWalletConnected) {
          return { balance: 'loading', title: 'loading' };
        }
      }
    }
  }, [isConnected, accounts, chain.chainName, mainWallet.status]);

  return (
    <Card
      sx={{
        margin: '4px 8px',
        flexGrow: 1,
      }}
    >
      <CardActionArea
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '8px',
          color: 'inherit',
          textAlign: 'left',
          textTransform: 'unset',
        }}
        component={RouterLink}
        to={`/blockchains/${chain.id}`}
        onClick={() => {
          console.log('[SSA] routerLinkClicked: ');
        }}
      >
        <img
          src={chain.icon}
          alt={chain.chainName}
          style={{
            width: '30px',
            height: '30px',
            filter: isConnected ? 'unset' : 'grayscale(100%)',
          }}
        />
        <Box ml={2}>
          <Typography variant="body1" sx={{ lineHeight: 1 }}>
            {chain.prettyName}
          </Typography>
          {isConnected && balance ? (
            <Tooltip
              title={balance?.title || ''}
              placement="right-end"
              disableFocusListener
              enterDelay={500}
              enterNextDelay={500}
            >
              <Typography variant="caption">
                {balance && balance.balance !== 'loading' ? balance.balance : <Skeleton animation="wave" />}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2" sx={{ color: '#ccc' }}>
              {' '}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleChain}
            sx={{
              color: isConnected ? 'text.disabled' : 'text.primary',
            }}
          >
            {isConnected ? <LinkOffIcon /> : <LinkIcon />}
          </IconButton>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ChainListItem;
