import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, IconButton, Link, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BlockchainListItemFragment, ValidatorItemFragment } from '../../../generated/graphql';
import { coinToFloatWithName } from '../../../utils/coinToFloat';
import ValidatorExpandedInfo from '../../common/validator-expanded-info';
import ValidatorAvatar from '../../validator-group-list/validator-avatar';

interface OwnProps {
  validator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
}

const BlockchainValidatorItem: FC<OwnProps> = ({ validator, blockchain }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper>
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" mb={1}>
          <Box mr={3}>
            <Typography
              sx={{
                fontFamily: `'Russo One', sans-serif`,
                fontSize: '48px',
                lineHeight: '1',
                fontWeight: 'bold',
                opacity: 0.7,
              }}
            >
              {validator.rank}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flexGrow={1}>
            <ValidatorAvatar
              key={validator.operatorAddress}
              username={validator.moniker}
              img={validator.icon}
              sx={{ width: '50px', height: '50px' }}
            />
            <Box display="flex" flexDirection="column" ml={2}>
              <Link
                variant="subtitle1"
                component={RouterLink}
                to={`/validator-group/${validator.validatorGroupId}`}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {validator.moniker}
              </Link>
              <Typography variant="subtitle1" sx={{ marginTop: '-8px' }}>
                VP: {coinToFloatWithName(validator.tokens, blockchain.assets[0].denom, blockchain.assets[0].symbol)} (
                {coinToFloatWithName(validator.weight, 0, '$')})
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Typography>{validator.commissionSimple}%</Typography>
            <IconButton onClick={() => setIsExpanded(!isExpanded)} sx={{ marginLeft: '8px' }}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
        <ValidatorExpandedInfo validator={validator} blockchain={blockchain} expanded={isExpanded} />
      </Box>
    </Wrapper>
  );
};

export default BlockchainValidatorItem;

const Wrapper = styled(Card)({
  marginBottom: '8px',
  padding: '16px',
});
