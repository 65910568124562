import { ProposalStatus } from 'cosmjs-types/cosmos/gov/v1beta1/gov';
import React, { FC } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface OwnProps {
  selected: ProposalStatus[];
  onChange: (statuses: ProposalStatus[]) => void;
}

const ProposalStatusSelect: FC<OwnProps> = ({ selected, onChange }) => {
  const handleChangeStatus = (event: SelectChangeEvent<number>) => {
    const status = +event.target.value;
    let statuses: ProposalStatus[];
    if (status === ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED) {
      statuses = [ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD, ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD];
    } else if (status === ProposalStatus.UNRECOGNIZED) {
      statuses = [];
    } else {
      statuses = [status];
    }
    onChange(statuses);
  };

  let value: ProposalStatus;
  if (selected.length === 2) {
    value = ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED;
  } else if (!selected.length) {
    value = ProposalStatus.UNRECOGNIZED;
  } else {
    value = selected[0];
  }

  return (
    <FormControl size="small" sx={{ minWidth: '100px' }}>
      <Select value={value} onChange={handleChangeStatus}>
        <MenuItem value={ProposalStatus.UNRECOGNIZED}>All</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED}>Actual</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD}>Deposit period</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD}>Voting Period</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_PASSED}>Passed</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_REJECTED}>Rejected</MenuItem>
        <MenuItem value={ProposalStatus.PROPOSAL_STATUS_FAILED}>Failed</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProposalStatusSelect;
