import React, { FC } from 'react';
import { Button, styled, Tooltip } from '@mui/material';
import { BlockchainListItemFragment } from '../../../generated/graphql';

interface OwnProps {
  chain: BlockchainListItemFragment;
  selected: boolean;
  onClick: () => void;
}

const VotingFilterChainButton: FC<OwnProps> = ({ chain, onClick, selected }) => {
  return (
    <Tooltip title={chain.prettyName}>
      <ChainButton
        sx={{ filter: selected ? 'grayscale(100%)' : 'unset' }}
        onClick={onClick}
        variant={selected ? 'outlined' : 'contained'}
        size="small"
      >
        <img src={chain.icon} alt={chain.chainName} style={{ width: '30px', height: '30px' }} />
      </ChainButton>
    </Tooltip>
  );
};

export default VotingFilterChainButton;

const ChainButton = styled(Button)({
  marginRight: '4px',
  marginBottom: '8px',
  minWidth: '38px',
  maxWidth: '38px',
  minHeight: '38px',
  maxHeight: '38px',
});
