import ContactMailIcon from '@mui/icons-material/ContactMail';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useWalletContext } from '../../context/wallet-context';
import { BlockchainListItemFragment, ValidatorItemFragment } from '../../generated/graphql';
import { Explorer } from '../../types';
import { coinToFloat, coinToFloatWithName } from '../../utils/coinToFloat';
import { formatDate } from '../../utils/formatDate';
import DelegateModal from '../validator-group-list/delegate-modal';
import AddressText from './address-text';
import ValidatorExplorerIconLink from './validator-explorer-icon-link';

interface ExpandedContentProps {
  validator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
}
interface OwnProps extends ExpandedContentProps {
  expanded: boolean;
}

const ExpandedContent: FC<ExpandedContentProps> = ({ blockchain, validator }) => {
  const [explorers, setExplorers] = useState<Explorer[]>([]);

  useEffect(() => {
    if (blockchain) {
      try {
        const explorers = JSON.parse(blockchain.explorers) as Explorer[];
        if (explorers.length) {
          setExplorers(explorers);
        }
      } catch {}
    }
  }, [blockchain]);

  return (
    <Box mb={1}>
      {validator.website || validator.securityContact ? (
        <Box mt={1}>
          {validator.website && (
            <Grid container spacing={1}>
              <Grid item>
                <LanguageIcon fontSize="small" />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <Link href={validator.website}>{validator.website}</Link>
                </Typography>
              </Grid>
            </Grid>
          )}

          {validator.securityContact && (
            <Grid container spacing={1}>
              <Grid item>
                <ContactMailIcon fontSize="small" />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <Link href={`mailto:${validator.securityContact}`}>{validator.securityContact}</Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      ) : null}

      <Box display={{ xs: 'none', md: 'block' }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
          <Box sx={{ maxWidth: 'calc(50% - 4px)' }}>
            <Typography variant="body2">Operator address</Typography>
            <Typography variant="body1" sx={{ cursor: 'pointer' }} component="div">
              <AddressText text={validator.operatorAddress} />
            </Typography>
          </Box>
          <Box sx={{ maxWidth: 'calc(50% - 4px)', marginLeft: '8px', textAlign: 'right' }}>
            <Typography variant="body2">Account address</Typography>
            <Typography variant="body1" component="div">
              <AddressText text={validator.accountAddress} />
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display={{ xs: 'block', md: 'none' }}>
        <Typography variant="h6" mt={2}>
          Addresses
        </Typography>
        <Typography variant="body1" sx={{ cursor: 'pointer' }} component="div">
          Operator: <AddressText text={validator.operatorAddress} />
        </Typography>
        <Typography variant="body1" component="div">
          Account: <AddressText text={validator.accountAddress} />
        </Typography>
      </Box>

      {validator.details && (
        <Box>
          <Typography variant="h6" mt={2}>
            Description
          </Typography>
          <Typography variant="body2">{validator.details}</Typography>
        </Box>
      )}

      <Typography variant="h6" mt={2}>
        Node Info
      </Typography>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box sx={{ maxWidth: 'calc(50% - 4px)' }}>
            <Typography variant="subtitle1">Name: {validator.moniker}</Typography>
            <Typography variant="subtitle1">
              Commission: {Number(JSON.parse(validator.commission).commission_rates.rate) * 100}%
            </Typography>
            <Typography variant="subtitle1">
              Min self delegation:{' '}
              {coinToFloatWithName(
                validator.minSelfDelegation,
                blockchain.assets[0].denom,
                blockchain.assets[0].symbol
              )}
            </Typography>
          </Box>
          <Box sx={{ maxWidth: 'calc(50% - 4px)', marginLeft: '8px', textAlign: 'right' }}>
            <Typography variant="subtitle1">Jailed: {validator.jailed ? 'Yes' : 'No'}</Typography>
            <Typography variant="subtitle1">Unbonding height: {validator.unbondingHeight}</Typography>
            <Typography variant="subtitle1">Unbonding time: {validator.unbondingTime}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display={{ xs: 'block', md: 'none' }}>
        <Typography variant="subtitle1">Name: {validator.moniker}</Typography>
        <Typography variant="subtitle1">
          Commission: {Number(JSON.parse(validator.commission).commission_rates.rate) * 100}%
        </Typography>
        <Typography variant="subtitle1">
          Min self delegation:{' '}
          {coinToFloatWithName(validator.minSelfDelegation, blockchain.assets[0].denom, blockchain.assets[0].symbol)}
        </Typography>
        <Typography variant="subtitle1">Jailed: {validator.jailed ? 'Yes' : 'No'}</Typography>
        <Typography variant="subtitle1">
          Unbonding height: {validator.unbondingHeight ? validator.unbondingHeight : '-'}
        </Typography>
        <Typography variant="subtitle1">
          Unbonding time: {validator.unbondingTime ? formatDate(+validator.unbondingTime) : '-'}
        </Typography>
      </Box>
      <Typography variant="h6" mt={2}>
        Profiles
      </Typography>
      <Grid container spacing={1}>
        {explorers.map((explorer) => (
          <ValidatorExplorerIconLink explorer={explorer} address={validator.operatorAddress} />
        ))}
      </Grid>
    </Box>
  );
};

const ValidatorExpandedInfo: FC<OwnProps> = ({ validator, blockchain, expanded }) => {
  const { myDelegations, accounts, actions } = useWalletContext();
  const account = accounts[blockchain.chainName];

  const delegation = useMemo(
    () => myDelegations[validator.operatorAddress],
    [myDelegations, validator.operatorAddress]
  );

  const [isDelegatedOpened, setIsDelegatedOpened] = useState(false);
  const asset = blockchain.assets[0];

  const handleCloseDelegatedModal = () => {
    setIsDelegatedOpened(false);
  };

  return (
    <Box>
      <Box>{expanded && <ExpandedContent validator={validator} blockchain={blockchain} />}</Box>
      <Divider />
      {delegation?.delegations ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mt={1}
        >
          <Box mr={2}>
            <Typography
              variant="body1"
              title={coinToFloatWithName(
                delegation.delegations?.amount,
                blockchain.assets[0].denom,
                blockchain.assets[0].symbol,
                undefined,
                true,
                false
              )}
            >
              Delegated: {coinToFloatWithName(delegation.delegations?.amount, asset.denom, asset.symbol)} (
              {coinToFloatWithName(
                coinToFloat((+delegation.delegations?.amount || 0) * asset.priceInUSD, asset.denom),
                0,
                '$'
              )}
              )
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Button variant="contained" size="small" onClick={() => setIsDelegatedOpened(true)}>
              Manage
            </Button>
          </Box>
        </Box>
      ) : account ? (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button variant="contained" size="small" onClick={() => setIsDelegatedOpened(true)}>
            Delegate
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button variant="contained" size="small" onClick={() => actions.enableChain(blockchain.chainName)}>
            Enable chain
          </Button>
        </Box>
      )}
      {isDelegatedOpened && (
        <DelegateModal
          blockchain={blockchain}
          validator={validator}
          isOpened={isDelegatedOpened}
          onClose={handleCloseDelegatedModal}
        />
      )}
    </Box>
  );
};

export default ValidatorExpandedInfo;
