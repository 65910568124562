import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWalletContext } from '../../context/wallet-context';

import { useValidatorGroupsLazyQuery, ValidatorGroupItemFragment } from '../../generated/graphql';
import PreloaderRelative from '../common/preloader-relative';
import ValidatorGroupListItem from './validator-group-list-item';

export default function ValidatorGroupListModule() {
  const [hasMoreValidatorGroups, setHasMoreValidatorGroups] = useState<boolean>(true);
  const { blockchainsQuery: {data: blockchains} } = useWalletContext();

  const [loadValidatorGroups, { called, data: validatorGroupsData, fetchMore: fetchMoreValidatorGroups }] =
    useValidatorGroupsLazyQuery({
      variables: {
        take: 50,
        skip: 0,
      },
    });

  useEffect(() => {
    if (!called) {
      loadValidatorGroups();
    }
  }, [called, loadValidatorGroups]);

  const loadMoreValidatorGroups = () => {
    const take = 10;
    fetchMoreValidatorGroups({
      variables: {
        take,
        skip: validatorGroupsData ? validatorGroupsData.validatorGroups.length : 50,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult.validatorGroups.length < take) {
          setHasMoreValidatorGroups(false);
        }

        if (!fetchMoreResult) {
          setHasMoreValidatorGroups(false);
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          validatorGroups: [...previousResult.validatorGroups, ...fetchMoreResult.validatorGroups],
        });
      },
    });
  };

  return (
    <Box>
      <InfiniteScroll
        dataLength={validatorGroupsData ? validatorGroupsData.validatorGroups.length : 5}
        next={loadMoreValidatorGroups}
        hasMore={hasMoreValidatorGroups} // Replace with a condition based on your data source
        loader={
          <Box display="flex" justifyContent="center" sx={{ position: 'relative', minHeight: 300, marginTop: 4 }}>
            <PreloaderRelative />
          </Box>
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {(!validatorGroupsData ? Array.from(new Array(10)) : validatorGroupsData.validatorGroups).map(
            (validatorGroup: ValidatorGroupItemFragment | undefined, index: number) => (
              <ValidatorGroupListItem
                key={validatorGroup?.id || index}
                index={index + 1}
                validatorGroup={validatorGroup}
                blockchains={blockchains}
              />
            )
          )}
        </Box>
      </InfiniteScroll>
    </Box>
  );
}
