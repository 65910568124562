import { QueryResult } from '@apollo/client';
import { SigningStargateClient } from '@cosmjs/stargate';
import { ChainContext } from '@cosmos-kit/core';
import { useChain, useManager, useWalletClient } from '@cosmos-kit/react';
import { assets, chains } from 'chain-registry';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { Coin } from 'osmojs/types/codegen/cosmos/base/v1beta1/coin';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import SnackbarAction from '../components/common/snackbar-action';
import { VoteOption } from '../enum';
import {
  BlockchainsQuery,
  BlockchainsQueryVariables,
  Exact,
  useAddVoteMutation,
  useBlockchainsQuery,
  useVotesLazyQuery,
  VotesQuery,
} from '../generated/graphql';
import { MainAccountList, MyDelegations } from '../types';
import { createDelegator } from './delegate-tx';
import { createVoter } from './vote-tx';

export interface Actions {
  reloadAccount: (blockchainId: string) => Promise<void>;
  disconnectWallet: () => void;
  connectWallet: () => void;
  enableChain: (chainName: string) => Promise<void>;
  disableChain: (chainName: string) => Promise<void>;
  vote: (chainName: string, proposalId: string, option: VoteOption) => Promise<void>;
  delegate: (chainName: string, amount: Coin, validatorAddress: string, gas: number, memo: string) => Promise<boolean>;
  simulateDelegation: (chainName: string, amount: Coin, validatorAddress: string) => Promise<number | null>;
  undelegate: (
    chainName: string,
    amount: Coin,
    validatorAddress: string,
    gas: number,
    memo: string
  ) => Promise<boolean>;
  redelegate: (
    chainName: string,
    amount: Coin,
    validatorSrcAddress: string,
    validatorDstAddress: string,
    gas: number,
    memo: string
  ) => Promise<boolean>;
}

// TODO: change actions and myDelegations to real types
export type WalletContextType = {
  blockchainsQuery: QueryResult<BlockchainsQuery, BlockchainsQueryVariables>;
  loading: boolean;
  accounts: MainAccountList;
  actions: Actions;
  enabledChains: string[];
  myDelegations: MyDelegations;
  mainWallet: ChainContext;
  votes: QueryResult<VotesQuery, Exact<{ voterAddresses: string | string[] }>>;
};

const WalletContext = React.createContext<WalletContextType | null>(null);

const getEnabledChains = () => {
  const stored = localStorage.getItem('enabledChains');
  return stored ? JSON.parse(stored) : [];
};

const getAccountAddresses = (mainAddress?: string): string[] => {
  if (!mainAddress) return [];
  const stored = localStorage.getItem(`${mainAddress}-addresses`);
  return stored ? JSON.parse(stored) : [];
};

const saveAccountAddresses = (mainAddress: string, addresses: string[]): void => {
  localStorage.setItem(`${mainAddress}-addresses`, JSON.stringify(addresses));
};

const saveEnabledChains = (enabledChains: string[]) => {
  localStorage.setItem('enabledChains', JSON.stringify(enabledChains));
};

const getLastWalletName = (): string | undefined => {
  return localStorage.getItem('lastWalletName') || undefined;
};

const setLastWalletName = (name: string) => {
  localStorage.setItem('lastWalletName', name);
};

function WalletContextProvider({ children }: { children: React.ReactNode }) {
  const blockchainsQuery = useBlockchainsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const blockchains = blockchainsQuery.data;

  const [loading, setLoading] = useState(true);
  const [enabledChains, setEnabledChains] = useState<string[]>(getEnabledChains());
  const [accountAddresses, setAccountAddresses] = useState<string[]>([]);
  const [myDelegations, setMyDelegations] = useState<MyDelegations>({});
  const mainWallet: ChainContext = useChain('cosmoshub');
  const { status: walletStatus, client: walletClient } = useWalletClient(getLastWalletName());
  const { addChains, getWalletRepo } = useManager();

  const [_, votes] = useVotesLazyQuery({
    variables: {
      voterAddresses: accountAddresses,
    },
  });

  useEffect(() => {
    if (mainWallet.address) {
      saveAccountAddresses(mainWallet.address, accountAddresses);
      if (accountAddresses.length) {
        votes.refetch({ voterAddresses: accountAddresses });
        // loadVotes({ variables: { voterAddresses } });
      }
    }
  }, [mainWallet.wallet, accountAddresses]);

  useEffect(() => {
    if (mainWallet.wallet?.name) {
      setLastWalletName(mainWallet.wallet?.name);
    }
  }, [mainWallet]);

  useEffect(() => {
    if (mainWallet.address) {
      const voterAddresses = getAccountAddresses(mainWallet.address);
      setAccountAddresses(voterAddresses);
      if (voterAddresses.length) {
        votes.refetch({ voterAddresses });
      }
    }
  }, [mainWallet.address]);

  useEffect(() => {
    if (blockchains && walletClient && walletStatus === 'Done') {
      for (let enabledChain of enabledChains) {
        initAccount(enabledChain).then((acc) => {
          setAccounts((filled) => ({ ...filled, [enabledChain]: acc }));
        });
      }
    }
  }, [walletStatus, blockchains]);

  const accountsForInit: MainAccountList = {};
  enabledChains.forEach((acc: string) => {
    accountsForInit[acc] = null;
  });
  const [accounts, setAccounts] = useState<MainAccountList>(accountsForInit);
  const [addVoteMutation] = useAddVoteMutation();

  useEffect(() => {
    if (Object.keys(accounts).every((chainName) => accounts[chainName])) {
      setLoading(false);
    }
  }, [accounts]);

  const addChain = async (chainName: string) => {
    if (!walletClient) {
      return;
    }

    ReactGA.event({
      category: 'Wallet',
      action: 'Add chain',
      label: chainName,
    });

    await addChains(
      chains.filter((chain) => chain.chain_name === chainName),
      assets.filter((assets) => assets.chain_name === chainName)
    );
    const walletRepo = getWalletRepo(chainName);
    walletRepo.isActive = true;
    await walletRepo.connect(mainWallet.wallet?.name);
  };

  const enableChain = async (chainName: string, exit: boolean = false): Promise<void> => {
    if (!walletClient) {
      await mainWallet?.connect?.();
    }

    if (!walletClient) {
      return;
    }

    try {
      const chain = chains.filter((chain) => chain.chain_name === chainName)[0];
      await walletClient.enable?.(chain.chain_id);
      const initChain = await initAccount(chainName);
      setAccounts((acc) => ({ ...acc, [chainName]: initChain }));
      setEnabledChains((stateChains: string[]) => {
        const chains = [...stateChains, chainName];
        saveEnabledChains(chains);
        return chains;
      });
    } catch (error: any) {
      if (error.message.indexOf('There is no chain info') !== -1) {
        if (exit) {
          return;
        }
        await addChain(chainName);
        return await enableChain(chainName, true);
      }
      enqueueSnackbar(error?.message, {
        variant: 'warning',
        action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
      });
    }
  };

  const disableChain = async (chainName: string): Promise<void> => {
    setEnabledChains((stateChains: string[]) => {
      const chains = stateChains.filter((acc) => acc !== chainName);
      saveEnabledChains(chains);

      setAccounts((stateAccounts) => {
        const newAccounts: MainAccountList = {};
        Object.keys(stateAccounts).forEach((aChainId) => {
          if (aChainId !== chainName) {
            newAccounts[aChainId] = stateAccounts[aChainId];
          }
        });
        return newAccounts;
      });
      return chains;
    });
  };

  const initAccount = useCallback(
    async (chainName: string) => {
      if (!blockchains || !walletClient) {
        return;
      }

      const blockchain = blockchains.blockchains.filter((n) => n.chainName === chainName)[0];
      await walletClient.enable?.(blockchain.chainId);
      const account = (await walletClient.getSimpleAccount?.(blockchain.chainId)) as any;
      const offlineSigner = await walletClient.getOfflineSigner?.(blockchain.chainId);

      if (!offlineSigner) {
        return;
      }

      setAccountAddresses((state) => {
        if (state.indexOf(account.address) === -1) {
          return [...state, account.address];
        }
        return state;
      });

      const apis = JSON.parse(blockchain.apis);
      const indexesString = localStorage.getItem('apiIndex') || '{}';
      let indexes = JSON.parse(indexesString);
      const apiIndex = indexes[chainName] || 0;
      let reset = false;
      for (let i = apiIndex; i < apis.rpc.length; i++) {
        const rpc = apis.rpc[i];
        try {
          account.cosmJS = await SigningStargateClient.connectWithSigner(rpc.address, offlineSigner);
          account.qc = account.cosmJS.getQueryClient();
          account.vote = await createVoter(account.cosmJS, (resp) => console.log('setResp', resp), blockchain);
          account.delegator = await createDelegator(account.cosmJS, blockchain);

          if (!account.qc) {
            continue;
          }

          account.assets = blockchain.assets;
          account.balance = await account.cosmJS.getBalance(account.address, account.assets[0].base);
          account.stakedBalance = await account.cosmJS.getBalanceStaked(account.address);

          account.delegations = [];

          const validators = await account.qc.staking.delegatorValidators(account.address);
          for (let validator of validators.validators) {
            const delegation = await account.qc.staking.delegation(account.address, validator.operatorAddress);
            if (delegation && delegation.delegationResponse) {
              setMyDelegations((delegations) => ({
                ...delegations,
                [validator.operatorAddress]: {
                  delegations: delegation.delegationResponse.balance,
                  balance: account.balance,
                },
              }));
              account.delegations.push({
                validator,
                balance: delegation.delegationResponse.balance,
              });
            }
          }

          const ins = JSON.parse(localStorage.getItem('apiIndex') || '{}');
          ins[chainName] = i;
          localStorage.setItem('apiIndex', JSON.stringify(ins));
          break;
        } catch (e) {
          console.error(rpc.address + ' is not available', e);
          if (apiIndex >= apis.rpc.length && !reset) {
            i = 0;
            reset = true;
          }
        }
      }

      return account;
    },
    [blockchains, walletClient]
  );

  const reloadAccount = async (chainName: string): Promise<void> => {
    await initAccount(chainName).then((acc) => {
      setAccounts((filled) => ({ ...filled, [chainName]: acc }));
    });
  };

  const vote = async (chainName: string, proposalId: string, option: VoteOption): Promise<void> => {
    const account = accounts[chainName];
    const blockchain = blockchains!.blockchains.filter((n) => n.chainName === chainName)[0];
    if (account) {
      const isSuccess = await account.vote(proposalId, account.address, option);
      if (isSuccess) {
        await addVoteMutation({
          variables: {
            data: {
              chainId: blockchain.chainId,
              option,
              chainProposalId: proposalId,
              voterAddress: account.address,
            },
          },
          refetchQueries: ['Votes'],
        });
      }
    } else {
      await actions.enableChain(chainName);
    }
  };

  const delegate = async (
    chainName: string,
    amount: Coin,
    validatorAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const account = accounts[chainName];
    if (account) {
      return await account.delegator.delegate(amount, account.address, validatorAddress, gas, memo);
    } else {
      await actions.enableChain(chainName);
      return false;
    }
  };

  const undelegate = async (
    chainName: string,
    amount: Coin,
    validatorAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const account = accounts[chainName];
    if (account) {
      return await account.delegator.undelegate(amount, account.address, validatorAddress, gas, memo);
    } else {
      await actions.enableChain(chainName);
      return false;
    }
  };

  const redelegate = async (
    chainName: string,
    amount: Coin,
    validatorSrcAddress: string,
    validatorDstAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const account = accounts[chainName];
    if (account) {
      return await account.delegator.redelegate(
        amount,
        account.address,
        validatorSrcAddress,
        validatorDstAddress,
        gas,
        memo
      );
    } else {
      await actions.enableChain(chainName);
      return false;
    }
  };

  const simulateDelegation = async (
    chainName: string,
    amount: Coin,
    validatorAddress: string
  ): Promise<number | null> => {
    const account = accounts[chainName];
    if (account) {
      return Math.round((await account.delegator.simulate(amount, account.address, validatorAddress)) * 1.1) || 0;
    } else {
      await actions.enableChain(chainName);
      return null;
    }
  };

  const disconnectWallet = () => {
    mainWallet?.disconnect?.();
    localStorage.removeItem('lastWalletName');
    setAccounts({});
  };

  const connectWallet = () => {
    ReactGA.event({
      category: 'Wallet',
      action: 'Connect',
    });

    mainWallet?.connect?.();
  };

  const actions: Actions = {
    connectWallet,
    disconnectWallet,
    enableChain,
    disableChain,
    reloadAccount,
    vote,
    delegate,
    simulateDelegation,
    undelegate,
    redelegate,
  };

  const providerValue = useMemo(
    () => ({ blockchainsQuery, votes, mainWallet, loading, accounts, actions, enabledChains, myDelegations }),
    [blockchainsQuery, votes, mainWallet, loading, accounts, actions, enabledChains, myDelegations]
  );

  return <WalletContext.Provider value={providerValue}>{children}</WalletContext.Provider>;
}

const useWalletContext = (): WalletContextType => {
  const context = React.useContext<WalletContextType | null>(WalletContext);
  if (context === null) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export { WalletContext, WalletContextProvider, useWalletContext };
