import { StdFee } from '@cosmjs/amino';
import { EncodeObject } from '@cosmjs/proto-signing';
import { calculateFee, GasPrice, SigningStargateClient } from '@cosmjs/stargate';
import { CircularProgress } from '@mui/material';
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from 'notistack';
import { cosmos } from 'osmojs';
import { Coin } from 'osmojs/types/codegen/cosmos/base/v1beta1/coin';
import ReactGA from 'react-ga4';
import { SnackbarAction, SnackbarActionWithUrl } from '../components/common/snackbar-action';

import { BlockchainListItemFragment } from '../generated/graphql';
import { FeeToken } from '../types';
import { coinToFloatWithName } from '../utils/coinToFloat';

type DelegateFnc = (
  amount: Coin,
  delegatorAddress: string,
  validatorAddress: string,
  gas: number,
  memo: string
) => Promise<boolean>;

type RedelegateFnc = (
  amount: Coin,
  delegatorAddress: string,
  validatorSrcAddress: string,
  validatorDstAddress: string,
  gas: number,
  memo: string
) => Promise<boolean>;

type UndelegateFnc = DelegateFnc;

type SimulateFnc = (amount: Coin, delegatorAddress: string, validatorAddress: string) => Promise<number>;

export type Delegator = {
  delegate: DelegateFnc;
  undelegate: UndelegateFnc;
  redelegate: RedelegateFnc;
  simulate: SimulateFnc;
};

export type CreateDelegator = (
  stargateClient: SigningStargateClient,
  blockchain: BlockchainListItemFragment
) => Promise<Delegator>;

export const createDelegator: CreateDelegator = async (stargateClient, blockchain) => {
  let gasUsed: number = 0;
  const feeObj = JSON.parse(blockchain.feeToken) as FeeToken;

  const getFee = (gas: number): StdFee => {
    const gasPriceString =
      (feeObj.low_gas_price ||
        feeObj.average_gas_price ||
        feeObj.high_gas_price ||
        feeObj.fixed_min_gas_price ||
        0.01) + feeObj.denom;
    const defaultGasPrice = GasPrice.fromString(gasPriceString);
    return calculateFee(Math.round(gas * 1.5), defaultGasPrice);
  };

  const simulate = async (amount: Coin, delegatorAddress: string, validatorAddress: string): Promise<number> => {
    const { delegate } = cosmos.staking.v1beta1.MessageComposer.withTypeUrl;
    const msg = delegate({
      amount,
      delegatorAddress,
      validatorAddress,
    });

    try {
      gasUsed = await stargateClient.simulate(delegatorAddress, [msg], 'votingpower.org');
    } catch (error: any) {
      console.log('Error: ', error);
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
      });
    }

    return gasUsed;
  };

  const process = async (
    delegatorAddress: string,
    msg: EncodeObject,
    memo: string,
    gas: number,
    amount: Coin,
    actionName: string
  ): Promise<boolean> => {
    if (!stargateClient) {
      enqueueSnackbar('Stargate client is not found.', {
        variant: 'error',
        action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
      });
      return false;
    }

    const fee = getFee(gas);

    const waitingSnackKey = enqueueSnackbar(`Waiting for your transaction`, {
      variant: 'info',
      autoHideDuration: null,
      action: <CircularProgress size="1rem" color="secondary" />,
    });
    try {
      const response = await stargateClient.signAndBroadcast(delegatorAddress, [msg], fee || 'auto', memo);
      closeSnackbar(waitingSnackKey);
      console.log('[SSA] response: ', response);
      const txUrl = JSON.parse(blockchain.explorers)[0].tx_page.replace('${txHash}', response.transactionHash);

      try {
        const rawLog = response.rawLog ? JSON.parse(response.rawLog) : response.rawLog;
        if (rawLog) {
          try {
            enqueueSnackbar(
              `You successfully ${actionName}d ${coinToFloatWithName(
                amount.amount,
                blockchain.assets[0].denom,
                blockchain.assets[0].symbol
              )}`,
              {
                variant: 'success',
                action: (key: SnackbarKey) => <SnackbarActionWithUrl url={txUrl} closeKey={key} />,
              }
            );
          } catch {
            enqueueSnackbar(
              `You successfully ${actionName}d ${coinToFloatWithName(
                amount.amount,
                blockchain.assets[0].denom,
                blockchain.assets[0].symbol
              )}`,
              {
                variant: 'success',
                action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
              }
            );
          }
        }
        ReactGA.event({
          category: 'Delegation',
          action: actionName,
        });
        return true;
      } catch {
        ReactGA.event({
          category: 'Delegation',
          action: 'Error: ' + actionName,
        });
        enqueueSnackbar(`Error sending transaction: "${response.rawLog}"`, {
          variant: 'error',
          autoHideDuration: 10000,
          action: (key: SnackbarKey) =>
            txUrl ? <SnackbarActionWithUrl url={txUrl} closeKey={key} /> : <SnackbarAction closeKey={key} />,
        });
        return false;
      }
    } catch (error: any) {
      closeSnackbar(waitingSnackKey);
      if (error?.message === 'Request rejected') {
        ReactGA.event({
          category: 'Delegation',
          action: 'Rejected: ' + actionName,
        });
        enqueueSnackbar('You rejected the transaction', {
          variant: 'warning',
          action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
        });
      } else {
        ReactGA.event({
          category: 'Delegation',
          action: 'Error: ' + actionName,
        });
        enqueueSnackbar(error?.message, {
          variant: 'error',
          autoHideDuration: 10000,
          action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
        });
      }
    }
    return false;
  };

  const delegate = async (
    amount: Coin,
    delegatorAddress: string,
    validatorAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const { delegate: delegateMsg } = cosmos.staking.v1beta1.MessageComposer.withTypeUrl;

    const msg = delegateMsg({
      amount,
      delegatorAddress,
      validatorAddress,
    });

    return process(delegatorAddress, msg, memo, gas, amount, 'delegate');
  };

  const undelegate = async (
    amount: Coin,
    delegatorAddress: string,
    validatorAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const { undelegate: undelegateMsg } = cosmos.staking.v1beta1.MessageComposer.withTypeUrl;

    const msg = undelegateMsg({
      amount,
      delegatorAddress,
      validatorAddress,
    });

    return process(delegatorAddress, msg, memo, gas, amount, 'undelegate');
  };

  const redelegate = async (
    amount: Coin,
    delegatorAddress: string,
    validatorSrcAddress: string,
    validatorDstAddress: string,
    gas: number,
    memo: string
  ): Promise<boolean> => {
    const { beginRedelegate: redelegateMsg } = cosmos.staking.v1beta1.MessageComposer.withTypeUrl;

    const msg = redelegateMsg({
      amount,
      delegatorAddress,
      validatorSrcAddress,
      validatorDstAddress,
    });

    return process(delegatorAddress, msg, memo, gas, amount, 'redelegate');
  };

  return {
    delegate,
    simulate,
    undelegate,
    redelegate,
  };
};
