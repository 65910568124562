import { SearchOutlined } from '@mui/icons-material';
import { Box, IconButton, Skeleton, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useMemo, useState } from 'react';
import { useWalletContext } from '../../../context/wallet-context';
import { BlockchainListItemFragment } from '../../../generated/graphql';
import ChainListItem from './chain-list-item';

const sortChains = (
  blockchains: BlockchainListItemFragment[],
  accounts: any,
  enabledChains: string[]
): BlockchainListItemFragment[] => {
  if (!Object.keys(accounts).length) {
    return blockchains;
  }

  return blockchains.slice().sort((bca, bcb) => {
    if (accounts[bcb.chainName] && accounts[bca.chainName]) {
      const bcbAmount =
        (+accounts[bcb.chainName]?.stakedBalance?.amount || 0) * (accounts[bcb.chainName]?.assets[0].priceInUSD || 0);
      const bcaAmount =
        (+accounts[bca.chainName]?.stakedBalance?.amount || 0) * (accounts[bca.chainName]?.assets[0].priceInUSD || 0);
      return bcbAmount - bcaAmount;
    }

    const bcaEnabled = enabledChains.indexOf(bca.chainName) !== -1 ? 0.000000000001 : 0;
    const bcbEnabled = enabledChains.indexOf(bcb.chainName) !== -1 ? 0.000000000001 : 0;

    if (bcaEnabled === bcbEnabled) {
      return bca.chainName.localeCompare(bcb.chainName);
    }

    return bcaEnabled ? -1 : 1;
  });
};

const ChainList = ({ onClose }: { onClose?: () => void }) => {
  const {
    accounts,
    enabledChains,
    blockchainsQuery: { data: blockchains, loading: blockchainLoading },
  } = useWalletContext();
  const [searchText, setSearchText] = useState('');

  const blockchainList = useMemo(() => {
    if (blockchains && blockchains.blockchains) {
      if (!searchText) {
        return sortChains(blockchains.blockchains, accounts, enabledChains);
      }

      return sortChains(blockchains.blockchains, accounts, enabledChains).filter((bc) => {
        return (
          bc.chainName.indexOf(searchText.toLowerCase()) !== -1 ||
          bc.prettyName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    return [];
  }, [accounts, blockchains, searchText]);

  if (!blockchainLoading && !blockchains) {
    return null;
  }

  return (
    <Box>
      <Divider />
      <TextField
        label="Search Chains"
        sx={{ margin: '8px 8px' }}
        size="small"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton sx={{ marginRight: -2 }}>
              <SearchOutlined />
            </IconButton>
          ),
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!blockchainLoading
          ? blockchainList.map((chain) => <ChainListItem key={chain.chainName} chain={chain} onClose={onClose} />)
          : Array.from(new Array(5)).map((_, index) => (
              <Skeleton
                key={index}
                animation="wave"
                sx={{ margin: '8px 8px', padding: '8px', borderRadius: '4px' }}
                variant="rectangular"
                height="60px"
              />
            ))}
      </Box>
    </Box>
  );
};

export default ChainList;
