import React from "react";
import { Box, CircularProgress } from "@mui/material";

function PreloaderRelative() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        border: "none",
        zIndex: 2147483647,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 30,
        borderRadius: 1,
        backgroundColor: "rgba(127,127,127,.1)",
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
}

export default PreloaderRelative;
