export const abbreviateNumberSeparated = (value: number, precision: number = 3): { value: string; suffix: string } => {
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T', 'q', 'Q', 's', 'S', 'o', 'n', 'd'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  const valueWithPrecision = newValue.toString().length > precision ? newValue.toFixed(2) : newValue.toFixed(1);
  return { value: valueWithPrecision, suffix: suffixes[suffixNum] };
};

const abbreviateNumber = (num: number): string => {
  const { value, suffix } = abbreviateNumberSeparated(num);
  return value + suffix;
};

export default abbreviateNumber;
