import { ProposalsListItemFragment } from '../generated/graphql';
import { FinalTallyResult, TallyResult } from '../types';

const finalTallyResultsToTallyResults = (proposal: ProposalsListItemFragment): TallyResult => {
  let tally = JSON.parse(proposal.finalTallyResult) as FinalTallyResult;
  if (tally.yes === '0' && tally.no === '0' && tally.abstain === '0' && tally.no_with_veto === '0') {
    tally = JSON.parse(proposal.currentTallyResult) as FinalTallyResult;
  }

  return {
    yes: tally.yes,
    no: tally.no,
    abstain: tally.abstain,
    noWithVeto: tally.no_with_veto,
  };
};

export default finalTallyResultsToTallyResults;
