import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {wallets as cosmostationWallets} from '@cosmos-kit/cosmostation';
import {wallets as keplrWallets} from '@cosmos-kit/keplr';
import {wallets as leapWallets} from '@cosmos-kit/leap';
import {wallets as ledgerWallets} from '@cosmos-kit/ledger';
import {ChainProvider} from '@cosmos-kit/react';
import '@interchain-ui/react/styles';
import {Box, responsiveFontSizes, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {assets, chains} from 'chain-registry';
import {SnackbarProvider} from 'notistack';
import * as React from 'react';
import ReactGA from 'react-ga4';
import {BrowserRouter} from 'react-router-dom';
import Footer from './common/Footer';
import Layout from './common/Layout';
import LeftPanel from './components/common/LeftPanel/left-panel';
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_COSMOS_KIT_LOG_LEVEL,
  REACT_APP_GAKEY,
  REACT_APP_WALLET_CONNECT_PROJECT_ID,
} from './config';
import {WalletContextProvider} from './context/wallet-context';
import createMyTheme from './myTheme';
import Routes from './routes';

if (REACT_APP_GAKEY) {
  ReactGA.initialize(REACT_APP_GAKEY);
}

function App() {
  const theme = responsiveFontSizes(createMyTheme(true));

  const client = new ApolloClient({
    uri: REACT_APP_BACKEND_URL + '/graphql',
    cache: new InMemoryCache(),
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <SnackbarProvider />
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ChainProvider
            chains={chains}
            assetLists={assets}
            wallets={[...cosmostationWallets, ...keplrWallets, ...ledgerWallets, ...leapWallets]}
            logLevel={REACT_APP_COSMOS_KIT_LOG_LEVEL || 'NONE'}
            throwErrors={false}
            subscribeConnectEvents={false}
            modalTheme={{
              overrides: {
                'connect-modal': {},
              },
            }}
            walletConnectOptions={{
              signClient: {
                projectId: REACT_APP_WALLET_CONNECT_PROJECT_ID,
                relayUrl: 'wss://relay.walletconnect.org',
                metadata: {
                  name: 'Voting Power',
                  description: 'Voting power provider',
                  url: 'https://votingpower.org/',
                  icons: ['https://votingpower.org/img/logo.png'],
                },
              },
            }}
          >
            <WalletContextProvider>
              <Box display="flex" flexDirection="column" sx={{ minHeight: '100vh' }}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <LeftPanel />
                  <Layout>
                    <Routes />
                  </Layout>
                  <Footer />
                </Box>
              </Box>
            </WalletContextProvider>
          </ChainProvider>
        </ApolloProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
