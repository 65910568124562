const percentFixed = function (number: number): number {
  const str = number.toString(10);
  let position = 0;
  const start = str.indexOf('.');

  if (number > 0.9) {
    return +number.toFixed(2);
  }

  if (number > 10) {
    return +number.toFixed(1);
  }

  if (number > 99) {
    return +number.toFixed(0);
  }

  for (let i = start + 1, l = str.length; i < l; i++) {
    if (str[i] !== '0') {
      position = i + 1 - start;
      break;
    }
  }
  return +number.toFixed(position);
};

export default percentFixed;
