import { Alert, AlertTitle, Backdrop, Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import colors from '../../constants/colors';
import { texts } from '../../constants/texts';
import { ProposalStatus } from '../../enum';

import { useProposalByIdQuery } from '../../generated/graphql';
import { coinToFloatWithName } from '../../utils/coinToFloat';
import { formatDate } from '../../utils/formatDate';
import ProposalContent from './proposal-content/proposal-content';
import VotesResult from './votes-result/votes-result';

interface OwnParams {
  proposalId: string;
}

export default function ProposalModule({ proposalId }: OwnParams) {
  const { data, loading } = useProposalByIdQuery({
    variables: { proposalId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.proposalById) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Proposal Info ${data?.proposalById.title}`,
      });
    }
  }, []);

  const proposalStatus = useMemo<number>(() => {
    if (!data?.proposalById.totalDeposit) {
      return ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED;
    }

    return data.proposalById.status;
  }, [data]);

  const totalDeposit = useMemo<string>(() => {
    if (!data?.proposalById.totalDeposit) {
      return '0';
    }

    const pd: { denom: string; amount: string }[] = JSON.parse(data.proposalById.totalDeposit);

    return pd[0]?.amount
      ? coinToFloatWithName(
          parseInt(pd[0].amount),
          data.proposalById.blockchain.assets[0].denom,
          data.proposalById.blockchain.assets[0].symbol
        )
      : '0' + data.proposalById.blockchain.assets[0].symbol;
  }, [data]);

  if (!data || loading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const proposal = data?.proposalById;

  return (
    <Box style={{ padding: '1rem 0' }}>
      <Typography variant="h6">
        [{proposal.blockchain.prettyName}] - proposal #{proposal.chainProposalId}
      </Typography>
      {proposal.isScam && (
        <Box mt={1}>
          <Alert severity="error" variant="filled">
            <AlertTitle>Possible Scam</AlertTitle>
            We have identified content that may be harmful in this proposal. <br />
            We advise you not to click on any links and to remain cautious.
          </Alert>
        </Box>
      )}
      <Box mt={2}>
        <Typography variant="h4">{proposal.title}</Typography>
      </Box>
      <Box mt={3}>
        <Box display="flex" justifyContent="flex-start" alignItems="center" mb={1} mt={2}>
          <Typography
            mr={2}
            variant="body1"
            textAlign="justify"
            component="div"
            sx={{
              border: `1px solid ${colors.statuses[proposalStatus as ProposalStatus]}`,
              borderRadius: '10px',
              padding: '0 10px',
              color: colors.statuses[proposalStatus as ProposalStatus],
            }}
          >
            {texts.statuses[proposalStatus as ProposalStatus]}
          </Typography>
          {proposalStatus === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD && (
            <Typography>{`Ends ${moment(proposal.votingEndTime).fromNow()}`}</Typography>
          )}
          {proposalStatus === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD && (
            <Typography>{`Ends ${moment(proposal.depositEndTime).fromNow()}`}</Typography>
          )}
        </Box>
      </Box>
      {proposalStatus !== ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD && (
        <Box mt={3}>
          <VotesResult proposal={proposal} />
        </Box>
      )}
      {proposalStatus === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD && (
        <Paper>
          <Box p={2} mt={3}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="subtitle2">Deposit End Time</Typography>
                <Typography variant="subtitle1">{formatDate(proposal.depositEndTime)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Total Deposited</Typography>
                <Typography variant="subtitle1">{totalDeposit}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      {proposalStatus !== ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD && (
        <Paper>
          <Box p={2} mt={3}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="subtitle2">Submit Time</Typography>
                <Typography variant="subtitle1">{formatDate(proposal.submitTime)}</Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2">Voting Start Time</Typography>
                <Typography variant="subtitle1">{formatDate(proposal.votingStartTime)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Voting End Time</Typography>
                <Typography variant="subtitle1">{formatDate(proposal.votingEndTime)}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      <Box mt={3}>
        <ProposalContent proposal={proposal} />
      </Box>
    </Box>
  );
}
