import abbreviateNumber from './abbreviateNumber';
import formatNumber from './formatNumber';

export const coinToFloat = (num: number | string, exponent: number): number => {
  return Number(num) / 10 ** exponent;
};

export const coinToFloatWithName = (
  num: number | string,
  exponent: number,
  coinName: string,
  fixed: number = 6,
  showDenom: boolean = true,
  short: boolean = true
): string => {
  let coin: number;
  if (typeof num === 'string') {
    coin = parseInt(num);
  } else {
    coin = num;
  }

  let value = coinToFloat(coin, exponent);

  return (
    (showDenom && coinName === '$' ? '$' : '') +
    (short ? abbreviateNumber(value) : +formatNumber(value, fixed)) +
    (showDenom && coinName !== '$' ? ' ' + coinName : '')
  );
};
