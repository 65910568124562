import { Coin } from '@cosmjs/stargate';
import { Box, Grid, Skeleton, styled, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useBlockchainByIdWithParamsQuery } from '../../../generated/graphql';
import { coinToFloatWithName } from '../../../utils/coinToFloat';
import percentFixed from '../../../utils/percent-fixed';
import TabPanel from '../../common/tab-panel';
import BlockchainParam from './blockchain-param';
import BlockchainParamsList from './blockchain-params-list';
import BlockchainValidatorsList from './blockchain-validators-list';

interface OwnProps {
  blockchainId: string;
}

const BlockchainInfo: FC<OwnProps> = ({ blockchainId }) => {
  const { data: blockchainData } = useBlockchainByIdWithParamsQuery({ variables: { blockchainId } });
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChangeTab = (event: React.SyntheticEvent | null, newValue: number): void => {
    setTabIndex(newValue);
  };

  const blockchain = useMemo(() => blockchainData?.blockchainById, [blockchainData]);

  useEffect(() => {
    if (blockchainData?.blockchainById) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Blockchain Info ${blockchainData.blockchainById.chainName}`,
      });
    }
  }, [blockchainData]);

  const explorers = useMemo(() => {
    if (blockchain?.explorers) {
      const explorers: { [p: string]: string } = {};
      JSON.parse(blockchain.explorers).forEach((e: { kind: string; url: string }) => {
        explorers[e.kind] = e.url;
      });
      return explorers;
    }
    return null;
  }, [blockchain]);

  const params = useMemo(() => (blockchain?.params ? JSON.parse(blockchain.params) : null), [blockchain]);
  const stakingParams = useMemo(
    () => (blockchain?.stakingParams ? JSON.parse(blockchain.stakingParams) : null),
    [blockchain]
  );

  const slashingParams = useMemo(
    () => (blockchain?.slashingParams ? JSON.parse(blockchain.slashingParams) : null),
    [blockchain]
  );

  const distributionParams = useMemo(
    () => (blockchain?.distributionParams ? JSON.parse(blockchain.distributionParams) : null),
    [blockchain]
  );

  const mintParams = useMemo(() => (blockchain?.mintParams ? JSON.parse(blockchain.mintParams) : null), [blockchain]);
  const govParams = useMemo(() => {
    if (blockchain?.govParams) {
      const parsed = JSON.parse(blockchain.govParams);
      return {
        ...parsed,
        min_deposit: parsed.min_deposit
          .map((d: Coin) => coinToFloatWithName(d.amount, blockchain.assets[0].denom, blockchain.assets[0].symbol))
          .join(', '),
      };
    }
    return null;
  }, [blockchain]);

  return (
    <Wrapper>
      <Box m={2}>
        <Grid container direction="row">
          <Grid item xs={12}>
            {blockchain ? (
              <Box display="flex" flexDirection="row">
                <img height={48} src={blockchain.icon} alt={blockchain.prettyName} />
                <Typography variant="h3" component="h1" marginLeft={1}>
                  {blockchain.prettyName}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row">
                <Skeleton animation="wave" variant="circular" width="48px" height="48px" />
                <Typography variant="h3" component="h1" marginLeft={1}>
                  <Skeleton animation="wave" width="160px" />
                </Typography>
              </Box>
            )}
          </Grid>
          <Typography variant="body2">{blockchain?.networkType}</Typography>
          <Grid container item xs={12} mt={2}>
            <Grid container item xs={12}>
              <BlockchainParam
                name="Price"
                value={
                  !blockchain
                    ? undefined
                    : blockchain.assets[0].priceInUSD > 1
                    ? coinToFloatWithName(blockchain.assets[0].priceInUSD, 0, '$')
                    : '$' + percentFixed(blockchain.assets[0].priceInUSD)
                }
              />
              <BlockchainParam
                name="Staked"
                value={
                  blockchain
                    ? coinToFloatWithName(blockchain.TVL, blockchain.assets[0].denom, blockchain.assets[0].symbol)
                    : undefined
                }
              />
              <BlockchainParam
                name="% Staked"
                value={blockchain ? percentFixed(blockchain.TVLPercent) + '%' : undefined}
              />
              <BlockchainParam
                name="USD Staked"
                value={blockchain ? coinToFloatWithName(blockchain.TVLinUSD, 0, '$') : undefined}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab label="Validators" />
                <Tab label="Parameters" />
              </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
              <Box pt={3}>
                <BlockchainValidatorsList blockchainId={blockchainId} />
              </Box>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Grid item container xs={12}>
                {params && <BlockchainParamsList name="Main" params={params} />}
                {govParams && <BlockchainParamsList name="Governance" params={govParams} />}
                {mintParams && <BlockchainParamsList name="Mint" params={mintParams} />}
                {distributionParams && <BlockchainParamsList name="Distribution" params={distributionParams} />}
                {stakingParams && <BlockchainParamsList name="Staking" params={stakingParams} />}
                {slashingParams && <BlockchainParamsList name="Slashing" params={slashingParams} />}
                {explorers && <BlockchainParamsList name="Explorers" params={explorers} />}
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default BlockchainInfo;

const Wrapper = styled(Box)({});
