import { Box, Modal, styled, Tab, Tabs } from '@mui/material';
import { Coin } from 'osmojs/types/codegen/cosmos/base/v1beta1/coin';
import React, { FC } from 'react';
import { useWalletContext } from '../../context/wallet-context';
import { BlockchainListItemFragment, ValidatorItemFragment } from '../../generated/graphql';
import TabPanel from '../common/tab-panel';
import DelegateForm from './forms/delegate-form';
import RedelegationForm from './forms/redelegate-form';
import UndelegateForm from './forms/undelegate-form';

interface OwnProps {
  isOpened: boolean;
  onClose: () => void;
  validator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DelegateModal: FC<OwnProps> = ({ blockchain, validator, isOpened, onClose }) => {
  const [value, setValue] = React.useState(0);
  const { myDelegations, actions, accounts } = useWalletContext();

  const handleChange = (event: React.SyntheticEvent | null, newValue: number): void => {
    setValue(newValue);
  };

  if (isOpened && !accounts[blockchain.chainName]?.balance) {
    onClose();
    return null;
  }

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Wrapper sx={{ boxShadow: 24 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Delegate" {...a11yProps(0)} />
            <Tab label="Redelegate" {...a11yProps(1)} />
            <Tab label="Undelegate" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ p: 3 }}>
            <DelegateForm
              defaultValidator={validator}
              blockchain={blockchain}
              onClose={onClose}
              myDelegations={myDelegations}
              balance={accounts[blockchain.chainName]!.balance as Coin}
              actions={actions}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ p: 3 }}>
            <RedelegationForm
              defaultValidator={validator}
              blockchain={blockchain}
              onClose={onClose}
              myDelegations={myDelegations}
              actions={actions}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ p: 3 }}>
            <UndelegateForm
              defaultValidator={validator}
              blockchain={blockchain}
              onClose={onClose}
              myDelegations={myDelegations}
              actions={actions}
              switchTab={handleChange}
            />
          </Box>
        </TabPanel>
      </Wrapper>
    </Modal>
  );
};

export default DelegateModal;

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  backgroundColor: theme.palette.background.paper,
}));
