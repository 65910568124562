import Menu from '@mui/material/Menu';
import React, { FC, useEffect } from 'react';
import { useWalletContext } from '../../../context/wallet-context';
import { ChainFilterType, ProposalStatus } from '../../../enum';
import { VotingFilterState } from '../../../types';
import VotingFilters from './voting-filters';

interface OwnProps {
  filter: VotingFilterState;
  onChange: (update: Partial<VotingFilterState>) => void;
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

const VotingFiltersMenu: FC<OwnProps> = ({ filter, onChange, anchorEl, onClose }) => {
  const {
    blockchainsQuery: { data: blockchains },
  } = useWalletContext();
  const isOpened = Boolean(anchorEl);

  useEffect(() => {
    if (blockchains && !filter.loaded) {
      const storedFilter = localStorage.getItem('voting-filter');
      if (storedFilter) {
        const storedFilterParsed = JSON.parse(storedFilter);
        if (storedFilterParsed.hasOwnProperty('chainsType')) {
          const newSelected = blockchains.blockchains
            .filter((c) => storedFilterParsed.selectedChains.indexOf(c.id) !== -1)
            .map((c) => c.id);
          onChange({
            isActual: storedFilterParsed.isActual,
            selectedChains: newSelected,
            statuses: storedFilterParsed.statuses,
            loaded: true,
          });
          return;
        }
      }

      const filter: Partial<VotingFilterState> = {
        chainsType: ChainFilterType.CONNECTED,
        isActual: false,
        selectedChains: [],
        statuses: [ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD],
        loaded: true,
      };
      onChange(filter);
      localStorage.setItem('voting-filter', JSON.stringify(filter));
    }
  }, [blockchains, filter, onChange]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpened}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <VotingFilters filter={filter} onChange={onChange} />
    </Menu>
  );
};

export default VotingFiltersMenu;
