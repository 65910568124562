import React from 'react';
import { useParams } from 'react-router-dom';
import ValidatorGroupModule from '../components/validator-group/validator-group-module';

function ValidatorGroupPage() {
  let { validatorGroupId } = useParams();

  if (!validatorGroupId) {
    return null;
  }

  return <ValidatorGroupModule validatorGroupId={validatorGroupId} />;
}

export default ValidatorGroupPage;
