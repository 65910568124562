import React from 'react';
import { useParams } from 'react-router-dom';
import ProposalModule from '../components/proposal/proposal-module';

function ProposalPage() {
  let { proposalId } = useParams();
  if (!proposalId) {
    throw Error(`Can't find proposal without ID`);
  }
  return <ProposalModule proposalId={proposalId} />;
}

export default ProposalPage;
