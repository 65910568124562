import { Box, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useValidatorGroupByIdQuery } from '../../generated/graphql';
import ValidatorGroupHeader from './validator-group-header';

interface OwnProps {
  validatorGroupId: string;
}

const ValidatorGroupModule: FC<OwnProps> = ({ validatorGroupId }) => {
  const { data: validatorGroup } = useValidatorGroupByIdQuery({
    variables: { validatorGroupId },
  });

  useEffect(() => {
    if (validatorGroup?.validatorGroupById) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Validator Group Info ${validatorGroup?.validatorGroupById.username}`,
      });
    }
  }, [validatorGroup]);

  return (
    <Wrapper>
      <ValidatorGroupHeader validatorGroup={validatorGroup?.validatorGroupById} />
    </Wrapper>
  );
};

export default ValidatorGroupModule;

const Wrapper = styled(Box)({});
