import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HubIcon from '@mui/icons-material/Hub';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ConnectButton from '../connect-button';
import Header from '../Header/header';
import ChainList from './chain-list';

const drawerWidth = 240;

interface OwnProps {
  window?: () => Window;
}

interface PageListItem {
  name: string;
  href: string;
  icon: ReactElement;
}

const pages: PageListItem[] = [
  { name: 'Proposals', icon: <BusinessCenterIcon />, href: '/proposals' },
  { name: 'Top validators', icon: <AdminPanelSettingsIcon />, href: '/top-validators' },
  { name: 'Blockchains', icon: <HubIcon />, href: '/blockchains' },
  { name: 'About', icon: <InfoIcon />, href: '/about' },
];

const LeftPanel: FC<OwnProps> = ({ window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Box
        ml={1.5}
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          height: 50,
        }}
      >
        <Link to={'/'}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: '200px' }}>
            <img alt="logo" src="/img/logo-full.svg" height="40" />
            <Typography variant="caption" sx={{ color: '#fff' }}>
              by
            </Typography>
            <img alt="POSTHUMAN" src="/img/phmn.png" height="32" style={{ marginBottom: '4px' }} />
          </Box>
        </Link>
      </Box>
      <Divider />
      <Box m={1} onClick={() => setMobileOpen(false)}>
        <ConnectButton />
      </Box>
      <List onClick={() => setMobileOpen(false)}>
        {pages.map(({ name, icon, href }) => (
          <ListItemButton key={href} component={Link} to={href}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Header handleDrawerToggle={() => setMobileOpen(true)} />
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 }, p: 1 }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
          <ChainList onClose={() => setMobileOpen(false)} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
          <ChainList />
        </Drawer>
        <Divider />
      </Box>
    </>
  );
};

export default LeftPanel;
