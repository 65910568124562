import { Box, Skeleton, styled } from '@mui/material';
import React, { FC } from 'react';
import { useWalletContext } from '../../context/wallet-context';

import { ValidatorGroupItemFragment } from '../../generated/graphql';
import ValidatorGroupListItem from '../validator-group-list/validator-group-list-item';
import ValidatorItem from './validator-item';

interface OwnProps {
  validatorGroup: ValidatorGroupItemFragment | undefined;
}

const ValidatorGroupHeader: FC<OwnProps> = ({ validatorGroup }) => {
  const { blockchainsQuery: {data: blockchains} } = useWalletContext();

  return (
    <Wrapper>
      <ValidatorGroupListItem
        validatorGroup={validatorGroup}
        blockchains={blockchains}
        showValidators={false}
        index={validatorGroup?.topPlace || 0}
      />
      <Box>
        {validatorGroup
          ? validatorGroup.validators.map((validator) => {
              const blockchain = blockchains?.blockchains.find((b) => b.id === validator.blockchainId);
              if (!blockchain) {
                return null;
              }

              return <ValidatorItem key={validator.id} validator={validator} blockchain={blockchain} />;
            })
          : Array.from(new Array(5)).map((_, index) => (
              <Skeleton
                key={index}
                sx={{ marginTop: '24px', width: '100%', height: '20vw' }}
                animation="wave"
                variant="rectangular"
              />
            ))}
      </Box>
    </Wrapper>
  );
};

export default ValidatorGroupHeader;

const Wrapper = styled(Box)({});
