import { Box, Skeleton, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  useBlockchainByIdQuery,
  useValidatorsByBlockchainIdQuery,
  ValidatorItemFragment,
} from '../../../generated/graphql';
import BlockchainValidatorItem from './blockchain-validator-item';
import BlockchainValidatorsListFilter from './blockchain-validators-list-filter';

interface OwnProps {
  blockchainId: string;
}

const BlockchainValidatorsList: FC<OwnProps> = ({ blockchainId }) => {
  const { data: blockchainData } = useBlockchainByIdQuery({ variables: { blockchainId } });
  const [isActive, setIsActive] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [validatorList, setValidatorList] = useState<ValidatorItemFragment[]>([]);

  const { data: validatorsData, refetch: refetchValidators } = useValidatorsByBlockchainIdQuery({
    variables: { blockchainId: blockchainId, isActive },
  });

  const blockchain = useMemo(() => blockchainData?.blockchainById, [blockchainData]);

  useEffect(() => {
    if (validatorsData?.validatorsByBlockchainId) {
      if (searchText) {
        setValidatorList(
          validatorsData.validatorsByBlockchainId.filter(
            (v) =>
              v.moniker.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
              v.rank.toString().indexOf(searchText) !== -1
          )
        );
      } else {
        setValidatorList(validatorsData.validatorsByBlockchainId);
      }
    }
  }, [searchText, validatorsData]);

  useEffect(() => {
    const refetch = async () => {
      setIsRefetching(true);
      await refetchValidators({ blockchainId: blockchainId, isActive });
      setIsRefetching(false);
    };

    refetch();
  }, [isActive]);

  const handleFilterChange = (text: string, active: boolean) => {
    text !== searchText && setSearchText(text);
    active !== isActive && setIsActive(active);
  };

  return (
    <Box>
      <BlockchainValidatorsListFilter onChange={handleFilterChange} />
      {validatorsData?.validatorsByBlockchainId.length && !validatorList.length && searchText ? (
        <Typography sx={{ color: (theme) => theme.palette.text.disabled }} textAlign="center">
          No results were found for selected filters...
        </Typography>
      ) : (
        <Box>
          {validatorList.length && blockchain && !isRefetching
            ? validatorList.map((validator: ValidatorItemFragment) => (
                <BlockchainValidatorItem key={validator.id} validator={validator} blockchain={blockchain} />
              ))
            : Array.from(new Array(15)).map((_, index) => (
                <Skeleton
                  key={index}
                  component="div"
                  sx={{ transform: 'unset', marginBottom: '8px' }}
                  animation="wave"
                  height="174px"
                  width="100%"
                />
              ))}
        </Box>
      )}
    </Box>
  );
};

export default BlockchainValidatorsList;
