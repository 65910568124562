import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Button, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { FormikProps, withFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Actions, useWalletContext } from '../../../context/wallet-context';
import {
  BlockchainListItemFragment,
  useValidatorsByBlockchainIdQuery,
  ValidatorItemFragment,
} from '../../../generated/graphql';
import { MyDelegations } from '../../../types';
import { coinToFloat, coinToFloatWithName } from '../../../utils/coinToFloat';
import SelectValidator from '../../common/select-validator/select-validator';
import ValidatorAvatar from '../validator-avatar';

interface FormValues {
  amount: string;
  memo: string;
  gas: number;
  operatorAddress: string;
}

interface OwnProps {
  defaultValidator: ValidatorItemFragment;
  blockchain: BlockchainListItemFragment;
  myDelegations: MyDelegations;
  actions: Actions;
  onClose: () => void;
  switchTab: (event: React.SyntheticEvent | null, newValue: number) => void;
}

const createValidationSchema = (blockchain: BlockchainListItemFragment, myDelegations: MyDelegations) => {
  return Yup.object().shape({
    amount: Yup.number()
      .typeError('Amount must be a number.')
      .required('Amount is required.')
      .min(10 ** -blockchain.assets[0].denom, "You can't delegate so little.")
      .test('amount', 'Insufficient staked balance.', (value, context) => {
        const delegation = myDelegations[context.parent.operatorAddress] || {};
        const delegated = +(delegation?.delegations?.amount || 0) * 10 ** -blockchain.assets[0].denom;
        return value <= delegated;
      }),
    memo: Yup.string(),
  });
};

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm: FC<OwnProps & FormikProps<FormValues>> = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  defaultValidator,
  blockchain,
  myDelegations,
  actions,
  switchTab,
}) => {
  const { data: validators } = useValidatorsByBlockchainIdQuery({
    variables: { blockchainId: blockchain.id, isActive: true },
  });

  const { accounts } = useWalletContext();

  const [isAdditionalOpened, setIsAdditionalOpened] = useState<boolean>(false);
  const [isSelectValidatorToOpened, setIsSelectValidatorToOpened] = useState<boolean>(false);
  const [validator, setValidator] = useState<ValidatorItemFragment>(defaultValidator);
  const delegation = myDelegations[values.operatorAddress] || {};

  useEffect(() => {
    if (validators) {
      const val: ValidatorItemFragment =
        (validator.id && validators.validatorsByBlockchainId.find((v) => v.id === validator.id)) || defaultValidator;

      setFieldValue('operatorAddress', val.operatorAddress);
      setValidator(val);
    }
  }, [validators, validator.id]);

  useEffect(() => {
    const getGas = async () => {
      let gasUsed = 0;
      const coin = accounts[blockchain.chainName]?.balance || null;
      if (coin) {
        gasUsed = (await actions.simulateDelegation(blockchain.chainName, coin, defaultValidator.operatorAddress)) || 0;
      }
      await setFieldValue('gas', gasUsed);
    };
    getGas();
  }, []);

  const undelegateAll = async () => {
    if (!delegation?.delegations) {
      return;
    }

    await setFieldValue('amount', coinToFloat(+delegation.delegations.amount, blockchain.assets[0].denom));
  };

  const onSelectValidatorTo = (selected?: ValidatorItemFragment) => {
    setIsSelectValidatorToOpened(false);
    if (selected) {
      setValidator(selected);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SelectValidator
        isOpened={isSelectValidatorToOpened}
        onClose={onSelectValidatorTo}
        blockchain={blockchain}
        selected={validator.id || defaultValidator.id}
      />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)}>
          from
        </Typography>
        <ValidatorAvatar
          img={validator.icon}
          username={validator.moniker}
          sx={{ width: '24px', height: '24px', marginLeft: 1, marginRight: 1 }}
        />
        <Typography
          flexGrow={1}
          variant="h6"
          onClick={() => setIsSelectValidatorToOpened(true)}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '400px',
            overflow: 'hidden',
            marginRight: 2,
          }}
        >
          {validator.moniker}
        </Typography>
        <Typography variant="h6" onClick={() => setIsSelectValidatorToOpened(true)}>
          {validator.commissionSimple}%
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={() => setIsSelectValidatorToOpened(true)}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Box mt={3} mb={3}>
        <TextField
          fullWidth
          error={touched.amount && !!errors.amount}
          helperText={touched.amount ? errors.amount : ''}
          placeholder="&infin;"
          label="Amount"
          InputProps={{
            endAdornment: <Typography>{blockchain.assets[0].symbol}</Typography>,
          }}
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
        />
        <Box display="flex" justifyContent="flex-end">
          <Link sx={{ color: 'action.disabled', textDecoration: 'none', cursor: 'pointer' }} onClick={undelegateAll}>
            Staked:{' '}
            {delegation.delegations
              ? coinToFloatWithName(
                  delegation.delegations.amount,
                  blockchain.assets[0].denom,
                  blockchain.assets[0].symbol
                )
              : 0}
          </Link>
        </Box>
        <Link mt={1} alignItems="center" onClick={() => setIsAdditionalOpened(!isAdditionalOpened)}>
          Additional
        </Link>
        <Box display={isAdditionalOpened ? 'block' : 'none'} mt={1}>
          <TextField
            fullWidth
            error={touched.memo && !!errors.memo}
            helperText={touched.memo ? errors.memo : ''}
            multiline={true}
            label="Memo"
            placeholder="Memo"
            name="memo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.memo}
            InputProps={{
              endAdornment: (
                <Tooltip title="A note or comment to send with the transaction.">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              ),
            }}
          />
        </Box>
      </Box>
      <Alert severity="warning">
        Asset will be liquid after unbonding period. If you want to switch validator, use{' '}
        <Link onClick={() => switchTab(null, 1)}>Redelegate</Link> feature. You can instantly stake your assets to
        another validator.
      </Alert>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button variant="contained" type="submit" disabled={isSubmitting}>
          Undelegate
        </Button>
      </Box>
    </form>
  );
};

// Wrap our form with the withFormik HoC
const UnDelegationForm = withFormik<OwnProps, FormValues>({
  validationSchema: (props: OwnProps) => createValidationSchema(props.blockchain, props.myDelegations),
  mapPropsToValues: (props) => {
    return {
      operatorAddress: props.defaultValidator.operatorAddress,
      amount: '',
      memo: 'votingpower.org',
      gas: 0,
    };
  },

  handleSubmit: async (values, { props: { blockchain, actions, onClose }, resetForm }) => {
    const success = await actions.undelegate(
      blockchain.chainName,
      { amount: (+values.amount * 10 ** blockchain.assets[0].denom).toString(10), denom: blockchain.assets[0].base },
      values.operatorAddress,
      values.gas,
      values.memo
    );
    if (success) {
      resetForm();
      onClose();
      await actions.reloadAccount(blockchain.chainName);
    }
  },
})(InnerForm);

export default UnDelegationForm;
