import { Box, Paper, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { getOverrides, MuiMarkdown } from 'mui-markdown';
import React, { FC } from 'react';
import { ProposalsListItemFragment } from '../../../generated/graphql';
import UnknownProposalTypeContent from './unknown-proposal-type-content';

type OwnProps = {
  proposal: ProposalsListItemFragment;
};

const CustomTypography =
  (variant: Variant) =>
  ({ children }: any) => {
    return (
      <Typography mb={variant === 'body2' ? 3 : 1} variant={variant}>
        {children}
      </Typography>
    );
  };

const ProposalContent: FC<OwnProps> = ({ proposal }) => {
  const proposalContent = JSON.parse(proposal.content);

  const description = proposal.description
    .replace(/\\r\\n/g, '\n')
    .replace(/(\S)\\n/g, '$1\n')
    .replace(/\\n(\S)/g, '\n$1');

  return (
    <Box>
      <Typography variant="h6">Description</Typography>
      <Paper sx={{ padding: 2, overflow: 'auto' }}>
        <MuiMarkdown
          overrides={{
            ...getOverrides({}), // This will keep the other default overrides.
            h1: {
              component: CustomTypography('h4'),
            },
            h2: {
              component: CustomTypography('h5'),
            },
            h3: {
              component: CustomTypography('h6'),
            },
            h4: {
              component: CustomTypography('body1'),
            },
            p: {
              component: CustomTypography('body2'),
            },
          }}
        >
          {description}
        </MuiMarkdown>
      </Paper>
      <Box mt={3}>
        <UnknownProposalTypeContent proposalContent={proposalContent} />
      </Box>
    </Box>
  );
};

export default ProposalContent;
