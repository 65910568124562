import { ProposalStatus, Tally, VoteOption } from "../enum";
import { BondStatus } from "cosmjs-types/cosmos/staking/v1beta1/staking";

export const votingKeysArr = [
  Tally["1"],
  Tally["3"],
  Tally["4"],
  Tally["2"],
] as const;

export const votingKeysSorted = [
  VoteOption.VOTE_OPTION_YES,
  VoteOption.VOTE_OPTION_NO,
  VoteOption.VOTE_OPTION_NO_WITH_VETO,
  VoteOption.VOTE_OPTION_ABSTAIN,
] as const;

export type VotingKeys = typeof votingKeysArr[number];

const statuses: {[key in ProposalStatus]: string} = {
  [ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD]: "#1787F3",
  [ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD]: "#A395FC",
  [ProposalStatus.PROPOSAL_STATUS_FAILED]: "#FD5C70",
  [ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED]: "#556296",
  [ProposalStatus.PROPOSAL_STATUS_REJECTED]: "#CC4458",
  [ProposalStatus.PROPOSAL_STATUS_PASSED]: "#24A474",
  [ProposalStatus.UNRECOGNIZED]: "#556296",
};

const votes: {[key in VoteOption]: string} = {
  [VoteOption.VOTE_OPTION_UNSPECIFIED]: "#CCCCCC",
  [VoteOption.VOTE_OPTION_YES]: "#24A474",
  [VoteOption.VOTE_OPTION_NO]: "#CC4458",
  [VoteOption.VOTE_OPTION_NO_WITH_VETO]: "#FD5C70",
  [VoteOption.VOTE_OPTION_ABSTAIN]: "#556296",
};

const validatorStatus: {[key in BondStatus]: string} = {
  [BondStatus.UNRECOGNIZED]: "#556296",
  [BondStatus.BOND_STATUS_BONDED]: "#24A474",
  [BondStatus.BOND_STATUS_UNSPECIFIED]: "#556296",
  [BondStatus.BOND_STATUS_UNBONDING]: "#556296",
  [BondStatus.BOND_STATUS_UNBONDED]: "#556296",
}

const colors = {
  statuses,
  validatorStatus,
  votes,
};

export default colors;
