import { Box, Typography } from '@mui/material';
import moment from 'moment';

const drawerWidth = 240;

export default function Footer() {
  return (
    <footer>
      <Box
        paddingX={8}
        display="flex"
        alignItems="center"
        sx={{
          height: 50,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'action.hover',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Typography variant="body1">&copy; Copyright Voting Power {moment().format('YYYY')}</Typography>
      </Box>
    </footer>
  );
}
