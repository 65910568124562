import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

export default function createMyTheme(isDarkTheme: boolean) {
  const neutral = isDarkTheme ? grey[500] : grey[600];

  return createTheme({
    // @ts-ignore
    overrides: {
      MuiCssBaseline: {
        '@global': {
          'blockquote p': {
            padding: '0!important',
            margin: '0!important',
          },
          'MuiLink-root': {
            cursor: 'pointer',
          },
          a: {
            color: 'inherit',
            '&:hover': {
              textDecoration: 'none',
            },
          },
          blockquote: {
            borderLeft: '0.3em solid',
            borderLeftColor: neutral,
            whiteSpace: 'pre',
            fontStyle: 'oblique',

            margin: '1.5em 0',
            padding: '0 10px',
          },
        },
      },
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: isDarkTheme ? '#181F37' : undefined,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '1em',
            color: 'white',
            backgroundColor: 'rgba(24,31,55,0.9)',
            padding: '10px 20px',
            border: '1px solid #556296',
            maxWidth: 'none',
          },
        },
      },
    },
    props: {
      MuiTypography: {
        variantMapping: {
          h4: 'h1',
          h5: 'h2',
          h6: 'h3',
        },
      },
    },
    palette: {
      mode: isDarkTheme ? 'dark' : 'light',
      primary: { main: '#66A1FF' },
      secondary: { main: '#FFC257' },
      background: {
        default: isDarkTheme ? '#13182C' : '#fff',
        paper: isDarkTheme ? '#181F37' : grey[100],
      },
    },
  });
}
