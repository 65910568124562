import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, styled } from '@mui/material';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import React, { FC, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import MiddleEllipsis from '../../utils/middle-ellipsis';
import SnackbarAction from './snackbar-action';

interface OwnProps {
  text: string;
}

const AddressText: FC<OwnProps> = ({ text }) => {
  const onAddressCopy = useCallback(() => {
    enqueueSnackbar('Address copied to clipboard!', {
      variant: 'success',
      action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
    });
  }, []);
  return (
    <Wrapper>
      <Box sx={{ maxWidth: 'calc(100% - 20px)', display: 'inline-block' }}>
        <MiddleEllipsis>
          <span className="ellipseMe">{text}</span>
        </MiddleEllipsis>
      </Box>
      <Box sx={{ display: 'inline-block', marginLeft: '8px' }}>
        <CopyToClipboard text={text} onCopy={onAddressCopy}>
          <ContentCopyIcon fontSize="small" />
        </CopyToClipboard>
      </Box>
    </Wrapper>
  );
};

export default AddressText;

const Wrapper = styled(Box)({
  position: 'relative',
});
