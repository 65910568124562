import { Box, Grid, Link, Paper, Skeleton, styled, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import formatNumber from '../../../utils/formatNumber';

interface OwnProps {
  name: string;
  value?: string | number | boolean;
}

const BlockchainParam: FC<OwnProps> = ({ name, value }) => {
  const renderedValue = useMemo(() => {
    const isLink = value && typeof value === 'string' && value.indexOf('http') === 0;
    if (typeof value === 'boolean') {
      return <Typography variant="body1">{value ? 'Yes' : 'No'}</Typography>;
    }

    if (!value) {
      return <Skeleton animation="wave" width="40px" />;
    }

    if (typeof value === 'number') {
      return <Typography variant="body1">{formatNumber(+value, 0)}</Typography>;
    }

    if (isLink) {
      return (
        <Typography
          component={Link}
          variant="body1"
          href={value ? value.toString() : ''}
          rel="nofollow"
          target="_blank"
          sx={{ display: 'block' }}
          noWrap
        >
          {value ? value : <Skeleton animation="wave" width="40px" />}
        </Typography>
      );
    }

    return <Typography variant="body1">{value}</Typography>;
  }, [value]);

  const displayName = useMemo(
    () => name.replace(/^([a-z])|_([a-z])/g, (g) => ' ' + (g[1] || g[0]).toUpperCase()),
    [name]
  );

  return (
    <Grid item xs={6} md={4}>
      <Wrapper>
        <Paper sx={{ padding: '8px', overflow: 'hidden' }}>
          <Typography variant="caption" noWrap textOverflow="ellipsis" sx={{ display: 'block' }}>
            {displayName}
          </Typography>
          {renderedValue}
        </Paper>
      </Wrapper>
    </Grid>
  );
};

export default BlockchainParam;

const Wrapper = styled(Box)({
  margin: '4px',
});
