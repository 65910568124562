import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import JSONBox from "../../common/json-box/json-box";

type OwnType = {
  proposalContent: any;
};

const filteredParams = "|description|title|@type|";

const UnknownProposalContent: FC<OwnType> = ({ proposalContent }) => {
  const params = Object.keys(proposalContent)
    .filter((p) => filteredParams.indexOf(`|${p}|`) === -1)
    .map((param: string) => {
      return (
        <TableRow key={param}>
          <TableCell sx={{ verticalAlign: "top", width: '1%' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {param}:
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: "top" }}>
            <JSONBox obj={proposalContent[param]} />
          </TableCell>
        </TableRow>
      );
    });

  if (!params.length) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6">Params:</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>{params}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UnknownProposalContent;
