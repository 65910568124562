import { Box, Button, Paper, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import colors from '../../../constants/colors';
import { useWalletContext } from '../../../context/wallet-context';
import { ProposalStatus, VoteOption } from '../../../enum';
import { Asset, ProposalsListItemFragment } from '../../../generated/graphql';
import { coinToFloatWithName } from '../../../utils/coinToFloat';
import finalTallyResultsToTallyResults from '../../../utils/finalTallyResultsToTallyResults';
import VotingMyVotes from '../../voting/voting-card/voting-my-votes';
import PieChart from './pie-chart';

type OwnType = {
  proposal: ProposalsListItemFragment;
};

type ResultValueBoxType = {
  value: number;
  total: BigInt;
  asset: Asset;
  color: string;
  title: string;
  disabled: boolean;
  onClick: () => void;
};

const ResultValueBox: FC<ResultValueBoxType> = ({ value, total, asset, title, color, disabled, onClick }) => {
  const percent = (+value * 100) / Number(total) || 0;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{
        backgroundColor: (theme) => (disabled ? theme.palette.background.default : color),
        borderColor: color,
        borderWidth: '2px',
        borderStyle: 'solid',
        margin: 2,
        borderRadius: '12px',
        padding: '8px 16px',
        flex: 1,
        color: '#fff!important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="h5">{title}</Typography>
      <Typography variant="h6">{(percent ? percent.toFixed(4) : 0) + '%'}</Typography>
      <Typography variant="body2">{coinToFloatWithName(value, asset.denom, asset.symbol, 2)}</Typography>
    </Button>
  );
};

const VotesResult: FC<OwnType> = ({ proposal }) => {
  const { actions, votes } = useWalletContext();

  const ftr = useMemo(() => finalTallyResultsToTallyResults(proposal), [proposal]);
  const total = useMemo(() => BigInt(ftr.yes) + BigInt(ftr.no) + BigInt(ftr.abstain) + BigInt(ftr.noWithVeto), [ftr]);

  const vote = (option: VoteOption) => {
    actions.vote(proposal.blockchain.chainName, proposal.chainProposalId, option);
  };

  const yourVote = useMemo(() => {
    if (!votes.data?.votes?.length) return null;
    return votes.data.votes.find((v) => v.proposalId === proposal.id);
  }, [votes]);

  return (
    <Paper>
      <Box pb={1} sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
        {total > BigInt(0) ? <PieChart proposal={proposal} tally={ftr} total={total} /> : <Box />}
        <Box sx={{ flex: 1, display: { xs: 'block', lg: 'flex' } }}>
          <Box sx={{ display: { xs: 'flex' }, flexGrow: 1 }}>
            <ResultValueBox
              disabled={proposal.status !== ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD}
              value={+ftr.yes || 0}
              asset={proposal.blockchain.assets[0]}
              total={total}
              color={colors.votes[VoteOption.VOTE_OPTION_YES]}
              title="YES"
              onClick={() => vote(VoteOption.VOTE_OPTION_YES)}
            />
            <ResultValueBox
              disabled={proposal.status !== ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD}
              value={+ftr.no || 0}
              asset={proposal.blockchain.assets[0]}
              total={total}
              color={colors.votes[VoteOption.VOTE_OPTION_NO]}
              title="NO"
              onClick={() => vote(VoteOption.VOTE_OPTION_NO)}
            />
          </Box>
          <Box sx={{ display: { xs: 'flex' }, flexGrow: 1 }}>
            <ResultValueBox
              disabled={proposal.status !== ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD}
              value={+ftr.noWithVeto || 0}
              asset={proposal.blockchain.assets[0]}
              total={total}
              color={colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO]}
              title="NWV"
              onClick={() => vote(VoteOption.VOTE_OPTION_NO_WITH_VETO)}
            />
            <ResultValueBox
              disabled={proposal.status !== ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD}
              value={+ftr.abstain || 0}
              asset={proposal.blockchain.assets[0]}
              total={total}
              color={colors.votes[VoteOption.VOTE_OPTION_ABSTAIN]}
              title="ABSTAIN"
              onClick={() => vote(VoteOption.VOTE_OPTION_ABSTAIN)}
            />
          </Box>
        </Box>
      </Box>
      {yourVote ? (
        <Box p={2}>
          <VotingMyVotes votes={[yourVote]} loaded={!votes.loading} />
        </Box>
      ) : null}
    </Paper>
  );
};

export default VotesResult;
