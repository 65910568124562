import { Box, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import colors from '../../../constants/colors';
import { texts } from '../../../constants/texts';
import { useWalletContext } from '../../../context/wallet-context';
import { ProposalStatus, VoteOption } from '../../../enum';
import { ProposalsListItemFragment } from '../../../generated/graphql';
import { formatDate } from '../../../utils/formatDate';
import separateCamelcase from '../../../utils/separateCamelcase';
import SnackbarAction from '../../common/snackbar-action';
import CustomTooltip from '../../common/Tooltip/Tooltip';
import DepositButton from './deposit-button';
import VotingButtons from './voting-buttons';
import VotingMyVotes from './voting-my-votes';

import VotingProgress from './voting-progress';

type ProposalContent = {
  '@type': string;
  title: string;
  description: string;
  [key: string]: any;
};

const tooltipKeys = ['submitTime', 'depositEndTime', 'votingStartTime', 'votingEndTime'] as const;

const tooltipKeysDeposit = ['submitTime', 'depositEndTime', 'votingStartTime', 'votingEndTime'] as const;

type TimeField = (typeof tooltipKeys)[number] | (typeof tooltipKeysDeposit)[number];

function VotingCard({ proposal }: { proposal: ProposalsListItemFragment }) {
  const { actions, enabledChains, votes } = useWalletContext();

  const votesList = votes.data?.votes || []

  const isDepositPeriod = proposal.status === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD;
  const isEnabledChain = enabledChains.indexOf(proposal.blockchain.chainName) !== -1

  const isVotingPeriod =
    new Date() > new Date(proposal.votingStartTime) &&
    new Date() < new Date(proposal.votingEndTime) &&
    proposal.status === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD;

  const timesToolTip = (isDepositPeriod ? tooltipKeysDeposit : tooltipKeys).map((key: TimeField) => ({
    name: separateCamelcase(key.charAt(0).toUpperCase() + key.slice(1)),
    value: formatDate(proposal[key]),
  }));

  const content = JSON.parse(proposal.content) as ProposalContent;

  const yourVotes = useMemo(
    () => votesList.filter((v) => v.proposalId === proposal.id),
    [votesList]
  );

  const onVoteClick = async (option: VoteOption) => {
    await actions.vote(proposal.blockchain.chainName, proposal.chainProposalId, option);
  };

  const onDepositClick = () => {
    enqueueSnackbar('This feature will be implemented soon.', {
      variant: 'info',
      action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
    });
  };

  const proposalType = useMemo(() => {
    const typeArr = proposal.type.split('.');
    return typeArr[typeArr.length - 1].replace('Proposal', '').replace(/([a-z])([A-Z])/g, '$1 $2');
  }, []);

  return (
    <Grid item key={proposal.id} xs>
      <Card
        sx={{
          minWidth: '300px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          position: 'relative',
        }}
      >
        {!isDepositPeriod && <VotingProgress proposal={proposal} />}
        <CardActionArea
          component={RouterLink}
          to={`/proposals/${proposal.id}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              backgroundImage: `url(${proposal.blockchain.icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'absolute',
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              height: '100%',
              width: '100%',
              opacity: 0.07,
            }}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
              position: 'relative',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: -2,
                marginRight: -1,
              }}
              color="text.secondary"
            >
              [{proposal.blockchain.prettyName}]
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                flexDirection: 'column',
                display: 'flex',
                flexGrow: 1,
              }}
            >
              #{proposal.chainProposalId} {content.title}
            </Typography>
            <Box display="flex" justifyContent="space-between" mb={1} mt={2}>
              <Typography
                variant="body1"
                textAlign="justify"
                component="div"
                sx={{
                  border: `1px solid ${colors.statuses[proposal.status as ProposalStatus]}`,
                  borderRadius: '6px',
                  padding: '0 12px',
                  color: colors.statuses[proposal.status as ProposalStatus],
                }}
              >
                {texts.statuses[proposal.status as ProposalStatus]}
              </Typography>
              <CustomTooltip
                content={
                  <React.Fragment>
                    {timesToolTip.map((time, i) => (
                      <Box key={i} display="flex" justifyContent="space-between" paddingX={1}>
                        <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, marginRight: 2 }}>
                          {time.name}:
                        </Typography>
                        <Typography color="inherit" component="div" noWrap sx={{ zIndex: 2, marginLeft: 1 }}>
                          {time.value}
                        </Typography>
                      </Box>
                    ))}
                  </React.Fragment>
                }
              >
                {proposal.status === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD ? (
                  <Typography variant="body2" textAlign="justify" component="div">
                    Ends {moment(proposal.depositEndTime).fromNow()}
                  </Typography>
                ) : (
                  <Typography variant="body2" textAlign="justify" component="div">
                    {Date.now() < +new Date(proposal.votingEndTime) ? 'Ends' : 'Ended'}{' '}
                    {moment(proposal.votingEndTime).fromNow()}
                  </Typography>
                )}
              </CustomTooltip>
            </Box>
            {/*<Typography*/}
            {/*  sx={{*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*    flexGrow: 1,*/}
            {/*  }}*/}
            {/*  variant="body2"*/}
            {/*  color="text.secondary"*/}
            {/*  textAlign="justify"*/}
            {/*  component="div"*/}
            {/*>*/}
            {/*  {shortDescription}*/}
            {/*</Typography>*/}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {isEnabledChain && !isDepositPeriod ? <VotingMyVotes votes={yourVotes} loaded={!votes.loading} /> : <Box />}
              <Box sx={{ minWidth: '8px' }} />
              <Typography
                variant="body1"
                textAlign="justify"
                component="div"
                sx={{
                  backgroundColor: `#556296`,
                  borderRadius: '6px',
                  padding: '6px 12px',
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {proposalType}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        {isVotingPeriod && <VotingButtons voted={null} onClick={onVoteClick} />}
        {isDepositPeriod && <DepositButton deposited={false} onClick={onDepositClick} />}
      </Card>
    </Grid>
  );
}

export default VotingCard;
