import { Box, Button, ListItem, Switch, Typography } from '@mui/material';
import { ProposalStatus } from 'cosmjs-types/cosmos/gov/v1beta1/gov.d';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import React, { FC } from 'react';
import { useWalletContext } from '../../../context/wallet-context';
import { ChainFilterType } from '../../../enum';
import { BlockchainListItemFragment } from '../../../generated/graphql';
import { VotingFilterState } from '../../../types';
import SnackbarAction from '../../common/snackbar-action';
import ProposalStatusSelect from './proposal-status-select';
import VotingFilterChainButton from './voting-filter-chain-button';

type OwnProps = {
  filter: VotingFilterState;
  onChange: (filter: Partial<VotingFilterState>) => void;
};

const VotingFilters: FC<OwnProps> = ({ filter, onChange }) => {
  const {
    blockchainsQuery: { data: blockchains, loading },
  } = useWalletContext();

  const onChainClick = (chain: BlockchainListItemFragment) => {
    let selectedChains: string[];

    if (filter.selectedChains.find((c) => c === chain.id)) {
      selectedChains = filter.selectedChains.filter((c) => c !== chain.id);
    } else {
      selectedChains = [...filter.selectedChains, chain.id];
    }

    onChange({ selectedChains });
    localStorage.setItem('voting-filter', JSON.stringify({ ...filter, selectedChains }));
  };

  const toggleActual = () => {
    enqueueSnackbar('This feature will be implemented soon.', {
      variant: 'info',
      action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
    });
    onChange({ isActual: !filter.isActual });
    localStorage.setItem('voting-filter', JSON.stringify({ ...filter, isActual: !filter.isActual }));
  };

  const toggleIncludeScam = (include: boolean) => {
    onChange({ includeScam: include });
    localStorage.setItem('voting-filter', JSON.stringify({ ...filter, includeScam: include }));
  };

  const handleChangeStatus = (statuses: ProposalStatus[]) => {
    onChange({ statuses });
    localStorage.setItem('voting-filter', JSON.stringify({ ...filter, statuses }));
  };

  const handleChangeChainsType = (chainsType: ChainFilterType) => {
    onChange({ chainsType, selectedChains: [] });
    localStorage.setItem('voting-filter', JSON.stringify({ ...filter, chainsType }));
  };

  if (!blockchains || loading) {
    return null;
  }

  return (
    <>
      <ListItem divider={true}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Typography variant="body1" component="div">
            Hide not voted
          </Typography>
          <Switch onChange={toggleActual} checked={filter.isActual} />
        </Box>
      </ListItem>
      <ListItem divider={true}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Typography variant="body1" component="div">
            Include potentially harmful proposals
          </Typography>
          <Switch onChange={(_, checked: boolean) => toggleIncludeScam(checked)} checked={filter.includeScam} />
        </Box>
      </ListItem>
      <ListItem divider={true}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Typography variant="body1">Status</Typography>
          <ProposalStatusSelect selected={filter.statuses} onChange={handleChangeStatus} />
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography variant="body1">Chains</Typography>
          </Box>
          <Box>
            <Button
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => handleChangeChainsType(ChainFilterType.CONNECTED)}
              variant={filter.chainsType === ChainFilterType.CONNECTED ? 'contained' : 'outlined'}
            >
              Connected
            </Button>
            <Button
              sx={{ borderRadius: 0, borderLeftWidth: 0, borderRightWidth: 0 }}
              onClick={() => handleChangeChainsType(ChainFilterType.ALL)}
              variant={filter.chainsType === ChainFilterType.ALL ? 'contained' : 'outlined'}
            >
              All
            </Button>
            <Button
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => handleChangeChainsType(ChainFilterType.CUSTOM)}
              variant={filter.chainsType === ChainFilterType.CUSTOM ? 'contained' : 'outlined'}
            >
              Custom
            </Button>
          </Box>
          <Box
            mt={1}
            sx={{ flexWrap: 'wrap', display: filter.chainsType === ChainFilterType.CUSTOM ? 'block' : 'none' }}
          >
            {blockchains.blockchains.map((chain) => (
              <VotingFilterChainButton
                key={chain.chainName}
                chain={chain}
                selected={filter.selectedChains.indexOf(chain.id) === -1}
                onClick={() => onChainClick(chain)}
              />
            ))}
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

export default VotingFilters;
