import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import BlockchainInfo from './blockchain-info/blockchain-info';
import BlockchainList from './blockchain-list/blockchain-list';

interface OwnProps {
  blockchainId?: string;
}

const BlockchainModule: FC<OwnProps> = ({ blockchainId }) => {
  return <Wrapper>{blockchainId ? <BlockchainInfo blockchainId={blockchainId} /> : <BlockchainList />}</Wrapper>;
};

export default BlockchainModule;

const Wrapper = styled(Box)({});
