import { SearchOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Skeleton, styled, TextField, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useWalletContext } from '../../../context/wallet-context';
import { BlockchainListItemFragment } from '../../../generated/graphql';
import BlockchainListItem from './blockchain-list-item';

interface OwnProps {}

const sortChains = (blockchains: BlockchainListItemFragment[]): BlockchainListItemFragment[] => {
  return blockchains.slice().sort((bca, bcb) => bcb.TVLinUSD - bca.TVLinUSD);
};

const BlockchainList: FC<OwnProps> = () => {
  const {
    blockchainsQuery: { data: blockchains, loading: blockchainLoading },
  } = useWalletContext();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Blockchain List',
    });
  }, []);

  const blockchainList = useMemo(() => {
    if (blockchains && blockchains.blockchains) {
      if (!searchText) {
        return sortChains(blockchains.blockchains);
      }

      return sortChains(blockchains.blockchains).filter((bc) => {
        return (
          bc.chainName.indexOf(searchText.toLowerCase()) !== -1 ||
          bc.prettyName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    return [];
  }, [blockchains, searchText]);

  if (!blockchainLoading && !blockchains) {
    return null;
  }

  return (
    <Wrapper>
      <Box mt={2}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              Blockchains ({blockchains?.blockchains?.length || 0})
            </Typography>
          </Grid>
          <Grid item alignSelf="flex-end" xs={12}>
            <>
              <TextField
                fullWidth
                label="Search Chains"
                sx={{ margin: '8px 0' }}
                size="small"
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton sx={{ marginRight: -2 }}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
              <ListWrapper>
                {!blockchainLoading
                  ? blockchainList.map((chain) => <BlockchainListItem key={chain.chainName} chain={chain} />)
                  : Array.from(new Array(12)).map((_, index) => (
                      <Skeleton
                        key={index}
                        animation="wave"
                        sx={{ margin: '4px', borderRadius: '4px', minWidth: '300px', flex: 1 }}
                        variant="rectangular"
                        height="90px"
                      />
                    ))}
              </ListWrapper>
            </>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default BlockchainList;

const Wrapper = styled(Box)({});

const ListWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: -4,
});
