import LanguageIcon from '@mui/icons-material/Language';
import { Box, Card, CardActionArea, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BlockchainListItemFragment } from '../../../generated/graphql';
import { coinToFloatWithName } from '../../../utils/coinToFloat';

type OwnProps = {
  chain: BlockchainListItemFragment;
};

const BlockchainListItem: FC<OwnProps> = ({ chain }) => {
  return (
    <Wrapper>
      {chain.website ? (
        <IconButton
          href={chain.website}
          size="small"
          target="_blank"
          rel="nofollow"
          sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1 }}
        >
          <LanguageIcon fontSize="small" />
        </IconButton>
      ) : null}
      <CardActionArea
        component={RouterLink}
        to={`/blockchains/${chain.id}`}
        sx={{
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <img
          src={chain.icon}
          alt={chain.chainName}
          style={{
            width: '48px',
            height: '48px',
          }}
        />
        <Box ml={2}>
          <Typography variant="h4" noWrap>
            {chain.prettyName}
          </Typography>
          <Tooltip
            title={coinToFloatWithName(chain.TVL, chain.assets[0].denom, chain.assets[0].symbol)}
            placement="right-end"
            disableFocusListener
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography variant="body2" sx={{ lineHeight: 1 }}>
              Staked: {coinToFloatWithName(chain.TVLinUSD, 0, '$')}
            </Typography>
          </Tooltip>
        </Box>
      </CardActionArea>
    </Wrapper>
  );
};

export default BlockchainListItem;

const Wrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(0.5),
  minWidth: '300px',
  flex: 1,
}));
