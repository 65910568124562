import { StdFee } from '@cosmjs/amino';
import { calculateFee, GasPrice, SigningStargateClient } from '@cosmjs/stargate';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { cosmos } from 'osmojs';
import { VoteOption } from 'osmojs/types/codegen/cosmos/gov/v1beta1/gov';
import { Long } from 'osmojs/types/codegen/helpers';
import ReactGA from 'react-ga4';
import { SnackbarAction } from '../components/common/snackbar-action';

import { BlockchainListItemFragment } from '../generated/graphql';
import { FeeToken } from '../types';

export const createVoter = async (
  stargateClient: SigningStargateClient,
  setResp: (resp: string) => any,
  blockchain: BlockchainListItemFragment
) => {
  const feeObj = JSON.parse(blockchain.feeToken) as FeeToken;

  return async (proposalId: Long, address: string, option: VoteOption): Promise<boolean> => {
    if (!stargateClient || !address) {
      console.error('Stargate client undefined or address undefined.');
      return false;
    }

    const { vote } = cosmos.gov.v1beta1.MessageComposer.withTypeUrl;
    const msgVote = vote({
      proposalId: proposalId,
      voter: address,
      option: option,
    });

    const gasUsed = await stargateClient.simulate(address, [msgVote], 'votingpower.org');

    const gasPriceString =
      (feeObj.low_gas_price ||
        feeObj.average_gas_price ||
        feeObj.high_gas_price ||
        feeObj.fixed_min_gas_price ||
        0.01) + feeObj.denom;
    const defaultGasPrice = GasPrice.fromString(gasPriceString);
    const fee: StdFee = calculateFee(Math.round(gasUsed * 1.5), defaultGasPrice);

    console.log(
      'Raw unsigned transaction:',
      JSON.stringify({
        body: { messages: [msgVote] },
        auth_info: { fee },
      })
    );

    try {
      const response = await stargateClient.signAndBroadcast(address, [msgVote], fee || 'auto', 'votingpower.org');
      setResp(JSON.stringify(response, null, 2));

      enqueueSnackbar(`You successfully voted proposal #${proposalId}`, {
        variant: 'success',
        action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
      });

      ReactGA.event({
        category: 'Voting',
        action: 'vote',
      });

      return !!response.transactionHash && response.height > 0;
    } catch (error) {
      console.log('Error signing and sending transaction:', error);
      enqueueSnackbar(`${error}`, {
        variant: 'error',
        action: (key: SnackbarKey) => <SnackbarAction closeKey={key} />,
      });
    }
    return false;
  };
};
