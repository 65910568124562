import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Box, styled } from '@mui/material';
import React, { FC } from 'react';

interface OwnProps {
  username?: string | null;
  img?: string | null;
  sx?: any;
  badgeId?: string;
  isBig?: boolean;
}

type BadgeParams = {
  src: string;
  top: string;
  left: string;
  size: string;
};

const Badge: FC<{ badgeId: string; isBig: boolean }> = ({ badgeId, isBig }) => {
  let badge: BadgeParams;

  switch (badgeId) {
    case '1':
      badge = {
        src: '/img/badge1.svg',
        top: isBig ? '-1.25vw' : '-12.5px',
        left: isBig ? '-1vw' : '-10px',
        size: isBig ? '7vw' : '70px',
      };
      break;
    case '2':
      badge = {
        src: '/img/badge2.svg',
        top: isBig ? '-1.25vw' : '-12.5px',
        left: isBig ? '-1vw' : '-10px',
        size: isBig ? '7vw' : '70px',
      };
      break;
    case '3':
      badge = {
        src: '/img/badge3.svg',
        top: isBig ? '-1.25vw' : '-12.5px',
        left: isBig ? '-1vw' : '-10px',
        size: isBig ? '7vw' : '70px',
      };
      break;
    default:
      return null;
  }

  return (
    <img
      src={badge.src}
      alt={badgeId}
      style={{
        position: 'absolute',
        top: badge.top,
        left: badge.left,
        minWidth: badge.size,
        minHeight: badge.size,
      }}
    />
  );
};

const ValidatorAvatar: FC<OwnProps> = ({ img, username, sx = { width: '100%' }, badgeId, isBig = false }) => {
  if (!img) {
    return (
      <Wrapper sx={sx}>
        {badgeId && <Badge badgeId={badgeId} isBig={isBig} />}
        <AdminPanelSettingsIcon sx={{ width: 'unset', height: 'unset' }} />
      </Wrapper>
    );
  }

  return (
    <Wrapper sx={sx}>
      {badgeId && <Badge badgeId={badgeId} isBig={isBig} />}
      <Image src={img} alt={username || ''} />
    </Wrapper>
  );
};

export default ValidatorAvatar;

const Wrapper = styled(Box)({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const Image = styled('img')({
  borderRadius: '50%',
  overflow: 'hidden',
});
