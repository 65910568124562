import React from 'react';
import { Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { darken } from '@mui/system/colorManipulator';
import colors, { votingKeysSorted } from '../../../constants/colors';
import { Tally, VoteOption } from '../../../enum';

const stylesMain = {
  borderRadius: 0,
  whiteSpace: 'nowrap',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
};

interface OwnProps {
  voted: VoteOption | null;
  onClick: (key: VoteOption) => void;
}

function VotingButtons({ voted, onClick }: OwnProps) {
  return (
    <Grid container spacing={0.1}>
      {votingKeysSorted.map((key, i) => (
        <Grid key={key} item xs>
          <Button
            fullWidth
            size="small"
            onClick={() => onClick(key)}
            sx={voted && voted === key ? styles[key].selected : styles[key].default}
          >
            {key === VoteOption.VOTE_OPTION_NO_WITH_VETO ? 'NWV' : Tally[key]}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default VotingButtons;

const styles: any = {
  [VoteOption.VOTE_OPTION_YES]: {
    default: {
      ...stylesMain,
      color: colors.votes[VoteOption.VOTE_OPTION_YES],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_YES],
      '&:hover': {
        backgroundColor: colors.votes[VoteOption.VOTE_OPTION_YES],
        color: '#ffffff',
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
    selected: {
      ...stylesMain,
      backgroundColor: colors.votes[VoteOption.VOTE_OPTION_YES],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_YES],
      color: '#ffffff',
      '&:hover': {
        backgroundColor: darken(colors.votes[VoteOption.VOTE_OPTION_YES], 0.2),
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
  },
  [VoteOption.VOTE_OPTION_NO]: {
    default: {
      ...stylesMain,
      color: colors.votes[VoteOption.VOTE_OPTION_NO],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_NO],
      '&:hover': {
        backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO],
        color: '#ffffff',
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
    selected: {
      ...stylesMain,
      backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_NO],
      color: '#ffffff',
      '&:hover': {
        backgroundColor: darken(colors.votes[VoteOption.VOTE_OPTION_NO], 0.2),
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
  },
  [VoteOption.VOTE_OPTION_NO_WITH_VETO]: {
    default: {
      ...stylesMain,
      color: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
      '&:hover': {
        backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
        color: '#ffffff',
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
    selected: {
      ...stylesMain,
      backgroundColor: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO],
      color: '#ffffff',
      '&:hover': {
        backgroundColor: darken(colors.votes[VoteOption.VOTE_OPTION_NO_WITH_VETO], 0.2),
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
  },
  [VoteOption.VOTE_OPTION_ABSTAIN]: {
    default: {
      ...stylesMain,
      color: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
      '&:hover': {
        backgroundColor: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
        color: '#ffffff',
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
    selected: {
      ...stylesMain,
      backgroundColor: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
      borderTopColor: colors.votes[VoteOption.VOTE_OPTION_ABSTAIN],
      color: '#ffffff',
      '&:hover': {
        backgroundColor: darken(colors.votes[VoteOption.VOTE_OPTION_ABSTAIN], 0.2),
      },
      '&:disabled': {
        backgroundColor: grey[500],
      },
    },
  },
};
