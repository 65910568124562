import { Box } from '@mui/material';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function ErrorPage() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Error page' });
  }, []);
  return (
    <Box style={{ padding: '1rem 0' }}>
      <h2>Error</h2>
    </Box>
  );
}
