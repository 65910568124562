import { SearchOutlined } from '@mui/icons-material';
import { Box, FormControl, IconButton, MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

interface OwnProps {
  onChange: (text: string, active: boolean) => void;
}

const BlockchainValidatorsListFilter: FC<OwnProps> = ({ onChange }) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    onChange(searchText, isActive);
  }, [searchText, isActive]);

  return (
    <Box mb={1} display="flex">
      <TextField
        label="Search"
        sx={{ marginRight: '8px', flex: 1 }}
        size="small"
        defaultValue=""
        // value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton sx={{ marginRight: -2 }}>
              <SearchOutlined />
            </IconButton>
          ),
        }}
      />
      <FormControl size="small">
        <Select value={isActive} onChange={() => setIsActive(!isActive)}>
          <MenuItem value={true as any}>Active</MenuItem>
          <MenuItem value={false as any}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default BlockchainValidatorsListFilter;
